import React from 'react';
import {PropTypes} from "prop-types";
import {connect} from 'react-redux';
import Popover from "@material-ui/core/Popover/Popover";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import {theme} from "../constants/colors"
import Typography from "@material-ui/core/Typography/Typography";
import TextField from "@material-ui/core/TextField/TextField";
import Divider from "@material-ui/core/Divider/Divider";

const styles = {
    popoverBodyContainer: {
        minWidth: "20vw",
        padding: "10px",
        background: theme.palette.primary.main
    },
    popoverBodyContainerInner: {
        padding: "10px",
        background: "white"
    },
    listContainer: {
        maxHeight: "20vh",
        overflow: 'auto',
    },
    divider:{
        height:"1vh",
    }
}

class PracticeChangePopover extends React.Component {

    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.renderPopoverBody = this.renderPopoverBody.bind(this);
    }

    handleClose() {

    }


    renderPopoverBody() {
        return (<div style={styles.popoverBodyContainer}>
                <div style={styles.popoverBodyContainerInner}>

                    <Typography gutterBottom variant={"subtitle1"}>
                        Description of practice changes
                    </Typography>
                    <div style={styles.listContainer}>

                        <List>
                            <Divider/>

                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(value => {
                                return (<ListItem key={value} divider button dense>
                                    {"option " + value}
                                </ListItem>)
                            })}
                        </List>
                    </div>
                </div>
                <div style={styles.divider}/>
                <div style={styles.popoverBodyContainerInner}>
                    <div>
                        <Typography gutterBottom variant={"subtitle1"}>
                            Description of new practice change
                        </Typography>
                    </div>
                    <div>
                        <TextField variant={"outlined"} label={"description"} multiline style={{width: "100%"}}>

                        </TextField>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (<Popover
            onClose={this.handleClose}
            open={this.props.open}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left"
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            anchorEl={this.props.target}>
            {this.renderPopoverBody()}
        </Popover>)
    }

}

PracticeChangePopover.propTypes = {
    open: PropTypes.bool.isRequired,
    target: PropTypes.object,
    closeCallback: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {

    return {};
}

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(PracticeChangePopover);