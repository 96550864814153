import React from 'react';
import {PropTypes} from "prop-types";
import {connect} from 'react-redux';
import Popover from "@material-ui/core/Popover/Popover";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import {theme} from "../constants/colors"
import Typography from "@material-ui/core/Typography/Typography";
import TextField from "@material-ui/core/TextField/TextField";
import Divider from "@material-ui/core/Divider/Divider";
import Button from "@material-ui/core/Button/Button";
import Grid from "@material-ui/core/Grid/Grid";

const styles = {
    popoverBodyContainer: {
        minWidth: "20vw",
        padding: "10px",
        background: theme.palette.primary.main
    },
    popoverBodyContainerInner: {
        padding: "10px",
        background: "white"
    },
    listContainer: {
        maxHeight: "20vh",
        overflow: 'auto',
    },
    divider: {
        height: "1vh",
    }
}

class PracticeChangeTemplatePopover extends React.Component {

    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.renderPopoverBody = this.renderPopoverBody.bind(this);
    }

    handleClose(name, description) {

        this.props.closeCallback(name, description);
    }


    renderPopoverBody() {
        return (<div style={styles.popoverBodyContainer}>
                <div style={styles.popoverBodyContainerInner}>

                    <Typography gutterBottom variant={"subtitle1"}>
                        Name of practice change
                    </Typography>
                    <div>
                        <TextField inputRef={ref=>(this.nameRef = ref)} defaultValue={this.props.name || this.props.templateName} variant={"outlined"} label={"description"}
                                   multiline style={{width: "100%"}}>

                        </TextField>
                    </div>
                </div>
                <div style={styles.divider}/>
                <div style={styles.popoverBodyContainerInner}>
                    <div>
                        <Typography gutterBottom variant={"subtitle1"}>
                            Description of new practice change
                        </Typography>
                    </div>
                    <div>
                        <TextField inputRef={ref=>(this.descRef = ref)} defaultValue={this.props.description || this.props.templateDescription} variant={"outlined"}
                                   label={"description"} rows={10} multiline style={{width: "100%"}}>

                        </TextField>
                    </div>
                </div>
                <div style={styles.divider}/>
                <div style={{'display':"flex"}}>
                    <Grid container spacing={8} justify={"flex-end"}>
                        <Grid item>
                            <Button onClick={_=>this.handleClose(this.nameRef.value, this.descRef.value)}
                                color={"secondary"} variant={"contained"}>Cancel</Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={_=>this.handleClose(this.nameRef.value, this.descRef.value)}
                                style={{background: "white"}} variant={"contained"}>Confirm</Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }

    render() {
        return (<Popover
            onClose={_=>this.handleClose(this.nameRef.value, this.descRef.value)}
            open={this.props.open}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left"
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            anchorEl={this.props.target}>
            {this.renderPopoverBody()}

        </Popover>)
    }

}

PracticeChangeTemplatePopover.propTypes = {
    open: PropTypes.bool.isRequired,
    target: PropTypes.object,
    closeCallback: PropTypes.func.isRequired,
    templateIndex: PropTypes.number,
    templateName: PropTypes.string,
    templateDescription: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
    if (ownProps.templateIndex !== -1) {
        let template = state.issueTemplates[ownProps.templateIndex];

        return {
            templateName: template.practice_change_name,
            templateDescription: template.practice_change_description,

        };
    }
    return {};
}

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(PracticeChangeTemplatePopover);