

const buildStrategyFromServer = function(obj){
    const cell_map = [0,1,2,3,4,5].map(_=>{
        return [0,1,2,3,4].map(_=>false);
    });
    if(obj.cell_map !== null && obj.cell_map !== undefined) {
        for (let i = 0; i < obj.cell_map.length; i++) {
            for (let j = 0; j < obj.cell_map[i].length; j++) {
                cell_map[i][j] = obj.cell_map[i][j];
            }
        }
    }
    return {
        text:obj.text,
        id: obj.id,
        selectedElems: cell_map,
        template_id: obj.template_id,
        practice_change_id: obj.practice_change_id,
    }
}

const buildStrategy=function(text, pcid, es_id){
    return {
        text:text,
        id:es_id,
        practice_change_id: pcid,
        template_id: null,
        selectedElems: [0,1,2,3,4,5].map(_=>{
            return [0,1,2,3,4].map(_=>false);
        }),
    }
}

const copyStrategy = function(strat){
    let new_strat = {...strat};
    new_strat.selectedElems = new_strat.selectedElems.map(v=>{
        return [...v];
    })
    return new_strat;

}


const defaultState = [];


const strategy = function(state, action){
    switch(action.type){
        case "ES_ADD_STRATEGY":
            return buildStrategy("new strategy", action.practice_change_id, action.enabling_strategy_id);
        case "ES_STRAT_TEXT":
            let new_state = copyStrategy(state);
            new_state.text = action.text;
            return new_state;
        case "ES_ADD_CELL":
            new_state = copyStrategy(state);
            new_state.selectedElems[action.col][action.row] = true;
            return new_state;
        case "ES_SET_TEMPLATE":
            new_state = copyStrategy(state);
            new_state.template_id = action.s_id;
            return new_state;
        case "ES_TOGGLE_CELL":
            new_state = copyStrategy(state);
            new_state.selectedElems[action.col][action.row] = !new_state.selectedElems[action.col][action.row];
            return new_state;
        case "ES_REMOVE_CELL":
            new_state = copyStrategy(state);
            new_state.selectedElems[action.col][action.row] = false;
            return new_state;
        default:
            return state;
    }
}




export const enablingStrategies = function(state=defaultState, action){
    switch(action.type){
        case "ES_SET_STRATEGIES":
            return action.strategies.map(buildStrategyFromServer);
        case "ES_ADD_STRATEGY":
            return [...state, strategy({}, action)];
        case "ES_REMOVE":
            return [...(state.filter(es => es.id !== action.id))]
        case "ES_STRAT_TEXT":
        case "ES_SET_TEMPLATE":
        case "ES_ADD_CELL":
        case "ES_TOGGLE_CELL":
            let new_state = state.map((s, i)=>{
                if(s.id === action.id){
                    return strategy(s, action);
                }else{
                    return copyStrategy(s);
                }
            });
            return new_state;
        default:
            return state;
    }
}