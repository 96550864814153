import React from 'react';
import {PropTypes} from "prop-types";
import {connect} from 'react-redux';
import Paper from "@material-ui/core/Paper/Paper";
import {matrixData} from "../../reducers/matrixData";
import Matrix from "../matrix/Matrix";
import Grid from "@material-ui/core/Grid/Grid";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader/ListSubheader";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction";
import Button from "@material-ui/core/Button/Button";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

const styles = {
    scorePanelContainer: {
        background: "white",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: "1%",
        boxSizing: "border-box",
        overflow: "hidden"
    },
    divider: n => ({
        height: n + "%"
    }),
}


class ReportHeatMap extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            projectFilter: [],
            selectCell: [],
        }
        this.generateData = this.generateData.bind(this);
        this.toggleFilter = this.toggleFilter.bind(this);
        this.handleCellClick = this.handleCellClick.bind(this);
    }

    handleCellClick(row, col){
        if(this.state.selectCell.length > 0){
            if(this.state.selectCell[0] === row && this.state.selectCell[1] === col){
                this.setState({
                    selectCell:[],
                });
                return;
            }
        }
        this.setState({
            selectCell: [row,col],
        })
    }

    toggleFilter(id){
        let index = this.state.projectFilter.indexOf(id);
        if(index === -1){
            this.setState({
                projectFilter: [id, ...this.state.projectFilter]
            })
            return;
        }else{
            let new_filter = [...this.state.projectFilter];
            new_filter.splice(index, 1);
            this.setState({
                projectFilter: new_filter
            })
        }
    }

    static getBackgroundColor(priority) {
        return ["lightgreen", "yellow", "red"][priority];
    }


    generateData(){
        let sums = this.props.matrixData.rows.map(v => {
            return v.elements.map(k=>0);
        });

        this.props.practiceChanges.map(v => {

            if(this.state.projectFilter.indexOf(v.id) !== -1){
                return;
            }
            v.matrixData.entries.map((v,i) => {
                v.map((k,j) => {
                    if(k.priority === null){
                        sums[i][j] += 3;
                    }else {
                        sums[i][j] += k.priority;
                    }
                })
            })

        })
        sums = sums.map(v => v.map(k => k/this.props.practiceChanges.length));
        let max = sums.reduce((acc, v) => Math.max(acc, v.reduce((acc2, v2) => Math.max(acc2, v2)), 0), 0);
        let min = sums.reduce((acc, v) => Math.min(acc, v.reduce((acc2, v2) => Math.min(acc2, v2)), max), max);
        if(max === min){
            return sums.map(v => v.map(k => {
                return {
                    backgroundColor: "grey"
                }
            }));
        }
        sums = sums.map(v => v.map(k => 5 - Math.min(5, Math.floor(6*(max - k)/(max - min)))));
        return sums.map((v,i) => v.map((k, j) => {
            return {
                border: i === this.state.selectCell[0] && j === this.state.selectCell[1] ? "solid 5px blue": "none",
                backgroundColor: ["green", "GreenYellow", "yellow", "orange", "orangered", "red"][k]
            }
        }));

    }

    render() {
        let md = this.generateData()
        let prac_changes = this.props.practiceChanges.reduce((rv, v) => {
            (rv[v.project_id] = rv[v.project_id] || []).push(v);
            return rv;
        }, {});
        return (
            <Grid container spacing={32}>
                <Grid style={{height: "100%"}} item xl={3} lg={3} md={3} sm={3} xs={3}>

                    <Paper style={styles.scorePanelContainer}>
                        <div>
                            <Button onClick={this.props.onBackCallback} variant={"contained"} color={"primary"}>
                                back
                            </Button>
                        </div>
                        <List dense style={{"overflow-y": "auto"}}>
                            {Object.keys(prac_changes).map(k => {
                                let proj = this.props.projects.filter(p=>p.id === parseInt(k))[0];
                                return [
                                    <ListSubheader style={{"background":"white"}}>{proj.name}</ListSubheader>,
                                    ...prac_changes[k].map(v => {

                                        return (<ListItem key={v.id} onClick={_=>this.toggleFilter(v.id)} button dense>
                                            <Checkbox
                                                checked={this.state.projectFilter.indexOf(v.id) === -1}
                                                disableRipple
                                                color={"primary"}
                                            />
                                            <ListItemText
                                                primary={v.practice_change_name}
                                                secondary={proj.name}
                                            />
                                                {
                                                    this.state.selectCell.length > 0 ?
                                                        (
                                                            <ListItemSecondaryAction>
                                                                <FiberManualRecord style={{
                                                                    "color": ReportHeatMap.getBackgroundColor(v.matrixData.entries[
                                                                        this.state.selectCell[0]][
                                                                        this.state.selectCell[1]
                                                                        ].priority)
                                                                }}/>
                                                            </ListItemSecondaryAction>) :
                                                        ""
                                                }
                                        </ListItem>)
                                    })
                                ]

                            }).reduce((acc, v) => acc.concat(v))}

                        </List>
                    </Paper>
                </Grid>
                <Grid style={{height: "100%"}} container item xl={9} lg={9} md={9} sm={9} xs={9}>
                    <Matrix onCellClick={this.handleCellClick} plainRender cellPropList={md}/>
                </Grid>
            </Grid>
        )
    }
}

ReportHeatMap.propTypes = {

    onBackCallback: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {

    return {
        projects:state.projects,
        matrixData: state.matrixData,
        practiceChanges: state.practiceChanges,
    };
}

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ReportHeatMap);