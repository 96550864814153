import React from 'react';
import {PropTypes} from "prop-types";
import {connect} from 'react-redux';
import Typography from "@material-ui/core/Typography/Typography"
import Paper from "@material-ui/core/Paper"
import List from "@material-ui/core/List/List"
import ListItem from "@material-ui/core/ListItem/ListItem"
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import CardActions from "@material-ui/core/CardActions/CardActions";
import Button from "@material-ui/core/Button/Button";
import {setCurrentProject, setCurrentScreen, setProjectData} from "../actions/projectActions";
import {setPracticeChange} from "../actions/practiceChangeActions";
import TextField from "@material-ui/core/TextField/TextField";
import {updateProjectData} from "../actions/projectsActions";

const styles = {
    container: {
        background: "white",
        height: '100%',
        width:"600px",
    }
}

class ProjectPreview extends React.Component {

    constructor(props) {
        super(props);
    }

    renderField = (name, key) => {
        return (
            <div style={{borderBottom: "solid 2px lightgrey"}}>
                <div style={{width: "60%", margin: "auto", display: "flex", flexDirection: "row"}}>
                    <span style={{marginLeft: "30px", fontSize: "1.0em"}}>
                        {name}:
                    </span>
                    <div style={{flex: 1}}/>
                    <TextField value={this.props.project[key]} onChange={e => this.props.setProjectData(this.props.project, key, e.target.value)}/>

                </div>
            </div>)
    }

    calculateProgress = (prac_change) => {

        let mat_data = prac_change.matrixData;
        let matrix_valid = true;
        for (let col = 0; col < mat_data.columnJustifications.length; col++) {
            if (mat_data.columnJustifications[col] === "") {
                matrix_valid = false;
                break;
            }
        }
        for (let col = 0; col < mat_data.entries[0].length && matrix_valid; col++) {
            let last_value = null;
            let value_found = false;
            for (let row = 0; row < mat_data.entries.length && matrix_valid; row++) {
                let entry = mat_data.entries[row][col];
                if (row === 0 && (col === 1 || col === 4)) {
                    continue;
                } else if (row === 1 && col === 4) {
                    continue;
                }
                if (entry.priority !== null) {
                    value_found = true;
                }
                if (last_value === null) {
                    last_value = entry.priority;
                }


                if (last_value === null && entry.priority !== null) {
                    matrix_valid = false;
                    break;
                }
                if (last_value !== null && entry.priority === null) {
                    continue;
                } else if (last_value !== null && entry.priority < last_value) {
                    matrix_valid = false
                    break;
                }
            }
            if (!value_found) {
                matrix_valid = false;
            }
        }

        if (!matrix_valid) {
            return 2;
        }


        let es = this.props.enablingStrategies.filter(es => es.practice_change_id === prac_change.id);
        if (es.length === 0) {
            return 3;
        }

        return 4;

    }
    renderProgress = (pc) => {

        const prog_text = ["Create practice change", "Define transition phase", "Develop enabling strategies"]
        let progress = this.calculateProgress(pc);

        return <div style={{
            display: "flex",
            flexDirection: "row",
            maxHeight: "40px",
            padding: "5px",
            borderBottom: "solid 2px lightgrey"
        }}>
            <div></div>
                        <span style={{
                            width: "calc(50%)",
                            overflow: "hidden",
                            whitespace: "nowrap",
                            fontSize: "1.0em",
                            textOverflow: "ellipsis"
                        }}>
                            {pc.practice_change_name}
                        </span>
            <div style={{flex: "1 1 auto"}}/>
            <div style={{
                marginLeft: "20px",
                overflow: "hidden",
                marginTop: "5px",
                marginBottom: "10px",
                borderRadius: "10px",
                border: "solid 2px grey",
                marginRight: "20px",
                background: "white",
                position: "relative",
                width: "400px"
            }}>
                {progress === 4 ? <div style={{height:"100%"}}>
                        <div style={{height: "100%", width:"100%", backgroundColor: "#77dd77"}}/>
                        <div style={{
                            width: "100%",
                            lineHeight: "20px",
                            height: "100%",
                            position: "absolute",
                            left: 0,
                            top: 0,
                            textAlign: "center"
                        }}>
                            Complete
                        </div>
                    </div> :
                    <div style={{height:"100%"}}>
                        <div style={{height: "100%", width: (100 * progress / 3) + "%", backgroundColor: "#008fa5"}}/>
                        <div style={{
                            width: "100%",
                            lineHeight: "20px",
                            height: "100%",
                            position: "absolute",
                            left: 0,
                            top: 0,
                            textAlign: "center"
                        }}>
                            {progress}/3 - {prog_text[progress - 1]}
                        </div>
                    </div>
                }


            </div>
            <div>
                <Button variant={"contained"} color={"primary"}
                        onClick={_ => this.props.selectPracChange(this.props.projectIndex, pc.id, progress === 1 ? 1 : 2)}>SELECT</Button>
            </div>
        </div>
    }

    render() {
        let all_issues = new Set(this.props.practiceChanges.map(p => p.issue_name));
        let issues = [...all_issues];

        return (
            <Paper style={styles.container}>
                <div style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    width:"90%",
                    marginLeft:"15px"}}>
                    <Typography variant={"h4"} align={"center"} style={{margin:"20px 0px"}}>
                        Project Preview
                    </Typography>

                    {this.renderField("Name", "name")}
                    {this.renderField("Time Period", "time_period")}
                    {this.renderField("City", "city")}
                    {this.renderField("Whos Involved", "whos_involved")}
                    {this.renderField("Lead Facilitator/Analyst", "lead_fac_analyst")}

                    <div style={{height:"20px"}}> </div>

                    <h3>Completion Progress</h3>
                    <div style={{flex: 1, overflowY: "hidden"}}>
                        <div style={{height: "100%", overflowY: "auto"}}>
                            {issues.map(i =>
                                {return <div>

                                        <Typography style={{backgroundColor:"#D9E2EC"}} variant={"h6"}>{i}</Typography>

                                {this.props.practiceChanges.filter(p=>p.issue_name===i).map(pc => {
                                    return this.renderProgress(pc)
                                })}</div>
                                })}
                        </div>

                    </div>

                    {/*<div>
                        <Button color={"primary"} variant={"contained"}
                                onClick={_ => this.props.createPracticeChangeCallback(this.props.project.id)}>New
                            Practice Change</Button>
                    </div>*/}
                </div>
            </Paper>)
    }
}

ProjectPreview.propTypes = {
    projectIndex: PropTypes.number.isRequired,
    createPracticeChangeCallback: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
    const prac_changes = state.practiceChanges.filter(pc => pc.project_id === state.projects[ownProps.projectIndex].id)
    const enabling_strategies = state.enablingStrategies.filter(es => prac_changes.some(pc => pc.id === es.practice_change_id))
    return {
        project: state.projects[ownProps.projectIndex],
        practiceChanges: prac_changes,
        enablingStrategies: enabling_strategies,
    };
}

const mapDispatchToProps = dispatch => ({
    selectPracChange: (index, pcid, n) => {
        dispatch(setCurrentProject(index))
        dispatch(setPracticeChange(pcid));
        dispatch(setCurrentScreen(n))
    },
    setProjectData: (proj,key, value) => {
        dispatch(updateProjectData(proj.id, {...proj, [key]:value}))
    }

})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPreview);