import React from 'react';
import {PropTypes} from "prop-types";
import {connect} from 'react-redux';
import Typography from "@material-ui/core/Typography/Typography";
import Divider from "@material-ui/core/Divider/Divider";
import Grid from "@material-ui/core/Grid/Grid";
import Matrix from "../matrix/Matrix";
import Paper from "@material-ui/core/Paper/Paper";
import ReportTable from "./ReportTable";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ReportHeatMap from "./ReportHeatMap";
import VisualSummary from "./VisualSummary";
import {createReport} from "../../actions/serverActions";
import {Button, TextField} from "@material-ui/core";
import GoalReport from "./GoalReport";

const styles = {
    scorePanelContainer: {
        background: "white",
        display:"flex",
        flexDirection:"column",
        height: "100%",
        padding: "1%",
        boxSizing: "border-box",
        overflow:"hidden"
    },
    divider: n => ({
        height: n + "%"
    }),
}

export const templates = [
    {
        name:"WSC Index goals",
        description:"This report can be used to\n" +
            "determine the transition phases\n" +
            "of practices within WSC Index\n" +
            "goals, and what enabling\n" +
            "strategies can help improve the\n" +
            "WSC Index goal scores",
        report_component: GoalReport,
        component_props: {}

    },
    {
        name:"Practice Change",
        description:"This report allows comparison of\n" +
            "practice changes across cities to\n" +
            "determine how other cities are\n" +
            "performing and what enabling\n" +
            "strategies they are implementing",
        report_component: ReportTable,
        component_props: {perspective:"practiceChanges"}
    },
    {
        name:"Enabling Strategies",
        description:"This report can be used to\n" +
            "group enabling strategies\n" +
            "by type in order to guide\n" +
            "implementation and action\n" +
            "planning in a local area",
        report_component: ReportTable,
        component_props: {perspective:"enablingStrategies"}
    },
    // {
    //     name:"Enabling Strategies",
    //     description:"This report can be used to\n" +
    //         "group enabling strategies\n" +
    //         "by type in order to guide\n" +
    //         "implementation and action\n" +
    //         "planning in a local area"
    // },
    {
        name:"Visual Summary",
        description:"This report visually represents\n" +
            "the transition phases of\n" +
            "particular practice changes to\n" +
            "help with communication and\n" +
            "dissemination of ideas",
        report_component: VisualSummary,
        component_props: {},
        data: () => ({selectedPracChanges:[]})
    }
]

class CreateReportPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

            selectedReportType: null,
            reportName: "",
            reportDescription:"",
            selectedReport: null
        };
        this.handleTypeClick = this.handleTypeClick.bind(this);
    }

    handleTypeClick(n){
        this.setState({
            insightType: n,
            selectedReportType: null,
            reportName: "",
            reportDescription:"",
        })
    }


    addReport = () => {
        this.props.addReport()
    }

    renderTemplate = (template) => {
        return <div onClick={_=>this.setState({
            selectedReportType: template,
        })}
            style={{width:"200px", height:"200px", display:"flex",
                cursor:"pointer",
                border: this.state.selectedReportType === template ? "solid 2px lightblue": "solid 2px white",
                flexDirection:"column", marginTop:"20px", }}>
            <div style={{textAlign:"center"}}>{template.name}</div>
            <div style={{border:"solid 1px #00616F", maxWidth:"100%", wordWrap:"break-word", fontSize:"0.7em", padding:"3px", flex:1}}>
                {template.description}
            </div>
        </div>
    }

    render() {


        return (<Paper style={styles.scorePanelContainer}>
            <div style={{display:"flex", maxHeight:"100%", flexDirection:"row", flex:1}}>
                <div style={{flex: "1 0 0", height:"100%",}}>
                    Create report:
                    <div style={{height:"10px"}}/>
                    <TextField
                        value={this.state.reportName}
                        onChange={e => this.setState({reportName:e.target.value})}
                        variant={"outlined"}
                        label={"Report name"}
                        fullWidth/>
                    <div style={{height:"30px"}}/>
                    <TextField

                        value={this.state.reportDescription}
                        onChange={e => this.setState({reportDescription:e.target.value})}
                        variant={"outlined"}
                        label={"Report description"} multiline  fullWidth/>

                    <div style={{height:"10px"}}/>
                    <Button variant={"contained"} color={"primary"}
                            disabled={
                                this.state.selectedReportType === null ||
                                this.state.reportName === "" ||
                                this.state.reportDescription === ""
                            }
                            onClick={_=>this.props.createReportCallback(this.state.selectedReportType, this.state.reportName, this.state.reportDescription)}
                    >CREATE</Button>
                </div>
                <div style={{width:"40px"}}/>
                <div style={{ flex:"2 0 0", maxHeight:"100%", overflowY:"hidden"}}>
                    Report Template:
                    <div style={{width:"100%", maxWidth:"100%", height:"100%", display:"flex",
                        overflowY:"auto",
                        flexDirection:"row",
                        flexWrap:"wrap",
                        justifyContent:"space-around",
                    }}>
                        {templates.map(temp =>{
                            return this.renderTemplate(temp);
                        })}
                    </div>
                </div>
            </div>
        </Paper>)
    }
}

CreateReportPanel.propTypes = {
    createReportCallback: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {

    return {
        reports:state.reports
    };
}

const mapDispatchToProps = dispatch => ({

    addReport: () => {
        dispatch(createReport())
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateReportPanel);