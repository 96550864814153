const defaultState = [];

export const project = (state = {}, action) => {
    switch(action.type){
        case "PROJECTS_SET_PROJECTS":{
            let new_state = {};
            new_state.id = action.id;
            new_state.name = action.name;
            new_state["whos_involved"] = action.whos_involved
            new_state["time_period"] = action.time_period
            new_state["lead_fac_analyst"] = action.lead_fac_analyst
            new_state["city"] = action.city
            new_state.indicator_set_id = action.indicator_set_id;
            return new_state;
        }
        case "PROJECTS_CREATE_NEW_PROJECT":{
            let new_state = {};
            new_state.id = action.id;
            new_state.name = action.name;
            new_state.indicator_set_id = action.indicator_set_id;
            new_state["whos_involved"] = action.whos_involved
            new_state["time_period"] = action.time_period
            new_state["lead_fac_analyst"] = action.lead_fac_analyst
            new_state["city"] = action.city
            return new_state;
        }
        case "PROJECTS_SET_PROJECT_DATA":{
            let new_state = {...state};
            new_state.name = action.name;
            new_state.dirty = true;
            new_state.indicator_set_id = action.indicator_set_id;
            new_state["whos_involved"] = action.whos_involved
            new_state["time_period"] = action.time_period
            new_state["lead_fac_analyst"] = action.lead_fac_analyst
            new_state["city"] = action.city
            return new_state;
        }
        case "PROJECTS_SET_DIRTY":{
            let new_state = {...state};
            new_state.dirty = !new_state.dirty;
            return new_state;
        }
        default:
            return state;
    }
}

export const projects = (state = defaultState, action) => {
    switch (action.type){
        case "PROJECTS_SET_PROJECTS":{
            return action.projects.map(p => project(p, p));
        }
        case "PROJECTS_CREATE_NEW_PROJECT":{
            return [...state, project({}, action)];
        }
        case "PROJECTS_SET_PROJECT_DATA":{
            return state.map(p => p.id !== action.project_id ? p : project(p, action));
        }
        case "PROJECTS_SET_DIRTY":{
            return state.map(p => p.id !== action.project_id ? p : project(p, action));
        }
        case "PROJECTS_REMOVE": {
            return [...(state.filter(p => p.id !== action.id))]
        }
        default:
            return state;

    }
};