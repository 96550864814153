const buildFromServer = (indicator_obj) => {
    return {
        categories:indicator_obj.categories,
        name: indicator_obj.name,
        id: indicator_obj.id
    }
}

const defaultState = {
    categories:[],
    name:""
}

const indicator_set = function(state= defaultState, action){
    switch(action.type){
        default:
            return state;
    }
}

export const indicators = function(state=[], action){
    switch(action.type){
        case "IND_INIT_DATA": {
            let new_state = action.indicator_data.map(buildFromServer);
            return new_state;
        }
        default:
            return state;
    }
}