import React from 'react';
import {PropTypes} from "prop-types";
import {connect} from 'react-redux';
import Grid from "@material-ui/core/Grid/Grid"
import ProjectsList from "./ProjectsList";
import ProjectPreview from "./ProjectPreview";
import Button from "@material-ui/core/Button/Button";
import NewProjectPanel from "./NewProjectPanel";
import {logoutUser} from "../actions/userActions";
import Paper from "@material-ui/core/Paper/Paper";
import Fab from "@material-ui/core/Fab/Fab";
import AddIcon from '@material-ui/icons/Add';
import IconButton from "@material-ui/core/IconButton/IconButton";
import Slide from "@material-ui/core/Slide/Slide";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography/Typography";
import CloseIcon from '@material-ui/icons/Close';
import Drawer from "@material-ui/core/Drawer/Drawer";
import ProjectContentScreen from "./ProjectContentScreen";
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import ReportingScreen from "../components/reporting/ReportingScreen";
import {setCurrentProject} from "../actions/projectActions";
import Screen from "../components/Screen";
import {withRouter} from "react-router-dom";
import Divider from "@material-ui/core/Divider";

// import tableimg from "../resources/TDFM.png"

class ProjectsScreen extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            selected:-1,
            makeNewProject: false,
            openProject: false,
            openReport: false,
        };
        this.handleProjectCicked = this.handleProjectCicked.bind(this);
        this.handleNewProjectClicked = this.handleNewProjectClicked.bind(this);
        this.renderPreview = this.renderPreview.bind(this);
    }

    handleNewProjectClicked(){
        this.setState({
            makeNewProject:true,
        })
    }

    handleProjectCicked(index){
        this.setState({selected:index, makeNewProject:false});
    }

    openProject = (id) => {
        this.props.openProject(id);
        this.props.history.push("/project/"+id)
    }


    renderPreview(){
        if(this.state.selected >= 0 && !this.state.makeNewProject) {
            return (
                <ProjectPreview createPracticeChangeCallback={this.props.createPracticeChangeCallback} projectIndex={this.state.selected}/>
            )
        }
    }

    renderNewProjectDialog(){
        if (this.state.makeNewProject){
            return (<Dialog fullWidth open={true}
                            onClose={_=>this.setState({makeNewProject:false})}>
                <DialogTitle style={{padding:"15px"}} disableTypography><Typography variant={"h5"}>
                    New Project
                    <IconButton style={{float:"right", marginTop:"-10px"}} onClick={_=>this.setState({makeNewProject:false})}>
                        <CloseIcon fontSize={"small"}/>
                    </IconButton>
                </Typography></DialogTitle>
                <NewProjectPanel onProjectCreate={_=>this.setState({makeNewProject: false})}/>
            </Dialog>)
        }else{return null;}
    }

    renderReportingDialog(){
            return(<Dialog maxWidth={"xl"}  fullWidth open={this.state.openReport} fullScreen
                           onClose={_=>this.setState({openReport:false})}>
                <DialogTitle style={{padding:"15px"}} disableTypography><Typography variant={"h5"}>
                    Reports<IconButton style={{float:"right", marginTop:"-10px"}} onClick={_=>this.setState({openReport:false})}><CloseIcon fontSize={"small"}/></IconButton>
                </Typography></DialogTitle>
                <Divider/>
                    <ReportingScreen/>
            </Dialog>)
    }


    render() {
        return (
            <Screen key={0} appBar={true} title={"Projects"} active={true}>
            <Grid style={{padding:"20px",marginTop:"50px", overflowY:"auto"}} container direction={"row"}>
                <Drawer anchor={"right"} open={this.state.selected >= 0 && !this.state.makeNewProject}
                        onClose={_=>this.setState({selected:-1})}>

                        {this.renderPreview()}

                </Drawer>
                <Grid item xs={4} sm={3} md={2} lg={2} xl={1} >
                    <div style={{position:"sticky",top:"20px", justifyContent:"center",display:"flex"}}>
                        <Fab onClick={this.handleNewProjectClicked}><AddIcon/></Fab>
                    </div>
                    <div style={{position:"sticky",top:"120px", justifyContent:"center",display:"flex"}}>
                        <Fab><ImportContactsIcon onClick={_=>this.setState({openReport:true})}/></Fab>
                    </div>
                </Grid>
                <Grid style={{height:"200px"}} item container xs={8} sm={9} md={10} lg={10} xl={11}>
                    {this.props.projects.map((p,i) =>
                    {return <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <ProjectsList onClickCallback={this.handleProjectCicked} history={this.props.history}
                                              openProject={this.openProject}
                                              proj={p}
                                              index={i}/>
                            </Grid>})}

                </Grid>

                {this.renderNewProjectDialog()}
                {this.renderReportingDialog()}

            </Grid>
            </Screen>
            )


    }
}

ProjectsScreen.propTypes = {
    createPracticeChangeCallback: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {

    return {
        projects:state.projects
    };
}

const mapDispatchToProps = dispatch => ({
    logOut: () =>{
        dispatch(logoutUser())
    },
    setCurrentProject: (index) => {
        dispatch(setCurrentProject(index))
    },
})

ProjectsScreen = withRouter(ProjectsScreen);

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsScreen);