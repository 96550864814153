const COLS = 6

const deepCopyMatrixData = function(data){
    return data.map(row => {
        return row.map(entry => {
            return {
                justification: entry.justification,
                priority: entry.priority,
            }
        })
    })
}


const calculateScore = function(state){
    let suggested_score = 0;
    let cols = [];
    const mi = state;
    for(let i = 0; i < mi.entries.length; i++){

        for(let j = 0; j < mi.entries[i].length; j++){
            if (cols.length < j+1){
                cols.push([]);
            }
            if(i===0 && (j === 1 || j === 4)){
                cols[j].push(0);
                continue;
            }
            if(i===1 && (j === 4)){
                cols[j].push(0);
                continue;
            }
            cols[j].push(mi.entries[i][j].priority !== null
                ? mi.entries[i][j].priority : 2);
        }
    }
    for(let i = 5; i >= 0; i--){
        let row = cols.map(c=>c[i]);
        if(row.every(k=>k < 2)){
            if(row.some(k=>k==1)){
                suggested_score = (i) + 0.5;
            }else{
                suggested_score = (i+1);
            }
        }
    }

    return suggested_score;
}


const buildPracticeChangeFromServer = function(action){

    return {
        issue_name: action.issue_name,
        issue_description: action.issue_description,
        practice_change_name: action.practice_change_name,
        practice_change_description: action.practice_change_description,
        matrixData: action.practice_change_data,
        project_id: action.project_id,
        indicators: action.indicators,
        id: action.id,
    }
}

const generateEntry = function(){
    return {
        justification: "",
        priority: null,
    }
}


export const buildMatrix =()=>({
    entries: [...Array(6).keys()].map(
        _=>[...Array(COLS).keys()].map(generateEntry)
    ),
    suggestedScore:0,
    overrideScore:0,
    columnJustifications: [...Array(COLS).keys()].map(_=>""),
})

const buildPractice = function(action){

    return {
        issue_name: action.issue_name,
        issue_description: action.issue_description,
        practice_change_name: action.practice_change_name,
        practice_change_description: action.practice_change_description,
        matrixData: buildMatrix(),
        project_id: action.project_id,
        indicators: action.indicators,
        id: action.id,
    }
}

const entry = function(state, action, row, col){
    switch (action.type){

        case "PRAC_CHANGE_SET_JUSTIFICATION": {
            let new_entry = {...state};
            if (row === action.row && col === action.col) {
                new_entry.justification = action.justification;
            }
            return new_entry;
        }
        case "PRAC_CHANGE_SET_PRIORITY": {
            let new_entry = {...state};
            if (row === action.row && col === action.col) {
                new_entry.priority = action.priority;
            }
            return new_entry;
        }
        default:
            return state;
    }
}

const matrix = function(state, action){
    switch(action.type){
        case "PRAC_CHANGE_SET_JUSTIFICATION": {
            const new_state = {...state};
            new_state.entries = new_state.entries.map(
                (row, i) => row.map(
                    (e,j) => entry(e, action, i,j)
                )
            )
            return new_state;
        }
        case "PRAC_CHANGE_SET_OVERRIDE_SCORE": {
            const new_state = Object.assign({}, state);
            new_state.overrideScore = action.score;
            return new_state;
        }
        case "PRAC_CHANGE_SET_PRIORITY": {
            const new_state = {...state};

            new_state.entries = new_state.entries.map(
                (row, i) => row.map(
                    (e, j) => entry(e, action, i, j)
                )
            )

            new_state.suggestedScore = calculateScore(new_state);
            return new_state;
        }
        default:
            return state;
    }
}

const justifications = function(state, action, col){
    switch(action.type) {
        case "PRAC_CHANGE_SET_COLUMN_JUSTIFICATION":
            if (action.column === col){
                return action.justification
            }
            return state;
        default:
            return state;
    }
}

const practiceChange = function(state, action, index){
    switch(action.type){
        case "PRAC_CHANGE_SET_MATRIX": {
            if(state.id === action.id){
                let new_state = Object.assign({}, state);
                new_state.matrixData = {...action.matrix_data};
                new_state.matrixData.entries = deepCopyMatrixData(action.matrix_data.entries)
                return new_state;
            }
            return state;
        }
        case "PRAC_CREATE_PRAC_CHANGE": {
            let new_state = buildPractice(action)
            return new_state;
        }

        case "PRAC_CHANGE_SET_COLUMN_JUSTIFICATION":
            const new_state = {...state};
            new_state.matrixData.columnJustifications = new_state.matrixData.columnJustifications.map(
                (j,i) => justifications(j, action, i))
            return new_state;
        case "PRAC_CHANGE_SET_JUSTIFICATION":
        case "PRAC_CHANGE_SET_OVERRIDE_SCORE":
        case "PRAC_CHANGE_SET_PRIORITY":{
            if(action.id === state.id){
                let new_state = Object.assign({}, state);
                new_state.matrixData = matrix(new_state.matrixData, action)
                return new_state;
            }
            return state;
        }
        default:
            return state;
    }
};

const defaultState = []

export const practiceChanges = function(state=defaultState, action){
    switch(action.type){
        case "PRAC_CHANGE_SET_MATRIX": {
            let new_state = state.map((v, i)=>{
                return practiceChange(v, action, i);
            })
            return new_state;
        }
        case "PRAC_CHANGE_SET_DATA": {
            let new_state = action.practice_changes.map(buildPracticeChangeFromServer);
            return new_state;
        }

        case "PRAC_CREATE_PRAC_CHANGE": {
            let new_state = [...state, practiceChange({}, action)];
            return new_state;
        }
        case "PRAC_CHANGE_SET_COLUMN_JUSTIFICATION":
        case "PRAC_CHANGE_SET_JUSTIFICATION":
        case "PRAC_CHANGE_SET_OVERRIDE_SCORE":
        case "PRAC_CHANGE_SET_PRIORITY":
            return state.map((pc,i) => {
                if(pc.id === action.id){
                    return practiceChange(pc, action, i)
                }else{
                    return pc;
                }
            })
        default:
            return state;
    }
}