import React from 'react';
import {PropTypes} from "prop-types";
import {connect} from 'react-redux';
import List from "@material-ui/core/List/List"
import ListItem from "@material-ui/core/ListItem/ListItem"
import ListItemText from "@material-ui/core/ListItemText/ListItemText"
import Card from "@material-ui/core/Card/Card"
import Typography from "@material-ui/core/Typography/Typography"
import {authUser} from "../actions/authenticationActions";
import Button from "@material-ui/core/Button/Button";
import Grid from "@material-ui/core/Grid/Grid";
import CardHeader from "@material-ui/core/CardHeader/CardHeader";
import CardContent from "@material-ui/core/CardContent/CardContent";
import CardActions from "@material-ui/core/CardActions/CardActions";
import Divider from "@material-ui/core/Divider/Divider";
import {setCurrentScreen} from "../actions/projectActions";
import ProjectContentScreen from "./ProjectContentScreen";
import {removeProject} from "../actions/projectsActions";
import {deleteProject} from "../actions/serverActions";
const styles = {
    container: {
        background: "white",
        height:"100%",
        overflowY:"hidden",
    }
}


class ProjectsList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {

        return (

                <Card style={{margin:"10px", height:"auto"}} raised>
                    <CardHeader title={this.props.proj.name}/>
                    <CardContent>
                        <p style={{margin:0}}>Time Period: {this.props.proj["time_period"]}</p>
                        <p style={{margin:0}}>City: {this.props.proj["city"]}</p>
                        <p style={{margin:0}}>Who's Involved: {this.props.proj["whos_involved"]}</p>
                        <p style={{margin:0}}>Lead Facilitator/Analyst: {this.props.proj["lead_fac_analyst"]}</p>
                    </CardContent>
                    <Divider/>
                    <CardActions style={{backgroundColor:"#D9E2EC", justifyContent:"space-around"}}>
                        <Button variant={"outlined"} onClick={id=>this.props.openProject(this.props.proj.id)}>Open</Button>
                        <Button variant={"outlined"} onClick={id=>this.props.removeProject(this.props.proj.id)}>Delete</Button>
                        <Button variant={"outlined"} onClick={v=>this.props.onClickCallback(this.props.index)}>Preview</Button>
                    </CardActions>
                </Card>
            )
    }




}

ProjectsList.propTypes = {
    onClickCallback: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {

    return {
        practiceChanges:state.practiceChanges,
        projects:state.projects
    };
}

const mapDispatchToProps = dispatch => ({
    setCurrentScreen: (n) => {
        dispatch(setCurrentScreen(n))
    },
    removeProject: (id) => {
        dispatch(deleteProject(id))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsList);