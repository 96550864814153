

import {saveMixin} from "./serverActions";

export const setJustification =saveMixin((id, row, col, just)=>({
    type:"PRAC_CHANGE_SET_JUSTIFICATION",
    id:id,
    row:row,
    col:col,
    justification: just,
}));


export const setPriority = saveMixin((id, row, col, prio)=>({
    type:"PRAC_CHANGE_SET_PRIORITY",
    id:id,
    row:row,
    col:col,
    priority: prio,
}));


export const setColumnJustification = saveMixin((id, col, just)=>({
    type:"PRAC_CHANGE_SET_COLUMN_JUSTIFICATION",
    id:id,
    column:col,
    justification:just,
}));

export const setOverrideScore= saveMixin((id, score)=>({
    type:"PRAC_CHANGE_SET_OVERRIDE_SCORE",
    id:id,
    score,
}));

export const setMatrixData = (matrix_data, overrideScore, col_justs) => ({
    type:"MI_SET_MATRIX_DATA",
    matrix_data,
    overrideScore,
    col_justs
});

