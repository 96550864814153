import React from 'react';
import {PropTypes} from "prop-types";
import HelpOutline from "@material-ui/icons/HelpOutline"
import Popper from "@material-ui/core/Popper/Popper";
import Fade from "@material-ui/core/Fade/Fade";
import Typography from "@material-ui/core/Typography";
import Tooltip from '@material-ui/core/Tooltip';

const styles = {

    container: {
        background: "white",
        height:"100%"
    },
    popperTri: {
        backgroundColor:"rgb(0,103,114)",
        position:"absolute",
        width:"40px",
        height:"40px",
        left:"130px",
        margin:"auto",
        zIndex:10,
        transform: "rotate(45deg)"
    },
    popperContentWrapper: {
        position:"absolute",
        padding:"5px",
        minHeight:"20px",
        zIndex:11,
        backgroundColor: 'rgb(0,103,114)',
        borderRadius:"5px"
    },
    popperContentInner: {
        width:"100%",
        boxSizing:"border-box",
        backgroundColor:"white",
        padding: "5px",
        borderRadius:"2.5px",
        zIndex:12
    }
}
class HelpPopper extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            popperAnchor: null,
            popperOpen: false,
        }
    }

    handleOpenPopper = (event) => {
        this.setState({
            popperAnchor: event.target,
            popperOpen: true
        })
    }

    handleClosePopper = () => {
        this.setState({
            popperOpen: false
        })
    }

    render() {
        return (
            <Tooltip title={<Typography style={{color:"white"}} variant={"caption"}>{this.props.children}</Typography>} arrow>
            <HelpOutline fontSize={"small"} style={{...(this.props.style || {})}}/>
            {/*<Popper transition style={{
                marginTop:"15px", width:"300px",
            }} open={this.state.popperOpen} anchorEl={this.state.popperAnchor}>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <div style={{overflowY:"auto"}}>
                            <div style={styles.popperTri}/>
                            <div style={styles.popperContentWrapper}>
                                <div style={styles.popperContentInner}>
                                    <Typography variant={"caption"}>
                                        {this.props.children}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </Fade>
                )}
            </Popper>*/}
            </Tooltip>
        )
    }
}

export default HelpPopper;