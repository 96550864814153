import React from 'react';
import {PropTypes} from "prop-types"
import {theme} from "../constants/colors";


const styles = {
    listHighlighter:(index) => ({
        backgroundColor: theme.palette.primary.main,
        position:"absolute",
        height:"50px",
        zIndex:1,
        width:"100%",
        transition:"all 0.4s ease",
        marginTop:(50*index) + "px"
    }),
    listItem: (index, hl_index) => ({
        height:"50px",
        zIndex:2,
        position:"relative",
        cursor:"pointer",
        padding:"5px",
        boxSizing:"border-box",
        color: index === hl_index ? "white" : "black",
        transition: "color 0.4s ease",
    }),
    listContainer: {
        position:"relative",
        width:"100%",
        height:"100%",

    }
}

class HighlightedScrollList extends React.Component {

    constructor(props) {
        super(props);
    }

    renderListItem = (item, index) => {
        return <div onClick={_=>this.props.clickCallback(index)} style={styles.listItem(index, this.props.index)}>{item}</div>
    }


    render() {
        return <div style={styles.listContainer}>
            <div style={styles.listHighlighter(this.props.index)}/>
            {this.props.items.map((f, i) =>
                this.renderListItem(f, i)
            )}
            </div>
    }
}

HighlightedScrollList.propTypes = {
    index: PropTypes.number,
    clickCallback: PropTypes.func,
    items: PropTypes.array,
    color: PropTypes.string,
}

export default HighlightedScrollList;