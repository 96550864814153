
const COLS = 6
const deepCopyMatrixData = function(data){
    return data.map(row => {
        return row.map(entry => {
            return {
                justification: entry.justification,
                priority: entry.priority,
            }
        })
    })
}

const generateEntry = function(){
    return {
        justification: "",
        priority: null,
    }
}

const calculateScore = function(state){
    let suggested_score = 0;
    let cols = [];
    const mi = state;
    for(let i = 0; i < mi.entries.length; i++){

        for(let j = 0; j < mi.entries[i].length; j++){
            if (cols.length < j+1){
                cols.push([]);
            }
            if(i===0 && (j === 1 || j === 4)){
                cols[j].push(0);
                continue;
            }
            if(i===1 && (j === 4)){
                cols[j].push(0);
                continue;
            }
            cols[j].push(mi.entries[i][j].priority !== null
                ? mi.entries[i][j].priority : 2);
        }
    }
    for(let i = 5; i >= 0; i--){
        let row = cols.map(c=>c[i]);
        if(row.every(k=>k < 2)){
            if(row.some(k=>k==1)){
                suggested_score = (i) + 0.5;
            }else{
                suggested_score = (i+1);
            }
        }
    }
    console.log("suggested score", suggested_score);
    return suggested_score;
}

const defaultState = {
    entries: [...Array(6).keys()].map(
        _=>[...Array(COLS).keys()].map(generateEntry)
    ),
    suggestedScore:0,
    overrideScore:0,
    columnJustifications: [...Array(COLS).keys()].map(_=>""),
}

const entry = function(state, action, row, col){
    switch (action.type){

        case "MI_SET_JUSTIFICATION": {
                let new_entry = {...state};
                if (row === action.row && col === action.col) {
                    new_entry.justification = action.justification;
                }
                return new_entry;
            }
        case "MI_SET_PRIORITY": {
                let new_entry = {...state};
                if (row === action.row && col === action.col) {
                    new_entry.priority = action.priority;
                }
                return new_entry;
            }
        case "PROJ_SET_CURRENT_PRACTICE_CHANGE":
        default:
            return state;
    }
}

const justifications = function(state, action, col){
    switch(action.type) {
        case "MI_SET_COLUMN_JUSTIFICATION":
            if (action.column === col){
                return action.justification
            }
            return state;
        default:
            return state;
    }
}


export const matrixInstance = function(state=defaultState, action){
    switch(action.type){
        case "MI_SET_JUSTIFICATION": {
            const new_state = {...state};
            new_state.entries = new_state.entries.map(
                (row, i) => row.map(
                    (e,j) => entry(e, action, i,j)
                )
            )
            return new_state;
            }
        case "PRAC_CHANGE_SET_OVERRIDE_SCORE":
        case "MI_SET_OVERRIDE_SCORE": {
            const new_state = Object.assign({}, state);
            new_state.overrideScore = action.score;
            return new_state;
        }
        case "MI_SET_PRIORITY": {
            const new_state = {...state};

            new_state.entries = new_state.entries.map(
                (row, i) => row.map(
                    (e, j) => entry(e, action, i, j)
                )
            )

            new_state.suggestedScore = calculateScore(new_state);
            return new_state;
            }
        case "MI_SET_MATRIX_DATA": {
            let new_state = Object.assign({}, state);
            new_state.entries = deepCopyMatrixData(action.matrix_data);
            new_state.suggestedScore = calculateScore(new_state);
            new_state.columnJustifications = action.col_justs.map(v=>v);
            new_state.overrideScore = action.overrideScore;
            return new_state;
        }
        case "MI_SET_COLUMN_JUSTIFICATION":
            const new_state = {...state};
            new_state.columnJustifications = new_state.columnJustifications.map(
                (j,i) => justifications(j, action, i))
            return new_state;
        default:
            return state;
    }
}