
import {setCurrentPracticeChange} from "./projectActions";
import {setMatrixData} from "./matrixInstanceActions";
import {saveMixin} from "./serverActions";

export const setPracticeChangeMatrix = (id, matrix_data) => ({
    type:"PRAC_CHANGE_SET_MATRIX",
    id,
    matrix_data,
})

export const setPracticeChanges =(practice_changes)=>({
    type:"PRAC_CHANGE_SET_DATA",
    practice_changes
});



export const createPracticeChange = saveMixin((project_id, issue_name, issue_description, prac_change_name, prac_change_description, indicators, id)=> ({
    type: "PRAC_CREATE_PRAC_CHANGE",
    project_id,
    issue_name,
    issue_description,
    practice_change_name: prac_change_name,
    practice_change_description: prac_change_description,
    indicators,
    id,

}));




export const setPracticeChange = (id)=>{
    return (dispatch, getState) => {
        dispatch(setCurrentPracticeChange(id));


    }
};