    import React from 'react';
import {PropTypes} from "prop-types";
import {connect} from 'react-redux';
import Card from "@material-ui/core/Card/Card"
import CardActions from "@material-ui/core/CardActions/CardActions"
import Typography from "@material-ui/core/Typography/Typography"
import TextField from "@material-ui/core/TextField/TextField"
import Button from "@material-ui/core/Button/Button";
import {authUser} from "../../actions/authenticationActions";
import logo from "../../resources/crcwsc-logo.png"
//import loginImg from "../../resources/login-screen-img.jpg"
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
    import {InputBaseProps as startAdornment} from "@material-ui/core/InputBase/InputBase";
    import InputAdornment from '@material-ui/core/InputAdornment';
    import Fab from "@material-ui/core/Fab/Fab";
    import Link from "@material-ui/core/Link/Link";

class LoginScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username:"",
            password:"",
        }
        this.handleLoginClick = this.handleLoginClick.bind(this);
    }

    handleTextChange(key, e){
        let newstate = {};
        newstate[key] = e.target.value;
        this.setState(newstate);
    }

    handleLoginClick(){
        this.props.loginUser(this.state.username, this.state.password)
    }

    render() {

        return (
            <div style={{display:"flex", flexDirection:"row",width:"100%", height:"100%"}}>
                <div style={{flexBasis:"60%",margin:"auto"}}>
                    <img style={{display:"block", width:"50%", margin:"auto", marginLeft:"10%"}} src={logo}/>
                    <Card raised={true} style={{padding:"2%", boxSizing:"border-box", margin:"0% 10%",}}>
                        <div style={{height:"30px"}}>
                        </div>
                        <Typography variant={"h4"} align={"center"} style={{color:"#044E54"}}>Transition Dynamic Framework</Typography>
                        <div style={ {textAlign: "right"} } > <small> Build {process.env.REACT_APP_VERSION} </small><br /></div>

                        <Typography variant={"subtitle1"} color={"error"} align={"center"}>
                            {this.props.user_state.user_authentication_error ? this.props.user_state.user_authentication_error_string : ""}
                        </Typography>

                        <div style={{height:"50px"}}>
                        </div>

                        <TextField style={{margin:"2% 2%",width:"95%"}} variant={"outlined"}
                                   label={"Username"}
                                   InputLabelProps={{shrink:true}}
                                   InputProps={{startAdornment:<InputAdornment position={"start"}><PersonIcon/></InputAdornment>}}
                                   placeholder={"xxxxx@hotmail.com"}
                                   onChange={v=>this.handleTextChange("username", v)}
                                   fullWidth/>
                        <TextField style={{margin:"2% 2%",width:"95%"}} variant={"outlined"}
                                   label={"Password"}
                                   InputLabelProps={{shrink:true}}
                                   InputProps={{startAdornment:<InputAdornment position={"start"}><LockIcon/></InputAdornment>}}
                                   onChange={v=>this.handleTextChange("password", v)}
                                   fullWidth
                                   type={"password"} />
                        <div style={{display:"flex", justifyContent:"flex-end",marginRight:"10px"}}>
                            <Link style={{color:"#9FB3C8"}}>Forgot Password?</Link>
                        </div>
                        <div style={{height:"50px"}}>
                        </div>
                        <CardActions>
                            <Fab onClick={this.handleLoginClick} variant="extended" color="primary" size={"large"} style={{margin:"auto",width:"20%"}}>
                                log in
                            </Fab>
                        </CardActions>

                        <div style={{height:"50px"}}>
                        </div>

                        <Typography variant={"subtitle1"} align={"center"}><Link style={{color:"#0A6C74"}}>New to TDF? Create Account</Link></Typography>
                    </Card>
                </div>
                <div style={{flexBasis:"40%",backgroundColor:"#0A6C74"}}>

                </div>

            </div>)
    }
}

LoginScreen.propTypes = {};

const mapStateToProps = (state, ownProps) => {

    return {
        user_state: state.user
    };
}

const mapDispatchToProps = dispatch => ({
    loginUser:(user_name, password) => {

        dispatch(authUser(user_name, password))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);