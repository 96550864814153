import React from 'react';
import {PropTypes} from "prop-types";
import {connect} from 'react-redux';
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import Table from "@material-ui/core/Table/Table";
import TableBody from "@material-ui/core/TableBody/TableBody";
import TableSortLabel from "@material-ui/core/TableSortLabel/TableSortLabel";
import Paper from "@material-ui/core/Paper/Paper";
import Button from "@material-ui/core/Button/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Slide from "@material-ui/core/Slide"
import Grid from "@material-ui/core/Grid/Grid";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import {practiceChanges} from "../../reducers/practiceChanges";
import Typography from "@material-ui/core/Typography/Typography";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Matrix from "../matrix/Matrix";
import ExpansionPanel from "@material-ui/core/ExpansionPanel/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails";
import {theme} from "../constants/colors"
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Fade from "@material-ui/core/Fade/Fade";
import HighlightedScrollList from "./HighlightedScrollList";
import ViewAllDialog from "./ViewAllDialog";
import {Select} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";

const styles = {
    scorePanelContainer: {
        background: "white",
        display: "flex",
        flexDirection: "column",
        padding: "1%",
        height:"100%",
        naxHeight:"100%",
        boxSizing: "border-box",
        overflowY: "auto",
        position: "relative",
        width: "100%",
    },
    controlsContainer: {
        height: "100%",
        width: "30%",
        position: "absolute",
        right: "0",
        top: "0",
    },
    divider: n => ({
        height: n + "%"
    }),
    tableContainer: {
      flex:"1",
      overflowY:"auto"
    },

    tableHeader: {
        display:"flex",
        flexDirection:"row",
        background:"white",
        position:"sticky",
        top:"0px",
        borderBottom: "solid 3px rgb(224, 224, 244)",
        zIndex:10,
    },

    tableBody: {
    },

    tableCell: {
        flex:1,
        padding: "20px",
        whiteSpace:"no-wrap",
        textOverflow:"ellipsis",
    },
    tableRow: {
        borderBottom: "solid 1px rgb(224, 224, 244)",
        display:"flex",
        flexDirection:"row",

    },
}



class GoalReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            current_practice_change_id: null,
            dialogOpen: false,
            viewAll: false,
            checked:[],
            selectedProject: props.projects[0].id
        };
    }


    renderViewAllDialog = () => {
        return <ViewAllDialog
            itemList={this.state.checked.map(v=>v).sort().map(v => this.props.practiceChanges.find(pc => pc.id === v))}
            closeCallback={_=>this.setState({viewAll:false})}
            open={this.state.viewAll}/>
    }

    toggleChecked = (o) => {
        let index = this.state.checked.indexOf(o);
        let list = [...this.state.checked];
        if(index > -1){
            list.splice(index, 1);
        }else{
            list.push(o);
        }

        this.setState({
            checked:list,
        })
    }

    renderMatrixDialog = () => {
        let highlight_list;



        return (<Dialog maxWidth={"xl"}
                        fullWidth
                        open={this.state.dialogOpen} onClose={_=>this.setState({dialogOpen: false})}>

            <DialogContent style={{
                boxSizing: "border-box",
                padding: "10px",
                background: theme.palette.primary.main,
                minHeight: "80vh",
                height:"80vh",
                maxHeight: "80vh"
            }}>
                <div style={{display:"flex", flexDirection:"column", width:"100%", height:"100%"}}>
                    <div style={{display:"flex", flexDirection:"row", flex:"1 1 auto"}}>

                        <Matrix plainRender matrixId={this.state.current_practice_change_id}/>
                    </div>
                </div>
            </DialogContent>
        </Dialog>)
    }

    getPracChangeForInd = (indicator) => {

        const project = this.props.projects.find(proj => this.state.selectedProject === proj.id);
        const prac_changes = this.props.practiceChanges.filter(prac => prac.project_id === project.id);
        let prac = prac_changes.find(prac => prac.indicators.includes(indicator.id));
        return prac;
    }

    renderIndicator = (indicator, index, catindex) => {
        let prac = this.getPracChangeForInd(indicator);
        return <div style={{display:"flex", color:"black",paddingTop:"4px", paddingBottom:"0px"}}>
            <div style={{flex:"1 0 0", textAlign:"center", color:"#00616F"}}>{prac === undefined ? "N/A" : prac.matrixData.overrideScore}</div>
            <div style={{flex:"3 0 0"}}>{catindex+1}.{index+1} {indicator.text}</div>
            <div style={{flex:"1 0 0"}}><Button disabled={prac === undefined}  onClick={_=>this.setState({current_practice_change_id: prac.id, dialogOpen:true})} variant={"contained"} color={"primary"}>MATRIX</Button></div>
            <div style={{flex:"1 0 0"}}><Checkbox disabled={prac === undefined} checked={prac===undefined?false: this.state.checked.includes(prac.id)} onChange={_=>this.toggleChecked(prac.id)}/></div>
        </div>
    }

    renderCategory = (category, index) => {
        return <div style={{display:"flex", color:"#00616F", borderBottom:"solid 3px lightgrey"}}>
            <div style={{flex:"1 0 0", fontSize:"1.4em"}}>
                {index+1}. {category.text}
            </div>
            <div style={{flex:"2 0 0"}}>
                {category.indicators.map((c,i) => this.renderIndicator(c,i,index))}
            </div>
        </div>
    }

    renderLegend = () => {
        return <div style={{position:"sticky", color:"#00616F", background:"white",  zIndex:"10", top:0, display:"flex",paddingTop:"4px", paddingBottom:"4px", borderBottom:"solid 3px lightgrey"}}>
            <div style={{flex:"1 0 0"}}>
                Goal
            </div>
            <div style={{flex:"2 0 0"}}>
                <div style={{display:"flex"}}>
                    <div style={{flex:"1 0 0", textAlign:"center"}}>Transition Phase</div>
                    <div style={{flex:"3 0 0"}}>Indicators</div>
                    <div style={{flex:"1 0 0"}}>View</div>
                    <div style={{flex:"1 0 0"}}>Compare</div>
                </div>
            </div>
        </div>
    }

    renderReportContent = () => {
        const project = this.props.projects.find(proj => this.state.selectedProject === proj.id);
        const indicator = this.props.indicators.find(ind => ind.id === project.indicator_set_id);

        return <div>
            {this.renderLegend()}
            {indicator.categories.map((cat, i) => {
                return this.renderCategory(cat,i)
            })}
        </div>
    }


    render() {
        return (<div>
            {this.renderViewAllDialog()}
            <Dialog maxWidth={"lg"} fullScreen
                    open={true} onClose={this.props.closeCallback}>
                <DialogContent style={{margin:"0", padding:0}}>
                    <div style={{ height:"100%", display:"flex", flexDirection:"column"}}>
                        <div style={{textAlign:"center", backgroundColor:"#EEE"}}>
                            <div  style={{float:"left", display:"inline-block"}}>
                                <Button variant={"contained"} style={{marginRight:"40px"}} color={"primary"} onClick={this.props.onBackCallback}>EXIT</Button>
                                Project:
                                <Select style={{width:"300px"}} value={this.state.selectedProject} onChange={e=>this.setState({selectedProject: e.target.value, checked:[]})}>
                                    {this.props.projects.map(proj => {
                                        return <MenuItem value={proj.id}>{proj.name}</MenuItem>
                                    })}

                                </Select>
                            </div>
                            <div  style={{float:"right", display:"inline-block"}}>
                                <Button variant={"contained"} style={{marginRight:"40px"}} disabled={!this.state.checked.length} color={"primary"} onClick={_=>this.setState({viewAll:true})}>VIEW SELECTED</Button>
                            </div>
                            <div style={{display:"inline-block", float:"left", width:"100%", textAlign:"center"}}>
                                {this.props.selectedReport.data.report_name}

                            </div>
                        </div>
                        <div style={{flex:1, overflow:"hidden"}}>
                            <div style={{height:"100%", overflowY:"auto"}}>
                                {this.renderReportContent()}
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            {this.renderMatrixDialog()}
        </div>)
    }

}

GoalReport.propTypes = {
    selectedReport: PropTypes.object.isRequired,
    onBackCallback: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {

    return {
        practiceChanges: state.practiceChanges,
        enablingStrategies: state.enablingStrategies,
        projects: state.projects,
        indicators: state.indicators,
        enablingStrategyTemplates: state.enablingStrategyTemplates,
    };
}

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(GoalReport);