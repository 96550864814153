import React from 'react';
import {PropTypes} from "prop-types";
import {connect} from 'react-redux';
import Card from "@material-ui/core/Card/Card";
import CardActions from "@material-ui/core/CardActions/CardActions";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import TextField from "@material-ui/core/TextField/TextField";
import Typography from "@material-ui/core/Typography/Typography";
import Button from "@material-ui/core/Button/Button";
import {submitNewProject} from "../actions/serverActions";


const styles = {
    container: {
        background: "white",
        height: "100%",
    }
}

class NewProjectPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            indicators: -1,
            text:"",
        }
        this.handleCreate = this.handleCreate.bind(this);
    }


    handleCreate() {
        this.props.makeNewProject(this.state.text, this.props.indicators[this.state.indicators].id).then(_=>{
            this.props.onProjectCreate()
        });
    }

    render() {
        return (
            <Card style={styles.container}>
                <TextField style={{margin:"2% 2%",marginTop:"25px", width:"95%"}} variant={"outlined"}
                           label={"Project Name"}
                           InputLabelProps={{shrink:true}}
                           placeholder={"eg. Sydney"}
                           onChange={(e)=>this.setState({text: e.target.value})}
                           fullWidth/>

                <List>
                    <ListItem>
                        <ListItemText>
                            <Typography variant={"h6"}>
                                Indicator Set
                            </Typography>
                        </ListItemText>
                    </ListItem>
                    {this.props.indicators.map((v, i) => {
                        return (<ListItem onClick={_ => this.setState({indicators: i})}
                                          selected={this.state.indicators === i} button>
                            <ListItemText>
                                {v.name}
                            </ListItemText>
                        </ListItem>)
                    })}
                </List>

            <CardActions style={{float:"right"}}>
                <div>
                    <Button onClick={this.handleCreate}
                            color={"primary"}
                            variant={"contained"}>
                        create
                    </Button>
                </div>
            </CardActions>
        </Card>)
    }
}

NewProjectPanel.propTypes = {
    onProjectCreate: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {

    return {
        indicators: state.indicators
    };
}

const mapDispatchToProps = dispatch => ({
    makeNewProject: (name, indicators) => {
        return dispatch(submitNewProject(name, indicators))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(NewProjectPanel);