

function create_template_from_server(obj){
    return {
        text:obj.text,
        linked_cell: obj.cell,
        id: obj.id,
    }
}

function create_template(){
    return {
        text:"",
        linked_cell: null,
        id: -1,
    }
}


function enablingStrategyTemplate(state = {}, action){

}

export function enablingStrategyTemplates(state = [], action){
    switch(action.type){
        case 'EST_SET_TEMPLATES':
            return action.templates.map(create_template_from_server);
        default:
            return state;
    }
}