import React from 'react';
import {PropTypes} from "prop-types";
import {connect} from 'react-redux';
import Grid from "@material-ui/core/Grid/Grid";
import IssuePanel from "./IssuePanel";
import PracticeChangePanel from "./PracticeChangePanel";
import {createPracticeChange} from "../../actions/practiceChangeActions";
import {submitPracticeChange} from "../../actions/serverActions";
import {buildMatrix} from "../../reducers/practiceChanges";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

class CreatePracticeChange extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            template_index: -1,
            activeStep: 0,
            issue_name: "",
            issue_description: "",
        };


        this.handleTemplateSelected = this.handleTemplateSelected.bind(this);
        this.handleIssueCallback = this.handleIssueCallback.bind(this);
        this.handleOnCreate = this.handleOnCreate.bind(this);
    }
    componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.current_practice_change === null){
            return;
        }
        if((!this.props.editing && prevProps.editing) || (!this.props.editing &&  prevProps.current_practice_change !== this.props.current_practice_change)){
            this.setState({
                issue_name: this.props.current_practice_change.issue_name,
                issue_description: this.props.current_practice_change.issue_description
            })
        }
    }

    handleTemplateSelected(template_index){
        this.setState({
            template_index: template_index,
            issue_name: this.props.issue_templates[template_index].name,
            issue_description: this.props.issue_templates[template_index].description,
        })
    }

    handleIssueCallback(issue_name, issue_description){
        this.setState({
            issue_name:issue_name,
            issue_description: issue_description,
        })
    }

    handleOnCreate(name, desc, indicators){
        if (name==="" || name===undefined){
            this.setState({openPCAlertDialog:true})
        }

        else if (indicators.length===0){
            this.setState({openIndAlertDialog:true})
        }

        else{
            this.props.onCreateCallback()
            this.props.createPracticeChange(
                this.props.project,
                this.state.issue_name,
                this.state.issue_description,
                name,
                desc,
                indicators);
        }
    }

    renderIssueAlertDialog = () => {
            return (<Dialog open={this.state.issue_name === "" && this.state.activeStep === 1}>
                <DialogTitle><ReportProblemIcon style={{color:"red", marginBottom:"-3px", marginRight:"10px"}}/>Issue Name Required</DialogTitle>
                <Divider/>
                <DialogContent style={{paddingTop:24}}>Please provide name of your issue</DialogContent>
                <DialogActions><Button variant={"contained"} color={"primary"} onClick={_=>this.setState({activeStep:0})}>Understand</Button></DialogActions>
            </Dialog>)
    };


    renderPCAlertDialog = () => {
        return (<Dialog open={this.state.openPCAlertDialog}>
            <DialogTitle><ReportProblemIcon style={{color:"red", marginBottom:"-3px", marginRight:"10px"}}/>Practice Change Name Required</DialogTitle>
            <Divider/>
            <DialogContent style={{paddingTop:24}}>Please name your practice change</DialogContent>
            <DialogActions><Button variant={"contained"} color={"primary"} onClick={_=>this.setState({openPCAlertDialog:false})}>Understand</Button></DialogActions>
        </Dialog>)
    };

    renderIndAlertDialog = () => {
        return (<Dialog open={this.state.openIndAlertDialog}>
            <DialogTitle><ReportProblemIcon style={{color:"red", marginBottom:"-3px", marginRight:"10px"}}/>Indicators Required</DialogTitle>
            <Divider/>
            <DialogContent style={{paddingTop:24}}>Please select your indicators</DialogContent>
            <DialogActions><Button variant={"contained"} color={"primary"} onClick={_=>this.setState({openIndAlertDialog:false})}>Understand</Button></DialogActions>
        </Dialog>)
    };


    handleNextButtonClicked = () =>{
        this.setState({activeStep:1})
    };

    handleBackButtonClicked = () => {
        this.setState({activeStep:0})

    };


    render() {
        let steps = ['Define your issue', 'Suggest practice change'];
        return (
            <div>
                <div style={{display: "block"}}>
                    <Stepper style={{padding:"20px"}} nonLinear activeStep={this.state.activeStep}>
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepButton style={{fontVariant:"small-caps"}} onClick={_=>this.setState({activeStep:index})}>
                                    {label}
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                </div>
                <Divider/>
                <div style={{display: "flex", flexDirection: "row", paddingBottom:"10px"}}>
                    {this.state.activeStep === 0 ?
                        <IssuePanel issueCallback={this.handleIssueCallback}
                                    issue_name={this.state.issue_name}
                                    issue_description={this.state.issue_description}
                                    handleNextButtonClicked={this.handleNextButtonClicked}
                                    templateIndex={this.state.template_index}
                                    templateCallback={this.handleTemplateSelected}/>:
                        <PracticeChangePanel editing={this.props.editing}
                                             handleBackButtonClicked={this.handleBackButtonClicked}
                                             currentPracChange={this.props.current_practice_change}
                                             onCreateCallback={this.handleOnCreate}
                                             templateIndex={this.state.template_index}/>
                    }
                </div>
                {this.renderIssueAlertDialog()}
                {this.renderPCAlertDialog()}
                {this.renderIndAlertDialog()}
            </div>)
        {/*<Grid container spacing={32}>
                <Grid item container xl={6} lg={6} md={6} sm={6} xs={6} style={{height:"100%"}}>
                    <IssuePanel issueCallback={this.handleIssueCallback} name={this.state.issue_name} description={this.state.issue_description} templateCallback={this.handleTemplateSelected}/>
                </Grid>
                <Grid item container xl={6} lg={6} md={6} sm={6} xs={6} style={{height:"100%"}}>
                    <PracticeChangePanel editing={this.props.editing} currentPracChange={this.props.current_practice_change} onCreateCallback={this.handleOnCreate} templateIndex={this.state.template_index}/>
                </Grid>
            </Grid>*/
        }
    }

}

CreatePracticeChange.propTypes = {
    createPracticeChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    if(state.project.current_project === null){
        return {project: null};
    }
    return {
        issue_templates: state.issueTemplates,
        current_practice_change: state.practiceChanges.find(v=>v.id === state.project.current_practice_change) || null,
        project: state.projects[state.project.current_project].id,
    };
}

const mapDispatchToProps = dispatch => ({
    createPracticeChange: (pid, iname, idesc, pcname, pcdesc, indics) => {

        return dispatch(submitPracticeChange(pid, {
            project_id:pid,
            issue_name: iname,
            issue_description:idesc,
            practice_change_name: pcname,
            practice_change_description: pcdesc,
            indicators:indics,
            practice_change_data: buildMatrix()
        }));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(CreatePracticeChange);