import React from 'react';
import {PropTypes} from "prop-types";
import {connect} from 'react-redux';
import Paper from "@material-ui/core/Paper/Paper";
import AddCircle from "@material-ui/icons/AddCircle"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography/Typography";
import Grid from "@material-ui/core/Grid/Grid";
import Divider from "@material-ui/core/Divider/Divider";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Popover from "@material-ui/core/Popover/Popover";
import HelpOutline from "@material-ui/icons/HelpOutline"
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import TextField from "@material-ui/core/TextField/TextField";
import HelpPopper from "../HelpPopper";
import Button from "@material-ui/core/Button";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

const styles = {
    container: {
        height: "100%",
        width: "100%",
        background: "white",
        overflow:"hidden",
        margin:"0px 24px",
    },
    centerDiv: {
        display: "table",
        margin: "auto"
    },
    newIssueContainer: {
        boxSizing: "border-box",
        padding: "3%",
        display: "flex",
        flexDirection: "row",
    },
    fill: {
        maxHeight: "100%",
        margin: "1%"
    },
    templateContainer: {
        height: "100%",
        margin: "3%",
        overflowY:"auto",
        boxSizing: "border-box"
    }

}

class IssuePanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {hoverIndex: -1, issueName:this.props.issue_name, issueDescription:this.props.issue_description};
        this.handleChange = this.handleChange.bind(this);
        this.renderNewIssue = this.renderNewIssue.bind(this);
        this.handleTemplateClick = this.handleTemplateClick.bind(this);
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }


    handleMouseEnter(index, event) {
        this.setState({
            hoverIndex: index,
            hoverTarget: event.target,
        })
    }

    handleMouseLeave(index, event) {
        this.setState({
            hoverIndex: -1
        })
    }

    handleChange(key, event){
        let new_state = {...this.state};
        new_state[key] = event.target.value;
        this.setState(new_state)
        this.props.issueCallback(new_state.issueName  , new_state.issueDescription );

        //if (key==="issueName"){this.setState({issueName:event.target.value});}
        //else if (key==="issueDescription"){this.setState({issueDescription:event.target.value});}
        //console.log(this.state.issueName)
        //this.props.issueCallback(this.state.issueName,this.state.issueDescription)
    }

    renderNewIssue() {
        return (
            <div style={{display: "flex", flexDirection: "column", flexBasis:"50%", margin:"0px 5px"}}>

                <div style={{height:"10px"}}></div>
                <div style={{margin:"10px 5px"}}>
                    <Typography variant={"subtitle1"}>Issue Name</Typography>
                    <TextField fullWidth variant={'outlined'} value={this.state.issueName}
                               placeholder={"What is the issue?"}
                               onChange={e => this.handleChange("issueName", e)}/>
                </div>
                <div style={{margin:"10px 5px"}}>
                    <Typography variant={"subtitle1"}>Issue Description</Typography>
                    <TextField fullWidth multiline rows={4} variant={'outlined'} value={this.state.issueDescription}
                               placeholder={"Describe the issue..."}
                               onChange={e=> this.handleChange("issueDescription", e)}/>
                </div>
            </div>
        )
    }

    handleTemplateClick(template, index) {
        this.props.templateCallback(index);
        this.setState({
            issueName: template.name,
            issueDescription: template.description,
        })
    }

    renderTemplates() {
        return (<List>
            {
                (this.props.templates ?
                    this.props.templates.map((template, i) => {
                        return (<div key={"ipd" + i}>
                            <Tooltip
                                style={{
                                    background:"#AFAFAF"
                                }}
                                placement={"right"}
                                title={
                                <Typography variant={"subtitle1"} style={{
                                    "color":"white"
                                }}>
                                    {template.description}
                                </Typography>
                            }>
                                <ListItem style={{backgroundColor:"rgb(255,255,255)", fontSize:"0.9em"}} dense button
                                          onClick={_ => this.handleTemplateClick(template, i)}>
                                    {template.name}
                                </ListItem>
                            </Tooltip>
                            <Divider/>
                        </div>)
                    }) : "")
            }
        </List>)
    }

    render() {
        return (
            <div style={styles.container}>
                <div style={{padding:"15px", fontSize:"1.1em"}}>New Issue
                    <HelpPopper style={{marginTop:"10px"}}>
                        Choose from the list of pre-existing
                        issues. If your desired issue is not in
                        the list, create a new issue by entering
                        an ‘issue name’ and a brief description
                        of the issue in ‘issue description’
                    </HelpPopper>
                </div>
                <div style={styles.newIssueContainer}>
                    {this.renderNewIssue()}

                    <div style={{display:"flex", flexDirection: "column", flexBasis:"50%", margin:"0px 5px"}}>
                        <div>
                            Predefined Templates
                            <HelpPopper >
                                Choose from the list of pre-existing
                                issues. If your desired issue is not in
                                the list, create a new issue by entering
                                an ‘issue name’ and a brief description
                                of the issue in ‘issue description’
                            </HelpPopper>
                        </div>

                        <div style={styles.templateContainer}>
                            {this.renderTemplates()}
                        </div>
                    </div>
                </div>

                <Button style={{float:"right"}} variant={"contained"} color={"primary"}
                        onClick={this.props.handleNextButtonClicked}>
                    Next
                    <NavigateNextIcon/>
                </Button>
            </div>
        )
    }

}

IssuePanel.propTypes = {
    templateCallback: PropTypes.func.isRequired,
    issueCallback: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    if(state.project.current_project === null){
        return {templates:[]};
    }
    let current_project = state.projects[state.project.current_project];
    let templates = state.issueTemplates.filter(p=>current_project.indicator_set_id == p.indicator_set_id);
    return {
        current_practice_change: state.project.current_practice_change,
        templates: templates,
    };
}

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(IssuePanel);