import React from 'react';
import {PropTypes} from "prop-types";
import {connect} from 'react-redux';
import Typography from "@material-ui/core/Typography/Typography";
import Divider from "@material-ui/core/Divider/Divider";
import Grid from "@material-ui/core/Grid/Grid";
import Matrix from "../matrix/Matrix";
import Paper from "@material-ui/core/Paper/Paper";
import ReportTable from "./ReportTable";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ReportHeatMap from "./ReportHeatMap";
import VisualSummary from "./VisualSummary";
import {createReport} from "../../actions/serverActions";
import CreateReportPanel, {templates} from "./CreateReportPanel";

const styles = {
    scorePanelContainer: {
        background: "white",
        display:"flex",
        flexDirection:"column",
        maxHeight: "100%",
        height: "100%",
        padding: "1%",
        boxSizing: "border-box",
        overflow:"hidden",
    },
    divider: n => ({
        height: n + "%"
    }),
}


class ReportingScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            creatingReport: false,
            selectedReport: null
        };
        this.handleTypeClick = this.handleTypeClick.bind(this);
    }

    handleTypeClick(n){
        this.setState({
            insightType: n,
            selectedReport: null,
        })
    }


    addReport = () => {
        this.setState({
            creatingReport: true
        })
    }


    handleAddReport = (template, name, description) => {

        this.props.addReport({
            report_type: template.name,
            report_name: name,
            report_description: description,
            ...(template.data ? template.data() : {})
        })
    }

    render() {

        let ReportComponent = null;
        let report = null;
        let component_props = {};
        if(this.state.selectedReport !== null){
            report = this.props.reports.find(r => r.id === this.state.selectedReport);
            let report_template = templates.find(temp => {

                return temp.name === report.data.report_type;
            })
            ReportComponent = report_template.report_component;
            component_props = report_template.component_props;
        }

        if(ReportComponent   !== null){
            return <div style={{height:"100%", width:"100%", display:"flex", flexDirection:"row", boxSizing:"border-box", padding:"15px", overflowY:"auto"}}>
                 <ReportComponent {...component_props} onBackCallback={_=>this.setState({selectedReport: null})} selectedReport={report}/>
            </div>
        }

        return (
            <div style={{height:"100%", width:"100%", display:"flex", flexDirection:"row", boxSizing:"border-box", padding:"15px", overflowY:"auto"}}>
                <div style={{flex:"1 0 0"}}>
                    <Paper style={styles.scorePanelContainer} elevation={4}>
                        <Typography variant={"h4"}>
                            Reports
                        </Typography>
                        <Divider/>

                        <List dense>
                            {
                                this.props.reports.map(rep => {
                                    return <ListItem onClick={_=>this.setState({selectedReport: rep.id})} dense>{rep.data.report_name || "title"}</ListItem>
                                })
                            }

                            <ListItem dense button onClick={this.addReport}>
                                <ListItemText>add report</ListItemText>
                            </ListItem>
                        </List>
                    </Paper>
                </div>
                <div style={{width:"40px"}}/>
                <div style={{flex: "5 0 0"}}>
                    {this.state.creatingReport ? <CreateReportPanel createReportCallback={this.handleAddReport}/> :
                        this.renderAboutPanel()
                    }


                </div>
        </div>);
    }

    renderAboutPanel = () => {
        return <Paper style={styles.scorePanelContainer}>
            <Typography variant={"h3"}>
                About
            </Typography>
            <div>
                <p>
                    The reporting functionality allows you to analyse trends and themes in
                    city transition data through different filters, and to present the data in
                    ways that are easy to communicate.
                </p>
                <p>
                    You can filter data according to practice change, WSC Index goal, or
                    enabling strategy. You can also compare the transition phases across
                    different practice changes.
                </p>
            </div>
        </Paper>
    }
}

ReportingScreen.propTypes = {};

const mapStateToProps = (state, ownProps) => {

    return {
        reports:state.reports
    };
}

const mapDispatchToProps = dispatch => ({

    addReport: (data) => {
        dispatch(createReport(data))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportingScreen);