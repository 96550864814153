import React from 'react';
import {PropTypes} from "prop-types"
import {setCurrentScreen} from "../actions/projectActions";
import {connect} from "react-redux";
import Button from "@material-ui/core/Button/Button";


const styles = {
    container: (up) => ({
        top: up ? "-15vh": "0",
        position:"absolute",
        transition:" 0.3s all",
        width:"100%",
    }),
    innerWrapper: {
        width:"50%",
        minWidth:"700px",
        height:"15vh",
        margin:"auto",
    },
    bar: {
        height:"25px",
        position:"relative",
        overflow:"hidden",
        marginTop:"2.5px",
        border:"solid 4px rgb(0,103,114)",
        borderRadius:"12.5px",
        background:"white"
    },
    barInner: (val) => ({
        height:"100%",
        overflow:"hidden",
        width:val + "%",
        transition:"0.3s all",
        background:"rgba(0,103,114,0.8)"
    })

};

class ProjectProgression extends React.Component {


    constructor(props) {
        super(props);
        this.nextCallback = this.nextCallback.bind(this);
        this.prevCallback = this.prevCallback.bind(this);
    }


    nextCallback(){
        this.props.setCurrentScreen(Math.max(0, this.props.current_screen + 1));
    }

    jumpCallback = (index) => {
        this.props.setCurrentScreen(Math.max(0, index))
    }

    prevCallback(number){
        this.props.setCurrentScreen(Math.max(0, this.props.current_screen - 1));
    }

    renderBar = (color, colora) => {
        return <div style={{...styles.bar, borderColor: color}}>
            <div style={{...styles.barInner(100*this.props.current_step/this.props.steps), backgroundColor: colora}}>

            </div>
        </div>
    }
    renderSteps = () => {
        return <div style={{width:"100%"}}>
            <div style={{width: "80%", margin:"auto"}}>
                {this.props.step_text.map((_,index) => {
                    index = index+1;
                    let textcolor = index > this.props.current_step_index ? "#000" : "#FFF"
                    let color = index > this.props.current_step_index ? "#3294a3" : "#00616F"
                    let hlcolor = index >= this.props.current_step_index ? "grey" : "#2091a3"
                    hlcolor = index === this.props.current_step_index ? "lightgreen" :hlcolor;
                    hlcolor = this.props.error ? "rgb(226, 189, 0)" : hlcolor;
                    color = this.props.error ? "#dbc44e" : color;
                    let cursor = this.props.error ? "not-allowed" : "pointer"
                    return <div style={{boxSizing:"border-box",
                            cursor:cursor,

                            transition:" 0.3s all",
                            color:textcolor,
                            fontSize:"1em",
                            position:"relative",
                            border:"solid 3px "+ hlcolor,
                            display:"inline-block",
                            backgroundColor:color,
                            width:100/this.props.step_text.length + "%",
                            height:"40px",
                            zIndex:this.props.step_text.length-index
                        }}
                        onClick={_=>!this.props.error && this.props.setCurrentScreen(this.props.step_screen[index-1])}
                    >


                        <div style={{paddingLeft:(index === 0?"0px": "26px"), textAlign:"center", lineHeight:"37px"}}>
                            {_}
                        </div>
                        <div style={{width:"0",transition:" 0.3s all", height:"0", position:"absolute", right:"-34.8px", top:"-3px", borderStyle:"solid", borderWidth:"20px 0 20px  34.8px", borderColor: "transparent transparent transparent " + hlcolor}}>
                        </div>
                        <div style={{width:"0",transition:" 0.3s all", height:"0", position:"absolute", right:"-29.4px", top:"0px", borderStyle:"solid", borderWidth:"17px 0 17px  29.4px", borderColor: "transparent transparent transparent " + color}}>
                        </div>
                    </div>

                })}
            </div>
        </div>
    }

    render() {
        let color = "rgba(0,103,114)"
        let colora = "rgba(0,103,114, 0.5)"
        if(this.props.error !== null){
            color = "rgb(226, 189, 0)"
            colora = "rgba(226, 189, 0, 0.5)"
        }else if(this.props.is_complete){
            color = "rgb(45, 160, 0)"
            colora = "rgba(45, 160, 0, 0.5)"
        }
        return (<div style={styles.container(this.props.up)}>
            <div style={styles.innerWrapper}>
                {this.renderSteps()}
                {this.renderBar(color, colora)}
                {this.props.error !== null ?
                <div style={{textAlign:"center", background:"none", color:color}}>
                    {this.props.error}
                </div>:
                [<div style={{textAlign:"center", background:"none", color:color}}>
                    {this.props.current_step}/{this.props.steps} {this.props.current_task || "task-"+this.props.current_step}
                </div>,]
                }
            </div>
        </div>);
    }
}


const mapStateToProps = (state, ownProps) => {


    return {
        current_screen: state.project.current_screen,
    };
}

const mapDispatchToProps = dispatch => ({
    setCurrentScreen: (n) => {
        dispatch(setCurrentScreen(n))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectProgression);
