import React from 'react';
import {PropTypes} from "prop-types";
import {connect} from 'react-redux';
import Paper from "@material-ui/core/Paper/Paper";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Matrix from "../matrix/Matrix";
import {theme} from "../constants/colors"
import HighlightedScrollList from "./HighlightedScrollList";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography/Typography";
import Button from "@material-ui/core/Button/Button";
import Switch from "@material-ui/core/Switch"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormGroup from "@material-ui/core/FormGroup"
import Divider from "@material-ui/core/Divider/Divider";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";



const styles = {
    scorePanelContainer: {
        background: "white",
        display: "flex",
        flexDirection: "column",
        padding: "1%",
        height:"100%",
        boxSizing: "border-box",
        position: "relative",
        width: "100%",
    },
    controlsContainer: {
        height: "100%",
        width: "30%",
        position: "absolute",
        right: "0",
        top: "0",
    },
    divider: n => ({
        height: n + "%"
    }),
    tableContainer: {
        flex:"1",
        overflowY:"auto"
    },

    tableHeader: {
        display:"flex",
        flexDirection:"row",
        background:"white",
        position:"sticky",
        top:"0px",
        borderBottom: "solid 3px rgb(224, 224, 244)",
        zIndex:10,
    },

    tableBody: {
    },

    tableCell: {
        flex:1,
        padding: "20px",
        whiteSpace:"no-wrap",
        textOverflow:"ellipsis",
    },
    tableRow: {
        borderBottom: "solid 1px rgb(224, 224, 244)",
        display:"flex",
        flexDirection:"row",

    },
}

const getProject = (props, prac_change) => {
    return props.projects.find(v => v.id === prac_change.project_id);
}


function easeInOut(currentTime, start, change, duration) {
    currentTime /= duration / 2;
    if (currentTime < 1) {
        return change / 2 * currentTime * currentTime + start;
    }
    currentTime -= 1;
    return -change / 2 * (currentTime * (currentTime - 2) - 1) + start;
}

function Transition(props) {
    return <Slide direction="up" {...props} />;
}
class ViewAllDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
            pracChangeIndex: null,
            sort: null,
            checked: [],
            perspective: null,
            ignoreScroll: false,
            colMask: null,
            viewAll: false,
            viewAllIndex: 0,
            enablingStratSelection: props.itemList.map(v => null),
            showStrategies: false,
        };

        this.scrollRef = React.createRef();
    }

    enablingStrategySelected = (index, id) => {
        let new_list = [...this.state.enablingStratSelection];
        new_list[index] = new_list[index] === id ? null : id;
        this.setState({
            enablingStratSelection: new_list,
        })
    }

    scrollListClicked = (index) => {


        const  scrollTo = (element, to, duration) => {
            var start = element.scrollTop,
                change = to - start,
                increment = 10;

            var animateScroll = (elapsedTime) => {
                elapsedTime += increment;
                var position = easeInOut(elapsedTime, start, change, duration);
                element.scrollTop = position;
                if (elapsedTime < duration) {
                    setTimeout(_=> {
                        animateScroll(elapsedTime);
                    }, increment);
                }else{
                    this.setState({
                        ignoreScroll:false,
                    })
                }

            };

            animateScroll(0);
        }

        scrollTo(this.scrollRef.current, index*this.scrollRef.current.clientHeight, 400)
        this.setState({
            viewAllIndex: index,
            ignoreScroll: true,
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(prevProps.open === false && this.props.open === true){
            this.setState({
                viewAllIndex: 0,
                enablingStratSelection: this.props.itemList.map(v => null)
            })
        }
    }

    handleViewAllScroll = (event) => {
        if(this.state.ignoreScroll)return;
        let scrollTop = event.target.scrollTop;
        let height = event.target.clientHeight;
        let index = Math.round(scrollTop/height);
        if(this.state.viewAllIndex === index){

        }else{
            this.setState({
                viewAllIndex: index
            })
        }
    }


    generateData(){
        let sums = this.props.matrixData.rows.map(v => {
            return v.elements.map(k=>0);
        });

        this.props.practiceChanges.map(v => {

            if(!this.props.itemList.some(vo => vo.id === v.id)){
                return;
            }
            v.matrixData.entries.map((v,i) => {
                v.map((k,j) => {
                    if(k.priority === null){
                        sums[i][j] += 3;
                    }else {
                        sums[i][j] += k.priority;
                    }
                })
            })

        })
        sums = sums.map(v => v.map(k => k/this.props.itemList.length));
        let max = sums.reduce((acc, v) => Math.max(acc, v.reduce((acc2, v2) => Math.max(acc2, v2)), 0), 0);
        let min = sums.reduce((acc, v) => Math.min(acc, v.reduce((acc2, v2) => Math.min(acc2, v2)), max), max);
        if(max === min){
            return sums.map(v => v.map(k => {
                return {
                    backgroundColor: "grey"
                }
            }));
        }
        sums = sums.map(v => v.map(k => 5 - Math.min(5, Math.floor(6*(max - k)/(max - min)))));
        return sums.map((v,i) => v.map((k, j) => {
            return {
                border: "none",
                backgroundColor: ["green", "GreenYellow", "yellow", "orange", "orangered", "red"][k]
            }
        }));

    }

    renderListItem = (v, index) => {
        let enabling_strat  = this.props.enablingStrategies.find(es => es.id === this.state.enablingStratSelection[index]);
        const highlight_list = [0, 1, 2, 3, 4, 5].map((_, col) => {
            return [0, 1, 2, 3, 4].map((_, row) => {

                if (enabling_strat === undefined) {
                    return {
                        color: "rgba(0,0,0,0)",
                        size: "5px",
                    }
                }
                return {
                    color: "rgba(66,244,238," + (
                        enabling_strat.selectedElems[col][row] ?
                            255 : 0
                    ) + ")",
                    size: "5px",
                }
            });
        })


        return <div style={{flex:"1 1 auto",height:"100%", display:"flex", flexDirection:"column", boxSizing:"border-box", padding:" 5% 5% 5% 5%"}}>
            <div style={{display:"flex", flex:"1 0 autp", flexDirection:"row"}}>
                <div style={{display:"flex", flexDirection:"column", flex:"1"}}>
                    <div style={{flex:"1", fontSize:"2.4vmin"}}>
                            {v.practice_change_name}
                    </div>
                    <div style={{flex:1, fontSize:"2vmin"}}>
                            {getProject(this.props, v).name}
                    </div>
                </div>
                <div style={{flex:"1"}}>
                </div>
            </div>
            <div style={{display:"flex", flex:"1 0 auto"}}>
                <Paper style={{display:"flex", boxSizing:"border-box", padding:"2%", width:"100%", backgroundColor:theme.palette.primary.main}}>
                    <div style={{flex:"2 1 0%", display:"flex" }}>
                        <Matrix plainRender justifications highlightList={highlight_list}
                                matrixId={v.id}/>
                    </div>
                    { this.state.showStrategies ?
                        <div style={{padding: "0% 0% 0% 1%", flex:"1 1 0%"}}>
                            <Paper style={{height:"100%"}}>
                                <span style={{fontSize:"1.4vmin"}}>
                                    Enabling Strategies
                                </span>
                                <Divider/>
                                <div>
                                    <List>
                                        {
                                            this.props.enablingStrategies.filter(es => es.practice_change_id === v.id).map(v => {
                                                return <ListItem button onClick={_=>this.enablingStrategySelected(index,  v.id)} selected={this.state.enablingStratSelection[index] === v.id}>{v.text}</ListItem>;
                                            })
                                        }
                                    </List>
                                </div>
                            </Paper>
                        </div>
                        : ""
                    }
                </Paper>

            </div>
        </div>
    }

    render() {

        return (<Dialog maxWidth={"lg"} fullScreen TransitionComponent={Transition}
                        open={this.props.open} onClose={this.props.closeCallback}>
            <div style={{background:"white", display:"flex", padding:"10px"}}>
                <div style={{flex:"0 1 auto", fontSize:"3vmin"}}>
                        View All
                </div>
                <div style={{flex:"1 0 auto"}}>

                </div>
                <div style={{flex:"0 0 auto"}}>
                    <FormGroup row>
                        <FormControlLabel control={
                            <Switch color={"primary"} checked={this.state.showStrategies} onChange={_=>this.setState({showStrategies: !this.state.showStrategies})}/>
                        } label={
                            "Enabling Strategies"
                        }/>
                    </FormGroup>
                </div>
                <div style={{flex:"0 0 auto"}}>
                    <Button variant={"outline"} color={"secondary"} onClick={this.props.closeCallback}>
                        close
                    </Button>
                </div>
            </div>

            <div style={{ height:"100%", overflow:"hidden", display:"flex",flexDirection:"row"}}>
                <div style={{flex: "1 0 auto", background:"white"}}>

                    <HighlightedScrollList
                        clickCallback={this.scrollListClicked}
                        index={this.state.viewAllIndex}
                        items={[...this.props.itemList.map(v => {
                            return <div>
                                {v.practice_change_name}<br/>
                                {getProject(this.props, v).name}
                            </div>
                        }), <div>
                            Heatmap<br/>
                            all projects
                        </div>]}/>
                </div>
                <div style={{flex:"3 1 auto", overflowY:"auto",  boxShadow:"inset 10px 10px 29px -19px rgba(0,0,0,1)" }} ref={this.scrollRef} onScroll={this.handleViewAllScroll}>
                    <div style={{ display:"flex", flexDirection:"row",height:"100%", alignItems:"flex-start", flexFlow:"row wrap"}}>
                        {this.props.itemList.map((v,i) => {
                            return this.renderListItem(v, i)
                        })}
                        <div style={{flex:"1 1 auto",height:"100%", display:"flex", flexDirection:"column", boxSizing:"border-box", padding:" 5% 5% 5% 5%"}}>
                            <div style={{flex:"1"}}>
                                <div>Heatmap</div>
                            </div>
                            <div style={{display:"flex", height:"100%", flex:"1 0 auto"}}>
                                <Paper style={{display:"flex", boxSizing:"border-box", padding:"2%", width:"100%", backgroundColor:theme.palette.primary.main}}>

                                    <Matrix onCellClick={this.handleCellClick} plainRender cellPropList={this.generateData()}/>
                                </Paper>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Dialog>)
    }
}

ViewAllDialog.propTypes = {
    open:PropTypes.bool,
    closeCallback: PropTypes.func,
    itemList: PropTypes.array,
};

const mapStateToProps = (state, ownProps) => {

    return {
        matrixData: state.matrixData,
        practiceChanges: state.practiceChanges,
        enablingStrategies: state.enablingStrategies,
        projects: state.projects,
        indicators: state.indicators,
        enablingStrategyTemplates: state.enablingStrategyTemplates,
    };
}
const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ViewAllDialog);