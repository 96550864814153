import React from 'react';
import {PropTypes} from "prop-types"
import DashboardPracticeChanges from "./DashboardPracticeChanges";
import Grid from "@material-ui/core/Grid/Grid";

class DashboardContainer extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Grid container>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={6} style={{height:"100%"}}>
                    <DashboardPracticeChanges/>
                </Grid>
            </Grid>
        )
    }
}

DashboardContainer.propTypes = {
}

export default DashboardContainer;