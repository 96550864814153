import React from 'react';
import {PropTypes} from "prop-types";
import {connect} from 'react-redux';
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import Table from "@material-ui/core/Table/Table";
import TableBody from "@material-ui/core/TableBody/TableBody";
import TableSortLabel from "@material-ui/core/TableSortLabel/TableSortLabel";
import Paper from "@material-ui/core/Paper/Paper";
import Button from "@material-ui/core/Button/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Slide from "@material-ui/core/Slide"
import Grid from "@material-ui/core/Grid/Grid";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import {practiceChanges} from "../../reducers/practiceChanges";
import Typography from "@material-ui/core/Typography/Typography";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Matrix from "../matrix/Matrix";
import ExpansionPanel from "@material-ui/core/ExpansionPanel/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails";
import {theme} from "../constants/colors"
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Fade from "@material-ui/core/Fade/Fade";
import HighlightedScrollList from "./HighlightedScrollList";
import ViewAllDialog from "./ViewAllDialog";

const styles = {
    scorePanelContainer: {
        background: "white",
        display: "flex",
        flexDirection: "column",
        padding: "1%",
        height:"100%",
        naxHeight:"100%",
        boxSizing: "border-box",
        overflowY: "auto",
        position: "relative",
        width: "100%",
    },
    controlsContainer: {
        height: "100%",
        width: "30%",
        position: "absolute",
        right: "0",
        top: "0",
    },
    divider: n => ({
        height: n + "%"
    }),
    tableContainer: {
      flex:"1 0 0",
      overflowY:"auto"
    },

    tableHeader: {
        display:"flex",
        flexDirection:"row",
        background:"white",
        position:"sticky",
        top:"0px",
        borderBottom: "solid 3px rgb(224, 224, 244)",
        zIndex:10,
    },

    tableBody: {
    },

    tableCell: {
        flex:1,
        padding: "20px",
        whiteSpace:"no-wrap",
        textOverflow:"ellipsis",
    },
    tableRow: {
        borderBottom: "solid 1px rgb(224, 224, 244)",
        display:"flex",
        flexDirection:"row",

    },
}


const getGoalFromIndicator = (indicator_set, id) => {
    let flat_indic = indicator_set.categories.reduce((l, v) => l.concat(
        v.indicators.map(
            h => ({cat: v.index, val: h})
        )
    ), []);
    let result = flat_indic.find(v => v.val.id === id)
    return "Goal " + indicator_set.categories[result.cat - 1].index +". " + indicator_set.categories[result.cat - 1].text
}

const makeColEntry = (name, tooltip, formatter, sort, flex="1") => ({
    name,
    tooltip,
    formatter,
    sort,
    flex,
})

const getProject = (props, prac_change) => {
    return props.projects.find(v => v.id === prac_change.project_id);
}


const getProjectFromES = (props, enabling_strat) => {
    let pc = props.practiceChanges.find(v => v.id === enabling_strat.practice_change_id)
    return getProject(props, pc);
}
const getIndicatorSet = (props, project) => {
    return props.indicators.find(v => v.id === project.indicator_set_id);
}

const getStratTemplate = (props, strategy) => {
    return props.enablingStrategyTemplates.find(t => t.id === strategy.template_id)
}

const prettyPrintIndicators = (indicator_set, indicators) => {
    if (indicator_set === undefined) {
        return "";
    }
    let indicator_list = [];
    let flat_indic = [];
    flat_indic = indicator_set.categories.reduce((l, v) => l.concat(
        v.indicators.map(
            h => ({cat: v.index, val: h})
        )
    ), []);

    for (let i = 0; i < indicators.length; i++) {
        let ind = flat_indic.find(v => v.val.id === indicators[i]);
        indicator_list.push(ind);
    }

    let lst = indicator_list.sort((a, b) => {
        if (a.cat < b.cat) {
            return -1
        } else if (a.cat > b.cat) {
            return 1;
        } else {
            return a.val.index - b.val.index;
        }
    });
    let output = [];
    let current = null;
    let start = null;
    for (let i = 0; i < lst.length; i++) {
        if (start === null) {
            start = lst[i];
            current = lst[i];
            continue;
        } else {
            let peek = lst[i];
            if (peek.cat !== current.cat) {
                output.push([start, current]);
                start = peek;
                current = peek;
                continue;
            } else if (peek.cat === current.cat && peek.val.index > current.val.index + 1) {
                output.push([start, current]);
                start = peek;
                current = peek;
                continue;
            } else if (peek.cat === current.cat && peek.val.index === current.val.index + 1) {
                current = peek;
                continue;
            }

        }

    }
    output.push([start, current]);
    let str = [];
    for (let i = 0; i < output.length; i++) {
        let current = output[i];
        if (current[0].cat === current[1].cat && current[0].val.index === current[1].val.index) {
            str.push(current[0].cat + "." + current[0].val.index);
        } else {
            str.push(current[0].cat + "." + current[0].val.index + "-" + current[1].cat + "." + current[1].val.index);
        }
    }
    return str.join(",");
}

/*
    HORRIBLE HACK TODO: FIX THIS
 */
const getBiggestIndicator = (indicators) => {
    return Math.max(...indicators);
}

const asc = (a,b) => (a < b) ? -1 : (a===b ? 0 : 1);
const desc = (a,b) => (a > b) ? -1 : (a===b ? 0 : 1);
const srt = dir => dir === "asc" ? asc : desc;

const perspectives = [
    {
        name: "practice changes",
        key: "practiceChanges",
        col_list: [
            makeColEntry(
                "Project",
                "sort",
                (state, props, v) => props.projects.filter(p => p.id === v.project_id)[0].name,
                (props, state, direction) => (a,b) => srt(direction)(getProject(props, a).name, getProject(props, b).name)),
            makeColEntry("Practice Change", "sort",
                (state, props, v) => v.practice_change_name,
                (props, state, direction) => (a,b) => srt(direction)(a.practice_change_name, b.practice_change_name)),
            makeColEntry("Goal", "sort",
                (state, props, v) => <Tooltip title={prettyPrintIndicators(getIndicatorSet(props, getProject(props, v)), v.indicators)}>
                    <div>{getGoalFromIndicator(getIndicatorSet(props, getProject(props, v)), v.indicators[0])}</div>
                </Tooltip>,
                (props, state, direction) => (a,b) => srt(direction)(getBiggestIndicator(a.indicators), getBiggestIndicator(b.indicators))),
            makeColEntry("Transition Phase", "sort",
                (state, props, v) => v.matrixData.overrideScore,
                (props, state, direction) => (a,b) => srt(direction)(a.matrixData.overrideScore, b.matrixData.overrideScore)),
            makeColEntry("Actions", "sort",
                (state, props, v, self, i) => (
                <Button color={"primary"} variant={"contained"} onClick={_ => self.openMatrix(i)}>matrix</Button>)),
            makeColEntry("Select", "sort",
                (state, props, v, self, i) => (
                    <Checkbox checked={state.checked.indexOf(v.id) > -1} onClick={_ => self.toggleChecked(v.id)}>matrix</Checkbox>)),
        ]
    },
    {
        name: "enabling strategies",
        key: "enablingStrategies",
        col_list: [
            makeColEntry("Project", "sort", (state, props, v) => {
                const practice_change = props.practiceChanges.find(pc => pc.id === v.practice_change_id);
                return props.projects.filter(p => practice_change.project_id === p.id)[0].name;
            },
                (props, state, direction) => (a,b) => srt(direction)(getProjectFromES(props, a).name, getProjectFromES(props, b).name)),
            makeColEntry("Strategy", "sort", (state, props, v) => {
                return (<Tooltip title={
                    <Typography style={{color:"white"}} variant={"subtitle1"}>{v.text}</Typography>
                }><Typography nowrap={"true"}>{v.text}</Typography></Tooltip>)
            },
            (props, state, direction) =>  (a,b) => srt(direction)(a.text, b.text)),
            makeColEntry("Template", "sort", (state, props, v) => {
                if(v.template_id === undefined){
                    return "N/A"
                }
                const template = getStratTemplate(props, v);
                return (<Tooltip title={
                    <Typography style={{color:"white"}} variant={"subtitle1"}>{template.text}</Typography>
                }><Typography nowrap={"true"}>{template.text}</Typography></Tooltip>)
            },
            (props, state, direction) => (a,b) => srt(direction)((getStratTemplate(props, a) || "AAAAA").name, (getStratTemplate(props, b) || "AAAAA").name)),
            makeColEntry("Actions", "sort",
                (state, props, v, self, i) => (
                    <Button color={"primary"} variant={"contained"} onClick={_ => self.openMatrixWithEnablingStrategy(v.id, v.practice_change_id)}>matrix</Button>)),
            // makeColEntry("Transition Score", "sort", (state, props, v) => v.matrixData.overrideScore + "(" + v.matrixData.suggestedScore + ")"),
            // makeColEntry("Actions", "sort", (state, props, v, self, i) => (
            //     <Button color={"primary"} variant={"contained"} onClick={_ => self.openMatrix(i)}>matrix</Button>)),
        ]
    },
]



class ReportTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
            pracChangeIndex: null,
            enablingStrategyIndex: null,
            sort: null,
            checked: [],
            ignoreScroll: false,
            colMask: perspectives.find(p => p.key === props.perspective).col_list.map(v=>true),
            viewAll: false,
            scrollIndex: 0,
        };
        this.prettyPrintIndicators = this.prettyPrintIndicators.bind(this);
        this.getProject = this.getProject.bind(this);
        this.openMatrix = this.openMatrix.bind(this);
        this.closeMatrix = this.closeMatrix.bind(this);
        this.toggleColumn = this.toggleColumn.bind(this);
        this.handleSort = this.handleSort.bind(this);
    }


    getProject(prac_change) {
        return this.props.projects.find(v => v.id === prac_change.project_id);
    }

    toggleChecked = (o) => {
        let index = this.state.checked.indexOf(o);
        let list = [...this.state.checked];
        if(index > -1){
            list.splice(index, 1);
        }else{
            list.push(o);
        }
        this.setState({
            checked:list,
        })
    }

    prettyPrintIndicators(indicator_set, indicators) {
        if (indicator_set === undefined) {
            return "";
        }
        let indicator_list = [];
        let flat_indic = [];
        flat_indic = indicator_set.categories.reduce((l, v) => l.concat(
            v.indicators.map(
                h => ({cat: v.index, val: h})
            )
        ), []);

        for (let i = 0; i < indicators.length; i++) {
            let ind = flat_indic.find(v => v.val.id === indicators[i]);
            indicator_list.push(ind);
        }

        let lst = indicator_list.sort((a, b) => {
            if (a.cat < b.cat) {
                return -1
            } else if (a.cat > b.cat) {
                return 1;
            } else {
                return a.val.index - b.val.index;
            }
        });
        let output = [];
        let current = null;
        let start = null;
        for (let i = 0; i < lst.length; i++) {
            if (start === null) {
                start = lst[i];
                current = lst[i];
                continue;
            } else {
                let peek = lst[i];
                if (peek.cat !== current.cat) {
                    output.push([start, current]);
                    start = peek;
                    current = peek;
                    continue;
                } else if (peek.cat === current.cat && peek.val.index > current.val.index + 1) {
                    output.push([start, current]);
                    start = peek;
                    current = peek;
                    continue;
                } else if (peek.cat === current.cat && peek.val.index === current.val.index + 1) {
                    current = peek;
                    continue;
                }

            }

        }

        output.push([start, current]);
        let str = [];
        for (let i = 0; i < output.length; i++) {
            let current = output[i];
            if (current[0].cat === current[1].cat && current[0].val.index === current[1].val.index) {
                str.push(current[0].cat + "." + current[0].val.index);
            } else {
                str.push(current[0].cat + "." + current[0].val.index + "-" + current[1].cat + "." + current[1].val.index);
            }
        }
        return str.join(",");
    }

    openMatrix(index) {
        this.setState({
            dialogOpen: true,
            pracChangeIndex: index,
        })
    }

    openMatrixWithEnablingStrategy = ( enablingID, pracChangeID) => {

        let index = this.props.practiceChanges.findIndex(o => o.id === pracChangeID);
        let esIndex = this.props.enablingStrategies.findIndex(e => e.id === enablingID);
        this.setState({

            dialogOpen: true,
            pracChangeIndex: index,
            enablingStrategyIndex: esIndex,
        })
    }

    selectPerspective(index) {
        this.setState({
            perspective: perspectives,
            colMask: perspectives[index].col_list.map(v=>true),
            sort: null,
        });
    }

    handleViewAll = () => {
        this.setState({
            dialogOpen: false,
            viewAll: true,
        })
    }

    renderSettingsPanel() {
        return (<div>
            {this.props.perspective === null ? "" :
                <ExpansionPanel>
                    <ExpansionPanelSummary>
                        Columns
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <List style={{width: "100%"}} disablePadding dense>
                            {this.getPerspective(this.props.perspective).col_list.map((v, i) => {
                                return (<ListItem onClick={v => this.toggleColumn(i)} key={i} button dense>
                                    <Checkbox
                                        checked={this.state.colMask[i]}
                                        disableRipple
                                    />
                                    <ListItemText>
                                        {v.name}
                                    </ListItemText>
                                </ListItem>)
                            })}
                        </List>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            }
        </div>)
    }

    toggleColumn(index) {
        const new_state = this.state.colMask.map((v, i) => (i === index) ? !v : v);
        this.setState({
            colMask: new_state,
        })
    }

    closeMatrix(index) {
        this.setState({
            dialogOpen: false,
            pracChangeIndex: null,
            enablingStrategyIndex: null,
        })
    }

    handleSort(index){
        if(this.state.sort === null){
            this.setState({
                sort:{
                    index:index,
                    direction: "asc"
                }
            });
        }else if (this.state.sort.index === index){
            if(this.state.sort.direction === "desc"){
                this.setState({
                    sort: null,
                })
            }else {
                this.setState({
                    sort: {
                        index: index,
                        direction: "desc"
                    }
                })
            }
        }else{
            this.setState({
                sort:{
                    index:index,
                    direction:"asc",
                }
            })
        }
    }

    renderMatrixDialog = () => {
        let highlight_list;

        if(this.state.enablingStrategyIndex !== null) {
            let enabling_strat = this.props.enablingStrategies[this.state.enablingStrategyIndex];
            highlight_list = [0, 1, 2, 3, 4, 5].map((_, col) => {
                return [0, 1, 2, 3, 4].map((_, row) => {

                    if (enabling_strat === undefined) {
                        return {
                            color: "rgba(0,0,0,0)",
                            size: "5px",
                        }
                    }
                    return {
                        color: "rgba(66,244,238," + (
                            enabling_strat.selectedElems[col][row] ?
                                255 : 0
                        ) + ")",
                        size: "5px",
                    }
                });
            })
        }


        return (<Dialog maxWidth={"xl"}
                        fullWidth
                open={this.state.dialogOpen} onClose={this.closeMatrix}>

            <DialogContent style={{
                boxSizing: "border-box",
                padding: "10px",
                background: theme.palette.primary.main,
                minHeight: "80vh",
                height:"80vh",
                maxHeight: "80vh"
            }}>
                <div style={{display:"flex", flexDirection:"column", width:"100%", height:"100%"}}>
                    <div style={{background:"white", boxSizing:"border-box", padding:"4px", fontSize:"3vmin", flex:"0 0 auto", marginBottom:"8px"}}>
                        {this.state.pracChangeIndex !== null ? (this.state.enablingStrategyIndex !== null ? "Enabling Strategy" : this.props.practiceChanges[this.state.pracChangeIndex].practice_change_name) : ""}
                    </div>
                    <div style={{display:"flex", flexDirection:"row", flex:"1 1 auto"}}>
                        {this.state.pracChangeIndex !== null ?
                            (
                                this.state.enablingStrategyIndex !== null ?
                                    <div style={{display:"flex", width:"100%", flexDirection:"row", flex:"0 1 auto"}}>
                                        <div style={{flex:"4 1 0", display:"flex", flexDirection:"column", height:"100%", marginRight:"32px"}}>
                                            <Matrix plainRender
                                                    highlightList={highlight_list}
                                                    matrixId={this.props.practiceChanges[this.state.pracChangeIndex].id}/>
                                        </div>
                                        <div style={{flex:"1 1 0", height:"100%"}}>
                                            <Paper style={{height:"100%", padding:"10px", boxSizing:"border-box"}}>
                                                {this.props.enablingStrategies[this.state.enablingStrategyIndex].text}
                                            </Paper>
                                        </div>
                                    </div>
                                    :

                                    <Matrix plainRender
                                            matrixId={this.props.practiceChanges[this.state.pracChangeIndex].id}/>
                            )
                            : ""
                        }
                    </div>
                </div>
            </DialogContent>
        </Dialog>)
    }



    renderViewAllDialog = () => {
        return <ViewAllDialog
            itemList={this.state.checked.map(v=>v).sort().map(v => this.props.practiceChanges.find(pc => pc.id === v))}
            closeCallback={_=>this.setState({viewAll:false})}
            open={this.state.viewAll}/>
    }

    getPerspective = (key) => {
        return perspectives.find(p => p.key === key);
    }

    render() {


        let entries;
        if(this.props.perspective !== null){
            const perspective = this.getPerspective(this.props.perspective);

            entries = [...this.props[perspective.key]];
            if(this.state.sort !== null){
                let {direction, index} = this.state.sort;
                if(perspective.col_list[index].sort !== undefined) {
                    entries.sort(perspective.col_list[index].sort(this.props, this.state, direction))
                }
            }
        }
        return (
            <div style={{height:"100%", display:"flex", width:"100%", flexDirection:"row"}}>
                <div style={{flex:"1 0 0%", marginRight:"32px"}}>
                    <Paper style={styles.scorePanelContainer} elevation={4}>
                        {this.renderSettingsPanel()}
                    </Paper>
                </div>
                <div style={{flex:"4 0 0", display:"flex", flexDirection:"column"}}>

                    <Paper style={styles.scorePanelContainer} elevation={4}>
                        {this.renderMatrixDialog()}
                        {this.renderViewAllDialog()}
                        <div style={{display:"flex", flexDirection:"row"}}>
                            <Button variant={"contained"} color={"primary"} onClick={this.props.onBackCallback}>
                                back
                            </Button>
                            <div style={{flex:"1 0"}}>

                            </div>
                            <Button disabled={this.state.checked.length === 0}  variant={"contained"} color={"primary"} onClick={this.handleViewAll}>
                                view selected
                            </Button>
                        </div>
                        <div style={styles.tableContainer}>
                            <div style={styles.tableHeader}>
                                    { this.props.perspective === null ? null :
                                        this.getPerspective(this.props.perspective).col_list.filter((v, i) => this.state.colMask[i]).map((col, i, arr) => {
                                            return (
                                                <div style={{...styles.tableCell, textAlign:i===0?"left":(i === arr.length - 1 ?"right": "center") }} key={i} onClick={_=>this.handleSort(i)}>
                                                    <Tooltip title={col.tooltip}>
                                                        <TableSortLabel
                                                            active={this.state.sort === null ? false : this.state.sort.index === i}
                                                            direction={this.state.sort === null ? "asc" : this.state.sort.direction}
                                                        >{col.name}</TableSortLabel>
                                                    </Tooltip>
                                                </div>)
                                        })
                                    }
                                </div>
                            <div style={styles.tableBody}>
                                {this.props.perspective === null ? null : entries.map((v, i) => {
                                    return (
                                        <div style={styles.tableRow} key={i}>
                                            {

                                                this.getPerspective(this.props.perspective).col_list.filter((v, i) => this.state.colMask[i]).map((col, j, arr) => {
                                                    return (<div style={{...styles.tableCell, textAlign:j===0?"left":(j === arr.length - 1 ?"right": "center") }} key={col.name}>
                                                        {col.formatter(this.state, this.props, v, this, i)}
                                                    </div>)
                                                })
                                            }

                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </Paper>
                </div>
            </div>
        )
    }

}

ReportTable.propTypes = {
    onBackCallback: PropTypes.func.isRequired,
    perspective: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => {

    return {
        practiceChanges: state.practiceChanges,
        enablingStrategies: state.enablingStrategies,
        projects: state.projects,
        indicators: state.indicators,
        enablingStrategyTemplates: state.enablingStrategyTemplates,
    };
}

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ReportTable);