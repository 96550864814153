import React from 'react';
import {PropTypes} from "prop-types";
import {connect} from 'react-redux';
import Popover from "@material-ui/core/Popover/Popover";
import Typography from "@material-ui/core/Typography/Typography";
import List from "@material-ui/core/List/List";
import Divider from "@material-ui/core/Divider/Divider";
import ListItem from "@material-ui/core/ListItem/ListItem";
import TextField from "@material-ui/core/TextField/TextField";
import {theme} from "../constants/colors"
import Collapse from "@material-ui/core/Collapse/Collapse";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Button from "@material-ui/core/Button/Button";
import Grid from "@material-ui/core/Grid/Grid";
import HelpPopper from "../HelpPopper";

const styles = {
    popoverBodyContainer: {
        minWidth: "50vw",
        padding: "10px",
        background: theme.palette.primary.main
    },
    popoverBodyContainerInner: {
        padding: "10px",
        background: "white"
    },
    listContainer: {
        maxHeight: "50vh",
        overflow: 'auto',
    },
    divider: {
        height: "1vh",
    },
    nested: {
        paddingLeft: "1vw"
    }
}



class SelectIndicatorPopover extends React.Component {

    constructor(props) {
        super(props);
        this.state = {popoverOpen:props.open ,open: [], checked: props.default_checked === null? [] :  props.default_checked.map(arr=>[...arr])};
        this.handleOpenClick = this.handleOpenClick.bind(this);
        this.isChecked = this.isChecked.bind(this);
        this.getSelectedString = this.getSelectedString.bind(this);
        this.handleConfirmClick = this.handleConfirmClick.bind(this);
    }

    //TODO: change this into readable code
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.default_checked !== null && nextProps.open && !prevState.popoverOpen){
            let nextState = {...prevState};
            nextState.checked = nextProps.default_checked;
            nextState.popoverOpen = true;
            return nextState;
        }
        return prevState;
    }

    isChecked(cat, ind) {
        if (this.state.checked.length < cat + 1) {
            return false;
        } else {
            return this.state.checked[cat].indexOf(ind) !== -1;
        }
    }

    handleCheckClick(cat, ind) {
        let new_checked = [...this.state.checked.map(sub => [...sub])];
        if (new_checked.length < cat + 1) {
            //add new empty lists for all currently unadded categories
            let new_subs = [];
            for (let i = 0; i < cat + 1 - new_checked.length; i++) {
                new_subs.push([]);
            }
            new_checked = new_checked.concat(new_subs)


            new_checked[cat].push(ind)
        } else {
            let index = new_checked[cat].indexOf(ind);
            if (index === -1) {
                new_checked[cat].push(ind);
            } else {
                new_checked[cat].splice(index, 1);
            }
        }
        this.setState({
            checked: new_checked
        })
    }

    getSelectedString(cat){
        if(this.state.checked.length < cat + 1){
            return "";
        }else{
            if(this.state.checked[cat].length == 0){
                return "";
            }
            let total = this.props.indicators.categories[cat].indicators.length;
            return "(" +this.state.checked[cat].length + " of " + total + ")";
        }
    }
    renderCategory(category, i) {

        return category.indicators.map((ind, j) => {
            return (<ListItem dense button key={"cat" + i + "sub" + j} onClick={_ => this.handleCheckClick(i, j)}>
                <Checkbox
                    style={{"padding": "0px"}}
                    color={"primary"} checked={this.isChecked(i, j)}/>
                <ListItemText>{(i + 1) + "." + (j + 1) + " " + ind.text}</ListItemText>
            </ListItem>)
        })
    }

    handleConfirmClick(){
        this.props.closeCallback(this.state.checked);
    }

    renderCategories(categories) {
        let new_nodes = categories.map((cat, i) => {
            return ([
                <Divider key={"divider" + i}/>,

                <ListItem
                    style={styles.nested}
                    onClick={_ => this.handleOpenClick(i)}
                    button
                    key={"item" + i}
                >

                    <ListItemText>
                        {(i + 1) + ". " + cat.text}
                        <Typography style={{"display":"inline", paddingLeft:"1em"}} variant={"caption"}>
                            {this.getSelectedString(i)}
                        </Typography>
                    </ListItemText>

                    {this.state.open.indexOf(i) > -1 ? <ExpandLess/> : <ExpandMore/>}
                </ListItem>,
                <Collapse key={"collapse" + i} in={this.state.open.indexOf(i) > -1} unmountOnExit>
                    <List key={i} disablePadding>
                        {this.renderCategory(cat, i)}
                    </List>
                </Collapse>])
        });
        new_nodes = new_nodes.reduce((acc, val) => acc.concat(val), []);
        return new_nodes;
    }

    renderPopoverBody() {
        let categories = this.props.indicators.categories.map(_ => ({}));
        for (let i = 0; i < this.props.indicators.categories.length; i++) {
            let current = this.props.indicators.categories[i];
            categories[current.index - 1].text = current.text;
            categories[current.index - 1].indicators = current.indicators;

        }
        return (<div style={styles.popoverBodyContainer}>
                <div style={styles.popoverBodyContainerInner}>
                    <Typography variant={"h4"}>
                        Select indicators
                    </Typography>

                    <Typography gutterBottom variant={"subtitle1"}>
                        Description of practice changes
                    </Typography>
                    <div style={styles.listContainer}>

                        <List>
                            <Divider/>
                            {this.renderCategories(categories)}
                        </List>
                    </div>
                </div>
                <div style={styles.divider}/>
                <Grid container justify={"flex-end"}>
                    <Grid item>
                        <Button onClick={this.handleConfirmClick} style={{background: "white"}} variant={"contained"}>
                            confirm
                        </Button>
                    </Grid>
                </Grid>
            </div>
        )
    }



    handleOpenClick(i) {
        let open = [...this.state.open];
        let index = open.indexOf(i);
        if (index == -1) {
            open.push(i);
        } else {
            open.splice(index, 1);
        }
        this.setState({
            open: open,
        })
    }

    render() {
        return (<Popover
            onClose={this.handleClose}
            open={this.props.open}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left"
            }}
            transformOrigin={{
                vertical: "center",
                horizontal: "right",
            }}

            anchorEl={this.props.target}>
            {this.renderPopoverBody()}
        </Popover>)
    }
}

SelectIndicatorPopover.propTypes = {
    default_checked:PropTypes.array,
    open: PropTypes.bool.isRequired,
    target: PropTypes.object,
    closeCallback: PropTypes.func.isRequired,
    indicators: PropTypes.object.isRequired,
};


const mapStateToProps = (state, ownProps) => {

    let current_project = state.projects[state.project.current_project];
    let indicators = state.indicators.filter(is => is.id === current_project.indicator_set_id)[0]

    return {
        indicators: indicators
    };
}

const mapDispatchToProps = dispatch => ({})

export default  connect(mapStateToProps, mapDispatchToProps)(SelectIndicatorPopover);