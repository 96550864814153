import React from 'react';
import {PropTypes} from "prop-types";
import {connect} from 'react-redux';
import ScreenManager from "./components/ScreenManager";
import LoginScreen from "./components/loginScreen/LoginScreen";
import Screen from "./components/Screen";
import ProjectsScreen from "./projectsScreen/ProjectsScreen";
import DashboardContainer from "./components/dashboard/DashboardContainer";
import CreatePracticeChange from "./components/createPracticeChange/CreatePracticeChange";
import Matrix from "./components/matrix/Matrix";
import ScoreScreen from "./components/modifyTransitionPhase/ScoreScreen";
import EnablingStrategiesScreen from "./components/enablingStrategies/EnablingStrategiesScreen";
import ReportingScreen from "./components/reporting/ReportingScreen";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import {hideMessage, setCurrentProject, setCurrentScreen} from "./actions/projectActions";

import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from "@material-ui/core/Typography/Typography";
import {deleteEnablingStrategy} from "./actions/serverActions";
import Grid from "@material-ui/core/Grid/Grid";
import Paper from "@material-ui/core/Paper/Paper";
import DashboardPracticeChanges from "./components/dashboard/DashboardPracticeChanges";
import Button from "@material-ui/core/Button/Button";
import EditMatrix from "./components/editMatrix/EditMatrix";
import ProjectContentScreen from "./projectsScreen/ProjectContentScreen";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link, withRouter
} from "react-router-dom";

const styles = {

    container: {
        background: "white",
        height:"100%"
    }
}
class Wrapper extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            newPracticeChange: false,
            showHelp:false,
            openProject:false,
        };
        this.handleClose = this.handleClose.bind(this)
    }

    handleClose(e, s) {
        if(s==="clickaway")return;
        this.props.hideMessage();
    }

    nextCallback = () => {
        if(this.props.current_practice_change === null && this.props.current_screen === 1){
            this.props.setCurrentScreen(6)
            return;
        }
        this.props.setCurrentScreen(Math.max(0, this.props.current_screen + 1));
    }

    jumpCallback = (index) => {
        this.props.setCurrentScreen(Math.max(0, index))
    }

    prevCallback = () => {
        if(this.props.current_screen === 5){
            this.props.setCurrentScreen(2);
            return;
        }else if(this.props.current_screen === 7){
            this.props.setCurrentScreen(1);
            return;
        }
        this.props.setCurrentScreen(Math.max(0, this.props.current_screen - 1));
    }

    renderPostEnablingStrategies = () => {
        return <Grid container>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{height:"100%"}}>
                        <Paper elevation={1} style={{height:"100%"}}>
                            <h1 style={{marginTop:"0px"}}>
                                Complete
                            </h1>
                            <p>
                                You've finished defining your practice change:

                                Continue to reporting:<br/>
                                <Button color={"primary"} variant={"contained"}
                                        onClick={_ => this.props.setCurrentScreen(this.props.current_screen + 1)}
                                >reporting</Button>

                                Return to Define Practice Change:
                                <br/>
                                <Button color={"primary"} variant={"contained"}
                                        onClick={_ => this.props.setCurrentScreen(0)}
                                >home</Button>

                            </p>
                        </Paper>
                    </Grid>
                </Grid>
    }

    renderPostTransition = () => {
        return <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{height:"100%"}}>
                <Paper elevation={1} style={{height:"100%"}}>
                    asdsdasads
                </Paper>
            </Grid>
        </Grid>

    }

    handlePracChangeCreated = () => {
        this.setState({
            newPracticeChange: false,
        })
    }

    handleNewPracChange = (proj_id) => {
        this.setState({newPracticeChange:true});
        this.props.setCurrentProject(this.props.projects.findIndex(p => p.id === proj_id))
        this.props.setCurrentScreen(1);
    };

    showHelp = (status) => {
        this.setState({showHelp:status})
    };


    openProject = (id) => {
        this.setState({openProject:true,selectedProject:id})
        this.props.setCurrentProject(id)
    };

    renderMainScreen = () => {
        {/*return [
            <Screen key={0} active={true} appBar={true} showHelp={this.showHelp} title={"Projects"}>
                <ProjectsScreen showHelp={this.state.showHelp} createPracticeChangeCallback={this.handleNewPracChange}/>
            </Screen>,
            <Screen key={1} active={false} appBar={false}>
                <CreatePracticeChange onCreateCallback={this.handlePracChangeCreated} editing={this.state.newPracticeChange}/>
            </Screen>,
            <Screen key={2} active={false} appBar={false}>
                <EditMatrix/>
            </Screen>,
            <Screen key={3} active={false} appBar={false}>
                <ScoreScreen/>
            </Screen>,
            <Screen key={4} active={false} appBar={false}>
                <EnablingStrategiesScreen onDeleteCallback={strategy_id => this.props.deleteEnablingStrategy(strategy_id)}/>
            </Screen>,
            <Screen key={5} active={false} appBar={false}>
                {
                    this.renderPostEnablingStrategies()
                }
            </Screen>,
            <Screen key={8} active={false} appBar={false}>
                <ReportingScreen/>
            </Screen>,
            <Screen key={9} active={false} appBar={false}>
                <ProjectContentScreen/>
            </Screen>
        ]*/}
        return(<Router><Switch>
            <Route exact path={"/project/:projectID"}>
                <ProjectContentScreen setCurrentProjectCallback={projectID=>{
                    this.setState({selectedProject:projectID})
                }}
                                      project_id={this.state.selectedProject}/>
            </Route>
            <Route exact path={"/"}>
                <ProjectsScreen createPracticeChangeCallback={this.handleNewPracChange}
                                openProject={this.openProject}/>
            </Route>
            <Route exact path={"/bad"} component={_ => <div>Router Test</div>}/>
        </Switch></Router>)

        {/*if (!this.state.openProject){
            return (
                <ProjectsScreen createPracticeChangeCallback={this.handleNewPracChange}
                                openProject={this.openProject}/>
            )
        }else{
            return(
                <ProjectContentScreen project_id={this.state.selectedProject}
                                      backHome={_=>this.setState({openProject: !this.state.openProject})}/>
            )
        }*/}
    };

    render() {
        return (
            <div>
                <Dialog open={this.props.progress_block !== 0
                     && (!this.props.user_state.user_generic_error && !this.props.user_state.user_authentication_error)}>
                    <DialogTitle disableTypography>
                        <Typography variant={"h5"} align={"center"}>
                            Please wait
                        </Typography>
                    </DialogTitle>
                    <DialogContent style={{overflow:"hidden", margin:"auto"}}>
                        <div style={{width:"35px", height:"55px",}}>
                            <CircularProgress style={{width:"50px", height:"50px", margin:"auto"}} size={50}/>
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog open={this.props.user_state.user_generic_error}>
                    <DialogTitle>
                        Error!
                    </DialogTitle>
                    <DialogContent>
                        {this.props.user_state.user_generic_error_string}
                    </DialogContent>
                </Dialog>
                <Dialog open={this.props.user_state.user_authentication_error}>
                    <DialogTitle>
                       Authentication Error!
                    </DialogTitle>
                    <DialogContent>
                        {this.props.user_state.user_authentication_error_string}
                    </DialogContent>
                </Dialog>

 
                {!this.props.user_state.user_authenticated ?
                    <Screen appBar={false} active={true} innerStyle={{background:"white",}}>
                        <LoginScreen/>
                    </Screen>
                    : this.renderMainScreen()
                }
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={this.props.open}
                    autoHideDuration={3000}
                    onClose={this.handleClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">project saved</span>}

                />
            </div>
        )
    }
}

Wrapper.propTypes = {};

const mapStateToProps = (state, ownProps) => {

    return {
        user_state: state.user,
        project: state.project,
        projects: state.projects,
        open: state.project.show_message,
        current_screen: state.project.current_screen,
        current_practice_change: state.project.current_practice_change,
        progress_block: state.project.progress_block,
    };
}

const mapDispatchToProps = dispatch => ({
    hideMessage: () => {
        dispatch(hideMessage());
    },
    deleteEnablingStrategy: (id) => {
        dispatch(deleteEnablingStrategy(id))
    },
    setCurrentScreen: (n) => {
        dispatch(setCurrentScreen(n))
    },
    setCurrentProject: (index) => {
        dispatch(setCurrentProject(index))
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(Wrapper);