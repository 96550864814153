import React from 'react';
import {PropTypes} from "prop-types";
import {connect} from 'react-redux';
import Paper from "@material-ui/core/Paper/Paper";
import MatrixCellPriority from "./MatrixCellPriority"
import {styles} from "./MatrixCell"
import {buildMatrix} from "../../reducers/practiceChanges";



class MatrixCellClickable extends React.Component {

    constructor(props) {
        super(props);
    }



    render() {
        const highlight = this.props.highlight;

        return (
            <Paper onClick={this.props.onClick} style={{boxSizing: "border-box", cursor:"pointer",border:"solid " + highlight.size + " " + highlight.color, ...this.props.cellStyle,...styles.innerWrappers}}>
                <div style={{display:"flex", alignItems:"center", justifyContent:"center", height:"100%"}}>
                    {this.props.cell.text}
                </div>
        </Paper>)
    }
}

MatrixCellClickable.propTypes = {
    cell:PropTypes.object,
    priority: PropTypes.number,
    highlight: PropTypes.object,
    onClick: PropTypes.func,
    cellStyle: PropTypes.func,
    matrixId: PropTypes.number.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    let matrix;
    if(ownProps.matrixId !== undefined && ownProps.matrixId !== null){
        matrix = state.practiceChanges.filter(v => v.id === ownProps.matrixId)[0].matrixData;
    }else{
        matrix = buildMatrix()
    }
    let entry = matrix.entries[ownProps.row][ownProps.col];
    return {
        label: state.matrixData.rows[ownProps.row].elements[ownProps.col],
        priority: entry.priority,
        justification: entry.justification
    }

}

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(MatrixCellClickable);