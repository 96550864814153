import React from 'react';
import {PropTypes} from "prop-types"
import Paper from "@material-ui/core/Paper"
import {theme} from "./constants/colors"
import Button from "@material-ui/core/Button"
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import Typography from "@material-ui/core/Typography/Typography";
import InfoIcon from '@material-ui/icons/Info';
import IconButton from "@material-ui/core/IconButton/IconButton";
import Fab from "@material-ui/core/Fab/Fab";
import {logoutUser} from "../actions/userActions";
import connect from "react-redux/es/connect/connect";
import EditIcon from "@material-ui/core/SvgIcon/SvgIcon";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AppsIcon from '@material-ui/icons/Apps';
import Slide from "@material-ui/core/Slide";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";

const styles = {
    container: {
        width:"100%",
        height: "100%",
        position:"absolute",
        display:"flex",
        transition:"transform 400ms ease",
    },
    inner: {
        width:"100%",
        //backgroundColor:theme.palette.primary.main,
        //margin:"auto",
        //marginTop:"15vh",
        //marginBottom:"50px",
        display:"flex",
        //padding:"1%"
    },
    HelpPaperProps:{
        style:{
            width:"600px"
        }
    }
}
class Screen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showHelp:false,
            viewDetails:false,
            screenWidth: window.innerWidth,
        }

        this.updateScreenWidth = this.updateScreenWidth.bind(this);
    }

    componentDidMount() {
        this.updateScreenWidth();
        window.addEventListener('resize', this.updateScreenWidth);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateScreenWidth);
    }

    updateScreenWidth() {
        this.setState({ screenWidth: window.innerWidth, });
    }

    renderHelp = () => {
        return <div>
            <div style={{height:"64px",padding:"0px 10px", display:"flex", alignItems:"center"}}>
                <Typography variant={"h4"}>
                    Introduction
                </Typography>
            </div>
            <Divider/>
            <div style={{padding:"10px",}}>
            <p>
                The CRCWSC has developed the Transition Dynamics Framework, which identifies six distinct
                phases of change in any transition to new practice. This tool translates the research framework into a
                web application for users develop enabling strategies for progressing practice change in your urban
                context.
            </p>
            <p>
                As a city moves through each phase sequentially, enabling conditions are established to support its
                trajectory towards its practice change and avoid the risk of change pathways that reflect lock-in,
                backlash or system failure patterns.
            </p>
            <p>
                Actions to orient and drive change towards a desired practice change need to progressively establish
                these enabling conditions. Actions with the most impact during the early phases of transition will be
                different from those during the later phases. <b>It is critical to identify a city’s current phase of
                change to ensure that actions are prioritised according to the effectiveness they will have in
                accelerating the transition.</b>
            </p>
            <p>
                The CRCWSC’s Transition Dynamics Framework sets out five types of enabling factors that need to
                be present throughout a transition: champions, platforms for connecting, knowledge, projects and
                applications, and practical and administrative tools and instruments. <b>Together, these five factors
                create an enabling environment for a transition and, mapped against the six transition phases,
                they create a matrix (Figure 1) for a deeper understanding of the current transition phase.</b>
            </p>
            {/*<img     style={{margin:"auto", width:"70%", display:"block"}} src={tableimg}/>*/}
            <p>
                The Framework provides a checklist of the factors that should be deliberately and sequentially built up
                to inform the prioritisation of strategies and actions. <b>It can be used for any change in practice, and
                determines the enabling strategies needed to progress the practice change.</b> Once the enabling
                strategies are identified, further work will need to be done to determine priority actions according to
                local context and opportunities.
            </p>
            <h2 style={{marginBottom:"0px"}}>Further Reading</h2>
                <Divider/>
            <p>
                Brown, R.R., Rogers, B.C., &amp; Werbeloff, L. (2016). <a href="https://watersensitivecities.org.au/wp-content/uploads/2016/05/TMR_A4-1_MovingTowardWSC.pdf">Moving toward Water Sensitive Cities: A guidance
                manual for strategists and policy makers.</a> Melbourne, Australia: Cooperative Research Centre for
                Water Sensitive Cities.
            </p>
            <p>
                Brown, R.R., Rogers, B.C., &amp; Werbeloff, L. (2017). A framework to guide transitions to water sensitive
                cities. Chapter 9 in Moore, T., de Haan, F.J., Horne, R. &amp; Gleeson, B. (Eds) Urban Sustainability
                Transitions: Australian Cases – International Perspectives. Springer, Japan.
            </p>
            <h2 style={{marginBottom:"0px"}}>Glossary</h2>
                <Divider/>
            <p>
                <b>Issue: </b>when a traditional way of doing something is causing environmental, social, technical or other
                problems now and/or in the future.
            </p>
            <p>
                <b>Practice Change: </b>what needs to change in practice to address the issue and deliver positive, more
                sustainable outcomes
            </p>
            <p>
                <b>Enabling Factor: </b>an indicator to support a more comprehensive diagnosis of transition status and
                opportunities, organised into five types: champions, platforms for connecting, knowledge, projects and
                demonstrations, and tools and instruments
            </p>
            <p>
                <b>Enabling Strategy: </b>recommendation for action to advance the transition by addressing specific
                enabling factors and ensuring they don’t digress
            </p>
            <p>
                <b>Transition: </b>a fundamental shift in cultures, structures and practices as society changes from one
                pattern of socio-technological development to another usually more sustainable pattern
            </p>
        </div>
        </div>
    }

    render() {
        return(
            <div style={{...styles.container, ...this.props.style,}}>
                {this.props.appBar ?
                    <AppBar style={{position:"fixed",zIndex:1201}}>
                        <Toolbar>
                            <div style={{position:"fixed",left:this.props.nav ?(this.state.screenWidth>=960 ?"330px":"200px"):"5%"}}>
                                <Typography variant={"overline"} style={{color:"white"}}>
                                    {this.props.header}
                                </Typography>
                                <Typography variant={"h5"} style={{color:"white"}}>
                                    {this.props.title}
                                </Typography>
                            </div>
                            <div style={{position:"fixed", right:this.props.nav ?"150px":"110px"}}>
                                <IconButton style={{color:"white"}} onClick={_=>this.setState({showHelp:!this.state.showHelp})}><InfoIcon fontSize={"medium"}/></IconButton>
                            </div>
                            {this.props.nav ?
                                    <IconButton style={{color:"white", position:"fixed", right:"110px"}} variant={"outlined"} onClick={_=>this.props.history.push('/')}>
                                        <AppsIcon/>
                                    </IconButton> :null}
                            {this.props.nav ?
                                <div style={{position:"fixed",left:this.state.screenWidth>=960 ?"10px":"60px", marginTop:"5px"}}>
                                    <Typography variant={"subtitle"} style={{color:"#829AB1"}}>Project</Typography>
                                    <Typography variant={"title"} style={{color:"white"}}>{this.props.current_project.name}</Typography>
                                </div>:null}
                                <div style={{position:"fixed", right:"10px",}}>
                                    <Button style={{color:"white"}} variant={"outlined"} onClick={_=>this.props.logOut()}>Log Out</Button>
                                </div>
                        </Toolbar>
                    </AppBar>:null}
                {/*this.props.useCard ?
                <Paper elevation={20} style={{...styles.inner, ...this.props.innerStyle}}>
                    {Math.abs(this.props.index - this.props.currentScreen) <= 1 ?  React.Children.toArray(this.props.children): ""}
                </Paper>
                    :
                    <div style={{...styles.inner, ...this.props.innerStyle}}>
                        {Math.abs(this.props.index - this.props.currentScreen) <= 1 ?  React.Children.toArray(this.props.children): ""}
                    </div>
                */}
                <Drawer open={this.state.showHelp} anchor={"right"}
                        PaperProps={styles.HelpPaperProps}
                        onClose={_=>this.setState({showHelp:false})}>

                        {this.renderHelp()}
                </Drawer>
                {this.props.children}
            </div>
        )
    }
}

Screen.propTypes = {
    active: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    currentScreen: PropTypes.number.isRequired,
    style: PropTypes.object,
    innerStyle: PropTypes.object,
    useCard: PropTypes.bool,
}

const mapStateToProps = (state, ownProps) => {
    return{
        projects: state.projects,
    }
}

const mapDispatchToProps = dispatch => ({
    logOut: () =>{
        dispatch(logoutUser())
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(Screen);