import {getProject} from "./serverActions";

export const setCurrentPracticeChange = (id) => ({
    type: "PROJ_SET_CURRENT_PRACTICE_CHANGE",
    id
})

export const setCurrentScreen = (index) => ({
    type: "PROJ_SET_CURRENT_SCREEN",
    index
});


export const setProjectData = (project_data) => ({
    type: "PROJ_INIT_PROJECT_DATA",
    project_data
});


export const setASTimestamp= (ts) => ({
    type: "PROJ_SET_TIMESTAMP",
    ts
})

export const setCurrentProject = (index) => ({
    type: "PROJ_SET_CURRENT_PROJECT",
    index
})


export const cueAutoSave = (state) => ({
    type: "PROJ_CUE_AUTO_SAVE",
    state,
});


export const hideMessage = () => ({
    type: "PROJ_HIDE_MESSAGE",
});

export const showMessage = () => ({
    type: "PROJ_SHOW_MESSAGE",
});

export const projIncrBlock = () => ({
    type: "PROJ_PROGRESS_BLOCK_INC",
});
export const projDecrBlock = () => ({
    type: "PROJ_PROGRESS_BLOCK_DECR",
});