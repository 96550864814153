


export const theme = {
    palette: {
        primary: {
            main:'#00616F',
            mainRGBstring: a=>"rgba(0,103,114,"+a+")",
        },
        secondary: {
            main: '#3E5799',
        },
        highlight:{
            main:"#a2ceef"
        }
    },
    typography: {
        fontFamily:[
            'New Rail Alphabet',
            'Arial'
        ].join(',')
    }
}

