


export const setUserData = (user_email, user_token) => ({
    type: "USER_SET_USERDATA",
    user_email,
    user_token,
});

export const setUserAuthError = () => ({
    type: "USER_AUTHENTICATION_ERROR",
});



export const setUserAuthErrorString = (string) => ({
    type: "USER_AUTHENTICATION_ERROR_STRING",
    string,
});




export const setUserGenericError = () => ({
    type: "USER_SET_GENERIC_ERROR",
});



export const setUserGenericErrorString = (string) => ({
    type: "USER_SET_GENERIC_ERROR_STRING",
    string,
});

export const logoutUser = () => {
    return (dispatch, getState) => {
        dispatch(deAuthUser());
        window.location.reload();
    }
}

export const deAuthUser = () => {
    return (dispatch, getState) => {

        localStorage.removeItem("email");
        localStorage.removeItem("token");
        localStorage.removeItem("auth");
        dispatch({
            type: "USER_DE_AUTH",
        });
    }
}