import fetch from "cross-fetch"
import {setIndicatorData} from "./indicatorActions";
import {
    cueAutoSave,
    projDecrBlock,
    projIncrBlock,
    setCurrentProject,
    setCurrentScreen,
    setProjectData,
    showMessage
} from "./projectActions";
import {setTemplates} from "./issueTemplateActions";
import {addEnablingStrategy, removeEnablingStrategy, setEnablingStrategies} from "./enablingStrategyActions";
import {createPracticeChange, setPracticeChange, setPracticeChanges} from "./practiceChangeActions";
import {createNewProject, setProjects, removeProject} from "./projectsActions";
import {setEnablingStrategyTemplates} from "./enablingStrategyTemplateActions";
import {
    deAuthUser,
    setUserAuthError,
    setUserAuthErrorString,
    setUserGenericError,
    setUserGenericErrorString
} from "./userActions";
import {newReport, setReports} from "./reportsActions";
const URL = "https://main-tdf.enablingtransitions.org/";
// const URL = "http://localhost:5000/";

const timestamp = _ => {
    return Math.round((new Date()).getTime() / 1000);
}

export function saveMixin(fn){
    return (...args) => {
        return (dispatch, getState) => {
            Promise.resolve(dispatch(fn(...args))).then(v => {
                return dispatch(cueAutoSave(true));
            });

        }
    }
}
export function submitPracticeChange(project_id, pc_data){
    return (dispatch, getState) =>{
        dispatch(projIncrBlock());

        return fetch(URL + "project/" + project_id + "/createPracticeChange", {
            method: "post",
            headers: {
                "Authorization": getState().user.user_token,
                "useremail": getState().user.user_email,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(pc_data),
        }).then(response => {
            if (response.status !== 200) {
                dispatch(projDecrBlock());
                let error = new Error(response.statusText);
                error.code = response.status;
                throw error;
            }
            return response.json()
        }).then(json => {
            dispatch(createPracticeChange(project_id,
                pc_data.issue_name,
                pc_data.issue_description,
                pc_data.practice_change_name,
                pc_data.practice_change_description,
                pc_data.indicators,
                json["id"]))
            dispatch(setPracticeChange(json["id"]));
            dispatch(setCurrentScreen(2));
            dispatch(projDecrBlock());
        }).catch(p => {
            if(p.code === 401){
                dispatch(deAuthUser());
                dispatch(setUserAuthError());
                dispatch(setUserAuthErrorString("Authentication error: could not submit practice change. Please log-in to try again"));
            }else{
                dispatch(setUserGenericError());
                dispatch(setUserGenericErrorString("error occurred while trying to submit practice change. Please refresh page."));
            }
        });
    }
}

export function submitNewProject(name, indicator_id){
    let data = {};
    data["name"] = name;
    data["indicator_set_id"] = indicator_id;
    return (dispatch, getState) =>{
        dispatch(projIncrBlock());

        return fetch(URL + "project", {
            method: "post",
            headers: {
                "Authorization": getState().user.user_token,
                "useremail": getState().user.user_email,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        }).then(response => {
            if (response.status !== 200) {
                dispatch(projDecrBlock());
                let error = new Error(response.statusText);
                error.code = response.status;
                throw error;
            }
            return response.json()
        }).then(json => {
            dispatch(createNewProject(json["id"], name, indicator_id));
            dispatch(projDecrBlock());
        }).catch(p => {
            if(p.code === 401){
                dispatch(deAuthUser());
                dispatch(setUserAuthError());
                dispatch(setUserAuthErrorString("Authentication error: could not create new project. Please log-in to try again"));
            }else{
                dispatch(setUserGenericError());
                dispatch(setUserGenericErrorString("error occurred while trying to create project. Please refresh page."));
            }
        });
    }
}
export function submitEnablingStrategy(project_id, prac_change_id){
    return (dispatch, getState) =>{
        dispatch(projIncrBlock());
        return fetch(URL + "project/" +  project_id + "/practiceChange/" + prac_change_id + "/createEnablingStrategy", {
            method: "post",
            headers: {
                "Authorization": getState().user.user_token,
                "useremail": getState().user.user_email,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({}),
        }).then(response => {
            if (response.status !== 200) {
                dispatch(projDecrBlock());
                let error = new Error(response.statusText);
                error.code = response.status;
                throw error;
            }
            return response.json()
        }).then(json => {
            dispatch(addEnablingStrategy(prac_change_id, json["id"]))
            dispatch(projDecrBlock());

        }).catch(p => {
            if(p.code === 401){
                dispatch(deAuthUser());
                dispatch(setUserAuthError());
                dispatch(setUserAuthErrorString("Authentication error: could not submit enabling strategies. Please log-in to try again"));
            }else{
                dispatch(setUserGenericError());
                dispatch(setUserGenericErrorString("error occurred while trying to submit enabling strategy. Please refresh page."));
            }
        });
    }
}

export const deleteProject = (id) => {

    return (dispatch, getState) => {
        dispatch(projIncrBlock());
        return fetch(URL + "project/" +  id, {
            method: "delete",
            headers: {
                "Authorization": getState().user.user_token,
                "useremail": getState().user.user_email,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: "",
        }).then(response => {
            if (response.status !== 200) {
                dispatch(projDecrBlock());
                let error = new Error(response.statusText);
                error.code = response.status;
                throw error;
            }
            return response.json()
        }).then(json => {
            dispatch(removeProject(id));
            dispatch(projDecrBlock());

        }).catch(p => {
            if(p.code === 401){
                dispatch(deAuthUser());
                dispatch(setUserAuthError());
                dispatch(setUserAuthErrorString("Authentication error: could not delete enabling strategy. Please log-in to try again"));
            }else{
                dispatch(setUserGenericError());
                dispatch(setUserGenericErrorString("error occurred while trying to delete enabling strategy. Please refresh page."));
            }
        });


    }
}


export const deleteEnablingStrategy = (id) => {

    return (dispatch, getState) => {
        dispatch(projIncrBlock());
        return fetch(URL + "enabling_strategies/" +  id, {
            method: "delete",
            headers: {
                "Authorization": getState().user.user_token,
                "useremail": getState().user.user_email,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: "",
        }).then(response => {
            if (response.status !== 200) {
                dispatch(projDecrBlock());
                let error = new Error(response.statusText);
                error.code = response.status;
                throw error;
            }
            return response.json()
        }).then(json => {
            dispatch(removeEnablingStrategy(id));
            dispatch(projDecrBlock());

        }).catch(p => {
            if(p.code === 401){
                dispatch(deAuthUser());
                dispatch(setUserAuthError());
                dispatch(setUserAuthErrorString("Authentication error: could not delete enabling strategy. Please log-in to try again"));
            }else{
                dispatch(setUserGenericError());
                dispatch(setUserGenericErrorString("error occurred while trying to delete enabling strategy. Please refresh page."));
            }
        });


    }
}


export function saveProject(project_id){
    return (dispatch, getState) =>
    {
        let data = {};
        let project = getState().projects.find(p => p.id === project_id);
        data["project_data"] = {
            lead_fac_analyst: project.lead_fac_analyst,
            name: project.name,
            whos_involved: project.whos_involved,
            city: project.city,
            time_period: project.time_period,
        }
        data["practice_changes"] = getState().practiceChanges.map((v, i) => {

            return {...v, enabling_strategies: []}
        });

        for(let i = 0; i < getState().enablingStrategies.length; i++){
                let current = getState().enablingStrategies[i];

                let pc = data["practice_changes"].filter(pc => {
                    return pc.id === current.practice_change_id;
                })[0];
                pc["enabling_strategies"].push(current);
        }

        return fetch(URL + "project/" + project_id,{
            method: "post",
            headers: {
                "Authorization": getState().user.user_token,
                "useremail": getState().user.user_email,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        }).then(response =>{
            if (response.status !== 200) {
                let error = new Error(response.statusText);
                error.code = response.status;
                throw error;
            }
            dispatch(showMessage());
        }).catch(p => {
            if(p.code === 401){
                dispatch(deAuthUser());
                dispatch(setUserAuthError());
                dispatch(setUserAuthErrorString("Authentication error: could not save project. Please log-in to try again"));
            }else{
                dispatch(setUserGenericError());
                dispatch(setUserGenericErrorString("error occurred while trying to save project. Please refresh page."));
            }
        });
    }
}


export function saveReports(reports){

    return (dispatch, getState) =>
    {

        return fetch(URL + "reports",{
            method: "put",
            headers: {
                "Authorization": getState().user.user_token,
                "useremail": getState().user.user_email,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(reports),
        }).then(response =>{
            if (response.status !== 200) {
                let error = new Error(response.statusText);
                error.code = response.status;
                throw error;
            }
            dispatch(showMessage());
        }).catch(p => {
            if(p.code === 401){
                dispatch(deAuthUser());
                dispatch(setUserAuthError());
                dispatch(setUserAuthErrorString("Authentication error: could not save project. Please log-in to try again"));
            }else{
                dispatch(setUserGenericError());
                dispatch(setUserGenericErrorString("error occurred while trying to save project. Please refresh page."));
            }
        });
    }
}

export function getProject(project_id) {

    return (dispatch, getState) => {

        return fetch(URL + "project/" + project_id, {
            method: "get",
            headers: {
                "Authorization": getState().user.user_token,
                "useremail": getState().user.user_email
            }
        })
            .then(response => {
                if (response.status !== 200) {
                    dispatch(projDecrBlock());
                    let error = new Error(response.statusText);
                    error.code = response.status;
                    throw error;
                }
                return response.json()
            })
            .then(json => {
                /**
                 * set all project data here
                 */
                dispatch(setIndicatorData(json.indicators))
                dispatch(setTemplates(json.templates))
                dispatch(setEnablingStrategies(json.enabling_strategies))
                dispatch(setPracticeChanges(json.practice_changes));
                const project_data = {};
                project_data.name = json.name;

                project_data.id = json.project_id;
                return dispatch(setProjectData(project_data));

            }).then(v => {
            }).catch(p => {
                if(p.code === 401){
                    dispatch(deAuthUser());
                    dispatch(setUserAuthError());
                    dispatch(setUserAuthErrorString("Authentication error: could not access project data. Please log-in to try again"));
                }else{
                    dispatch(setUserGenericError());
                    dispatch(setUserGenericErrorString("error occurred while trying to retrieve project data. Please refresh page."));
                }
            });

    }
}

export function getIndicatorSets() {
    return (dispatch, getState) => {
        return fetch(URL + "indicators", {
            method: "get",
            headers: {
                "Authorization": getState().user.user_token,
                "useremail": getState().user.user_email
            }
        })
            .then(response => {
                if (response.status !== 200) {
                    dispatch(projDecrBlock());
                    let error = new Error(response.statusText);
                    error.code = response.status;
                    throw error;
                }
                return response.json()
            })
            .then(json => {
                let issue_templates_list = [];
                for(let i = 0; i < json.length; i++){
                    issue_templates_list = issue_templates_list.concat(json[i]["issue_templates"])
                }

                dispatch(setIndicatorData(json));
                dispatch(setTemplates(issue_templates_list));
            }).catch(p => {
                if(p.code === 401){
                    dispatch(deAuthUser());
                    dispatch(setUserAuthError());
                    dispatch(setUserAuthErrorString("Authentication error: could not access indicators. Please log-in to try again"));
                }else{
                    dispatch(setUserGenericError());
                    dispatch(setUserGenericErrorString("error occurred while trying to retrieve indicators. Please refresh page."));
                }
            });

    }
}

export function getEnablingStrategyTemplates() {
    return (dispatch, getState) => {
        return fetch(URL + "enabling_strategies", {
            method: "get",
            headers: {
                "Authorization": getState().user.user_token,
                "useremail": getState().user.user_email
            }
        })
            .then(response => {
                if (response.status !== 200) {
                    dispatch(projDecrBlock());
                    let error = new Error(response.statusText);
                    error.code = response.status;
                    throw error;
                }
                return response.json()
            })
            .then(json => {
                dispatch(setEnablingStrategyTemplates(json));

            }).catch(p => {
                if(p.code === 401){
                    dispatch(deAuthUser());
                    dispatch(setUserAuthError());
                    dispatch(setUserAuthErrorString("Authentication error: could not access enabling strategies. Please log-in to try again"));
                }else{
                    dispatch(setUserGenericError());
                    dispatch(setUserGenericErrorString("error occurred while trying to retrieve enabling strategies. Please refresh page."));
                }
            });

    }
}
export function getReports() {
    return (dispatch, getState) => {
        return fetch(URL + "reports", {
            method: "get",
            headers: {
                "Authorization": getState().user.user_token,
                "useremail": getState().user.user_email
            }
        })
            .then(response => {
                if (response.status !== 200) {
                    dispatch(projDecrBlock());
                    let error = new Error(response.statusText);
                    error.code = response.status;
                    throw error;
                }
                return response.json()
            })
            .then(json => {
                dispatch(setReports(json));

            }).catch(p => {

                if(p.code === 401){
                    dispatch(deAuthUser());
                    dispatch(setUserAuthError());
                    dispatch(setUserAuthErrorString("Authentication error: could not access reports. Please log-in to try again"));
                }else{
                    dispatch(setUserGenericError());
                    dispatch(setUserGenericErrorString("error occurred while trying to retrieve reports. Please refresh page."));
                }
            });

    }
}


export function getProjects(project_id) {

    return (dispatch, getState) => {
        dispatch(projIncrBlock());

        return fetch(URL + "projects", {
            method: "get",
            headers: {
                "Authorization": getState().user.user_token,
                "useremail": getState().user.user_email
            }
        })
            .then(response => {
                if (response.status !== 200) {
                    dispatch(projDecrBlock());
                    let error = new Error(response.statusText);
                    error.code = response.status;
                    throw error;
                }
                return response.json()
            })
            .then(json => {
                let practice_change_list = [];
                let project_list = [];
                let enabling_strategies_list = [];
                let issue_templates_list = [];
                for (let i = 0; i < json.length; i++) {
                    let current_obj = json[i];
                    project_list.push({
                        id: current_obj.id,
                        name: current_obj.name,
                        indicator_set_id:current_obj.indicator_set_id,
                        whos_involved: current_obj.whos_involved,
                        time_period: current_obj.time_period,
                        lead_fac_analyst: current_obj.lead_fac_analyst,
                        city: current_obj.city,
                    });

                    for (let j = 0; j < current_obj.practice_changes.length; j++) {
                        let current_pc = current_obj.practice_changes[j];
                        practice_change_list.push(current_pc);
                    }
                    for (let j = 0; j < current_obj.enabling_strategies.length; j++) {
                        let current_pc = current_obj.enabling_strategies[j];
                        enabling_strategies_list.push(current_pc);
                    }

                }

                dispatch(setProjects(project_list));
                dispatch(setPracticeChanges(practice_change_list));
                dispatch(setEnablingStrategies(enabling_strategies_list));
                dispatch(projDecrBlock());
                return [project_list,practice_change_list,enabling_strategies_list]

            }).catch(p => {
                if(p.code === 401){
                    dispatch(deAuthUser());
                    dispatch(setUserAuthError());
                    dispatch(setUserAuthErrorString("Authentication error: could not access project list. Please log-in to try again"));
                }else{
                    dispatch(setUserGenericError());
                    dispatch(setUserGenericErrorString("error occurred while trying to retrieve projects. Please refresh page."));
                }
            });

    }
}


export const createReport = (data) => {
    return (dispatch, getState) => {

        dispatch(projIncrBlock());
        return fetch(URL + "report" , {
            method: "post",
            headers: {
                "Authorization": getState().user.user_token,
                "useremail": getState().user.user_email
            },
            body: JSON.stringify(data),

        })
            .then(response => {
                if (response.status !== 200) {
                    dispatch(projDecrBlock());
                    let error = new Error(response.statusText);
                    error.code = response.status;
                    throw error;
                }
                return response.json()
            })
            .then(json => {
                /**
                 * set all project data here
                 */

                dispatch(projDecrBlock());
                return dispatch(newReport({
                    id: json.id,
                    data

                }));

            }).then(v => {
            }).catch(p => {
                if(p.code === 401){
                    dispatch(deAuthUser());
                    dispatch(setUserAuthError());
                    dispatch(setUserAuthErrorString("Authentication error: could not access project data. Please log-in to try again"));
                }else{
                    dispatch(setUserGenericError());
                    dispatch(setUserGenericErrorString("error occurred while trying to add report. Please refresh page."));
                }
            });
    }
}