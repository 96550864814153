import React from 'react';
import {PropTypes} from "prop-types";
import {connect} from 'react-redux';
import Grid from "@material-ui/core/Grid/Grid";
import Matrix from "../matrix/Matrix";
import Paper from "@material-ui/core/Paper/Paper";
import Forward from "@material-ui/icons/Forward"
import Typography from "@material-ui/core/Typography/Typography";
import TextField from "@material-ui/core/TextField/TextField";
import {theme} from "../constants/colors"
import {setOverrideScore} from "../../actions/matrixInstanceActions";
import Button from "@material-ui/core/Button/Button";

const styles = {
    scorePanelContainer: {
        background: "white",
        height:"100%",
        overflow:"hidden",
        display:"flex",
        flexDirection:"column",
        padding: "1%",
        boxSizing: "border-box"
    },
    divider:n=>({
        height:n+"%"
    }),
};


function isFloat(val) {
    let floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
    if (!floatRegex.test(val))
        return false;

    val = parseFloat(val);
    if (isNaN(val))
        return false;
    return true;
}

class ScoreScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            string: "0",
        }
        this.handleScoreChange = this.handleScoreChange.bind(this);
    }


    handleScoreChange(n){
        let val = n.target.value;
        if(val.length === 0){

            this.setState({
                string: "",
            })
            this.props.setOverrideScore(this.props.currentPracChange, 0);
        }
        let good = /^(?!\.)[0-6]((\.[05]?(?![^05]))|(?!\.))$/g.test(val);

        if(good && parseFloat(val) <= 6){
            this.setState({
                string: val,
            })
            this.props.setOverrideScore(this.props.currentPracChange, parseFloat(val));
        }
    }

    render() {
        return (
            <div style={{height:"100%", width:"100%", display:"flex", flexDirection:"column"}}>

                <div style={{flex: 1, display:"flex", flexDirection:"row"}}>
                    <Grid style={{height: "100%"}} container item xl={8} lg={8} md={8} sm={8} xs={8}>
                        <Matrix matrixId={this.props.currentPracChange} plainRender/>
                    </Grid>
                    <div style={{width:"20px"}}/>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                        <Paper style={styles.scorePanelContainer} elevation={4}>
                            <div>
                                <Typography variant={"h4"}>
                                    Modify Transition Phase
                                </Typography>
                            </div>
                            <p>
                                A suggested transition phase is
                                provided based on the Transition
                                Dynamics Framework matrix
                                assessment to the left. Review to either
                                confirm or change the transition phase
                                and provide justification if it has
                                changed. <span style={{color:"#3d3c3c"}}>(between 0 and 6, in increments of 0.5)</span>
                            </p>
                            <div style={styles.divider(2)}/>
                            <div>
                                <Grid spacing={8} justify={"center"} container>
                                    <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>
                                        <Typography align="right" variant={"h3"} color={"primary"}>
                                            {this.props.score.toFixed(1)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                                        <Typography align="center" variant={"h3"} color={"primary"}>
                                            <Forward style={{fontSize:"1.17em"}}/>
                                        </Typography>
                                    </Grid>
                                    <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>
                                        <input  style={{color:theme.palette.primary.main, width:"40%",border:"none",borderBottom:"solid 2px grey", padding:"0", fontSize:"2.8em"}} onChange={this.handleScoreChange} value={this.state.string} type={"text"}/>
                                    </Grid>
                                </Grid>
                            </div>
                            <div style={styles.divider(5)}/>
                            <div>
                                <TextField variant={"outlined"} rows={3} multiline label={"Justification"} style={{width:"100%"}}>

                                </TextField>
                            </div>
                            <div style={{overflowY:"auto"}}>
                                <h3>
                                Guidance on determining the transition
                                phase below:
                                </h3>
                                <ol>
                                    <li>
                                        Working from top to bottom of the matrix,
                                        identify the row where the first yellow cell
                                        occurs
                                    </li>
                                    <li>
                                        If there are three or more yellow cells in
                                        that row with the rest being green, the
                                        transition phase is that row number
                                    </li>
                                    <li>
                                        If there are two or less yellow cells in that
                                        row with the rest being green, the transition
                                        phase is that row number plus 0.5
                                    </li>
                                </ol>
                            </div>
                        </Paper>
                    </Grid>
                    </div>
                </div>
            );
    }
}

ScoreScreen.propTypes = {};

const mapStateToProps = (state, ownProps) => {
    return {
        currentPracChange: state.project.current_practice_change,
        score:state.matrixInstance.suggestedScore,
        overrideScore:state.matrixInstance.overrideScore,
    };
}

const mapDispatchToProps = dispatch => ({
    setOverrideScore: (id, n) => {
        dispatch(setOverrideScore(id, n));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ScoreScreen);