import React from 'react';
import {PropTypes} from "prop-types";
import {connect} from 'react-redux';
import Grid from "@material-ui/core/Grid/Grid";
import Matrix from "../matrix/Matrix";
import Paper from "@material-ui/core/Paper/Paper";

import ListItem from "@material-ui/core/ListItem/ListItem";
import List from "@material-ui/core/List/List";
import {enablingStrategies} from "../../reducers/enablingStrategies";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import {
    addCellToStrategy, addEnablingStrategy, setStrategyText, setStratTemplate,
    toggleStrategyCell
} from "../../actions/enablingStrategyActions";
import Typography from "@material-ui/core/Typography/Typography";
import Divider from "@material-ui/core/Divider/Divider";
import Edit from "@material-ui/icons/Edit"
import Popover from "@material-ui/core/Popover/Popover";
import Button from "@material-ui/core/Button/Button";
import TextField from "@material-ui/core/TextField/TextField";
import {submitEnablingStrategy} from "../../actions/serverActions";
import HelpPopper from "../HelpPopper";
import {setCurrentScreen} from "../../actions/projectActions";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from "@material-ui/core/IconButton";
import BuildIcon from '@material-ui/icons/Build';

const styles = {
    scorePanelContainer: {
        background: "white",
        display:"flex",
        flex:"1 1 auto",
        flexDirection:"column",
        height: "100%",
        overflowY:"auto",
        padding: "1%",
        boxSizing: "border-box",
    },
    divider: n => ({
        height: n + "%"
    }),
}




class EnablingStrategiesScreen extends React.Component {


    constructor(props) {
        super(props);
        this.state = {currentStrategy: -1, editStrategy: null, selectedTemplate:null};
        this.handleNewStratClick = this.handleNewStratClick.bind(this);
        this.handleStratClick = this.handleStratClick.bind(this);
        this.renderEnablingStrategyPanel = this.renderEnablingStrategyPanel.bind(this);
        this.renderEditStrategy = this.renderEditStrategy.bind(this);
        this.onBackClick = this.onBackClick.bind(this);
    }

    handleNewStratClick() {
        this.props.addEnablingStrategy(this.props.project_id, this.props.current_practice_change_id).then(_ => {
            let i = this.props.enablingStrategies.length - 1;
            this.setState({
                currentStrategy: i,
                currentStratId: this.props.enablingStrategies[i].id,
                stratText: this.props.enablingStrategies[i].text,
                selectedTemplate: this.props.enablingStrategies[i].template_id
            });
        })

    }

    handleStratClick(i) {
        this.setState({
            currentStrategy: i,
            currentStratId: this.props.enablingStrategies[i].id,
            stratText: this.props.enablingStrategies[i].text,
            selectedTemplate: this.props.enablingStrategies[i].template_id
        });
    }

    selectTemplates(templates, selectElems){
        let templates_list = [];
        for(let i = 0; i < templates.length; i++){
            let curr = templates[i];
            if(selectElems[curr.linked_cell[0]][curr.linked_cell[1]]){
                templates_list.push(i);
            }
        }
        return templates_list
    }

    onDeleteClick = () => {
        this.setState({currentStrategy: -1});
        this.props.onDeleteCallback( this.props.enablingStrategies[this.state.currentStrategy].id)
    }

    onBackClick(){
        this.props.setStrategyData(this.state.selectedTemplate, this.state.stratText, this.state.currentStratId);
        this.setState({currentStrategy:-1})
    }

    renderEditStrategy(){
        const templates = this.selectTemplates(this.props.templates, this.props.enablingStrategies[this.state.currentStrategy].selectedElems);

        return (<Paper style={{height:"auto", }} elevation={4}>
            <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                <IconButton onClick={this.onBackClick}><ArrowBackIosIcon fontSize={"small"}/></IconButton>
                    Edit Strategy
                <IconButton onClick={this.onDeleteClick}><DeleteIcon fontSize={"small"}/></IconButton>
            </div>
            <Divider/>
            <div style={{padding:"10px 10px", height:"auto"}}>
                <TextField style={{margin:"10px 0px"}}
                           variant={"outlined"}
                           label={"Strategy Text"}
                           rows={3} fullWidth multiline
                           value={this.state.stratText}
                           onChange={e=>this.setState({stratText:e.target.value})}>
                </TextField>
                <Typography style={{fontSize:"18px"}}>
                    Templates

                    <HelpPopper>
                        Suggested enabling strategy templates are provided based on the enabling
                        factor cell(s) you have selected. These suggestions have been determined
                        based on previous transitions analysis.
                        <br/>
                        Select the enabling strategy(ies) you feel would help progress this enabling
                        factor for your particular context. After choosing the suggested enabling
                        strategy template, you must fill in the second half of the strategy text
                        according to the practice change you are assessing.
                        <br/>
                        If none of the suggested enabling strategies are appropriate, you can create
                        a new enabling strategy.
                    </HelpPopper>
                </Typography>

                <div style={{border:"1px solid #B1B1B1", borderRadius:"3px", marginBottom:"10px"}}>
                    <List dense style={{maxHeight:"30vh", overflowY:"auto", }}>
                        {templates.map(v=>{
                            return (<ListItem button key={v} onClick={_=>this.setState({
                                        stratText:this.props.templates[v].text + "...",
                                        selectedTemplate:this.props.templates[v].id})
                            }>
                                <ListItemText>
                                {this.props.templates[v].text}
                                </ListItemText>
                            </ListItem>)
                        })}
                    </List>
                </div>
                <div style={{display:"flex", justifyContent:"flex-end"}}>
                    <Button color={"primary"} variant={"contained"} onClick={this.onBackClick}>
                        Confirm
                    </Button>
                </div>
            </div>

        </Paper>)
    }

    renderEnablingStrategyPanel(){
        if(this.state.currentStrategy >= 0 ){
            return this.renderEditStrategy()

        }
        return (<Paper style={{height:"auto"}} elevation={4}>
            <div style={{margin:"10px 10px", display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                <BuildIcon /> Enabling Strategies <BuildIcon style={{color:"transparent"}}/>
            </div>
            <Divider/>
            <div style={{height:"auto", display:"flex", flexDirection:"column"}}>
                <List style={{maxHeight:"50vh", overflowY:"auto", margin:"10px", }}>
                {this.props.enablingStrategies.map((s, i) => {
                    return (<ListItem button key={i} selected={i === this.state.currentStrategy}
                                      onClick={_ => this.handleStratClick(i)}>
                        <ListItemText disableTypography style={{fontSize:"16px", font:"inherit"}}>{s.text}</ListItemText>


                        <Edit onClick={event => {
                            this.setState({
                                editStrategy: i,
                                editStrategyTarget: event.target.parentNode
                            })
                        }}/>


                    </ListItem>)
                }).reduce((acc, el, id) => acc.concat([el, (<Divider light key={"div" + id}/>)]), [])
                }
                    <Divider/>
                </List>
                <Button onClick={this.handleNewStratClick} variant={"contained"} color={"primary"} style={{justifyContent:"space-around"}}>
                    new enabling strategy
                    <AddCircleOutlineIcon/>
                </Button>
            </div>
        </Paper>)
    }

    render() {
        const highlight_list = [0, 1, 2, 3, 4, 5].map((_, col) => {
            return [0, 1, 2, 3, 4].map((_, row) => {
                if (this.state.currentStrategy < 0) {
                    return {
                        color: "rgba(0,0,0,0)",
                        size: "5px",
                    }
                }
                return {
                    color: "rgba(0,255,0," + (
                        this.props.enablingStrategies[this.state.currentStrategy].selectedElems[col][row] ?
                            255 : 0
                    ) + ")",
                    size: "5px",
                }
            });
        })
        return (
            <div style={{width:"100%", height:"100%", position:"relative"}}>

                <div style={{width:"100%", display:"flex", flex:"1 0 0", flexDirection:"row", }}>
                    <div style={{display:"flex", height: "auto" ,marginRight:"32px", flex:"1 1 70%"}}>
                        <Matrix highlightList={highlight_list} onCellClick={(i, j) => {
                            this.props.esToggleCell(this.state.currentStratId, i, j);
                        }
                        } plainRender clickable matrixId={this.props.current_practice_change_id}/>
                    </div>

                    <div style={{display:"flex", height: "100%" , flex:"1 1 30%", }}>
                            {this.renderEnablingStrategyPanel()}


                        {/*<Popover
                            open={this.state.editStrategy !== null}
                            anchorEl={this.state.editStrategyTarget}
                        >
                            <div>
                                <TextField inputRef={ref=>this.nameRef = ref}>

                                </TextField>
                            </div>
                            <div>
                                <Button onClick={_=>{
                                    this.props.setStratText(this.state.currentStratId, this.nameRef.value)
                                    this.setState({editStrategy:null});
                                }}
                                        fullWidth color="primary" variant={"contained"}>
                                    confirm
                                </Button>
                            </div>
                        </Popover>*/}
                    </div>
                </div>
            </div>)
    }
}


EnablingStrategiesScreen.propTypes = {
    onDeleteCallback: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {

    if(state.project.current_practice_change === null){
        return {
            enablingStrategies: []
        }
    }


    let current_practice_change = state.practiceChanges.find(v=>v.id === state.project.current_practice_change);
    return {
        project_id: state.projects[state.project.current_project].id,
        templates: state.enablingStrategyTemplates,
        current_practice_change_id: current_practice_change.id,
        enablingStrategies: state.enablingStrategies.filter(es => es.practice_change_id === current_practice_change.id),
    };
}

const mapDispatchToProps = dispatch => ({
    setStrategyData:(template, text, id) => {
        dispatch(setStratTemplate(id, template))
        dispatch(setStrategyText(id, text))
    },
    setCurrentScreen: (n) => {
        dispatch(setCurrentScreen(n))
    },
    addEnablingStrategy: (proj_id, pcid) => {
        return dispatch(submitEnablingStrategy(proj_id, pcid))
    },
    esToggleCell: (id, row, col) => {
        dispatch(toggleStrategyCell(id, row, col));
    },
    setStratText: (id, text) => {
        dispatch(setStrategyText(id, text));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(EnablingStrategiesScreen);