
import {combineReducers} from "redux"
import {matrixData} from "./matrixData"
import {matrixInstance} from "./matrixInstance"
import {indicators} from "./indicators"
import {project} from "./project"
import {enablingStrategies} from "./enablingStrategies"
import {issueTemplates} from "./issueTemplates"
import {practiceChanges} from "./practiceChanges"
import {user} from "./user"
import {projects} from "./projects"
import {enablingStrategyTemplates} from "./enablingStrategyTemplates"
import {reports} from "./reports";

export const rootReducer = combineReducers({
    matrixData,
    matrixInstance,
    indicators,
    project,
    enablingStrategies,
    issueTemplates,
    practiceChanges,
    user,
    projects,
    reports,
    enablingStrategyTemplates,
});