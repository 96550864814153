import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

Sentry.init({
    dsn: "https://2db5afb95db6480295b47a0b5bef6776@o329884.ingest.sentry.io/5429250",
    integrations: [
        new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
});

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
