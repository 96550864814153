import React from 'react';
import {PropTypes} from "prop-types";
import {connect} from 'react-redux';
import Paper from "@material-ui/core/Paper/Paper";
import Grid from "@material-ui/core/Grid/Grid";
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Button from "@material-ui/core/Button/Button";

const styles = {
    scorePanelContainer: {
        background: "white",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: "1%",
        boxSizing: "border-box",
        overflow: "hidden"
    },
    divider: n => ({
        height: n + "%"
    }),
}

function lerpColor(a, b, amount) {

    var ah = parseInt(a.replace(/#/g, ''), 16),
        ar = ah >> 16, ag = ah >> 8 & 0xff, ab = ah & 0xff,
        bh = parseInt(b.replace(/#/g, ''), 16),
        br = bh >> 16, bg = bh >> 8 & 0xff, bb = bh & 0xff,
        rr = ar + amount * (br - ar),
        rg = ag + amount * (bg - ag),
        rb = ab + amount * (bb - ab);

    return '#' + ((1 << 24) + (rr << 16) + (rg << 8) + rb | 0).toString(16).slice(1);
}


class VisualSummaryViewer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            projectFilter: [],
            selectCell: [],
            currentProject: "",
            esOpen: false,
            selectedPracChanges:[],
        }
        this.generateData = this.generateData.bind(this);
        this.toggleFilter = this.toggleFilter.bind(this);
        this.handleCellClick = this.handleCellClick.bind(this);
    }

    handleCellClick(row, col){
        if(this.state.selectCell.length > 0){
            if(this.state.selectCell[0] === row && this.state.selectCell[1] === col){
                this.setState({
                    selectCell:[],
                });
                return;
            }
        }
        this.setState({
            selectCell: [row,col],
        })
    }

    toggleFilter(id){
        let index = this.state.projectFilter.indexOf(id);
        if(index === -1){
            this.setState({
                projectFilter: [id, ...this.state.projectFilter]
            })
            return;
        }else{
            let new_filter = [...this.state.projectFilter];
            new_filter.splice(index, 1);
            this.setState({
                projectFilter: new_filter
            })
        }
    }

    static getBackgroundColor(priority) {
        return ["lightgreen", "yellow", "red"][priority];
    }


    generateData(){
        let sums = this.props.matrixData.rows.map(v => {
            return v.elements.map(k=>0);
        });

        this.props.practiceChanges.map(v => {

            if(this.state.projectFilter.indexOf(v.id) !== -1){
                return;
            }
            v.matrixData.entries.map((v,i) => {
                v.map((k,j) => {
                    if(k.priority === null){
                        sums[i][j] += 3;
                    }else {
                        sums[i][j] += k.priority;
                    }
                })
            })

        })
        sums = sums.map(v => v.map(k => k/this.props.practiceChanges.length));
        let max = sums.reduce((acc, v) => Math.max(acc, v.reduce((acc2, v2) => Math.max(acc2, v2)), 0), 0);
        let min = sums.reduce((acc, v) => Math.min(acc, v.reduce((acc2, v2) => Math.min(acc2, v2)), max), max);
        if(max === min){
            return sums.map(v => v.map(k => {
                return {
                    backgroundColor: "grey"
                }
            }));
        }
        sums = sums.map(v => v.map(k => 5 - Math.min(5, Math.floor(6*(max - k)/(max - min)))));
        return sums.map((v,i) => v.map((k, j) => {
            return {
                border: i === this.state.selectCell[0] && j === this.state.selectCell[1] ? "solid 5px blue": "none",
                backgroundColor: ["green", "GreenYellow", "yellow", "orange", "orangered", "red"][k]
            }
        }));

    }

    handleProjChange = (event,child) => {
        this.setState({
            currentProject: event.target.value
        })
    }

    selectPracticeChange = id => _ => {
        let temp = [...this.state.selectedPracChanges];
        let index = temp.indexOf(id);
        if(index < 0){
            temp.push(id);
        }else{
            temp.splice(index, 1);
        }

        this.setState({
            selectedPracChanges: temp,
        })

    }

    renderLegendColumn = () => {
        return <div style={{height:"100%",background:"white", minWidth:"15vw", zIndex:"100", left:"0px", position:"sticky"}}>
            {
                this.props.matrixData.rows.map((row,i) => {
                    let highlight = !this.state.highlight ? false: this.state.highlight.min <= i && this.state.highlight.max >= i
                    return <div style={{ height:(100/6) + "%", padding:"5px", boxSizing:"border-box"}}>
                            <div style={{boxSizing:"border-box", padding:"5px", color: "white", height:"100%", backgroundColor: highlight ? "#46847e": "#99c4c8"}}>
                                {row.label}
                        </div>
                    </div>
                })
            }
        </div>
    }
    highlight = (ran, i) => {
        this.setState({
            highlight:ran,
            hoveredIndex:i,
        })
    }

    getMatrixRange = (mat) => {
        let min_yellow = null;
        let max_yellow = null;
        for(let row = 0; row < 6; row++){
            for(let col = 0; col < 5; col++){
                let entry = mat.entries[row][col];
                if(entry.priority === 1){

                    if(min_yellow === null || min_yellow > row){
                        min_yellow = row;
                    }
                    if(max_yellow === null || max_yellow < row){
                        max_yellow = row;
                    }

                }
            }
        }

        return {
            min: min_yellow,
            max: max_yellow,
        }
    }

    renderPracChanges = () => {
        return <div style={{overflowX:"auto", maxHeight:"100%", height:"100%", display:"flex", flexDirection:"row"}}>
            {
                this.renderLegendColumn()
            }
            {
                this.props.selectedPracChanges.map((pc_id,i) => {
                    let pc = this.props.practiceChanges.find(pc => pc.id === pc_id);
                    let project = this.props.projects.find(proj => proj.id === pc.project_id);
                    let ran = this.getMatrixRange(pc.matrixData);
                    let pct = i/this.props.selectedPracChanges.length;
                    let color = lerpColor("#5686d2", "#144593", pct);
                    if(ran.min === null || ran.max === null){
                        return <div style={{minWidth:"5vw", maxWidth:"10vw", height:"100%", marginLeft:"30px", flex:"1 1 5vw"}}>
                            no yellows
                        </div>
                    }

                    return <div>
                        <div style={{height: (ran.min*100/6) + "%",}}/>
                        <div
                            onMouseEnter={_=>this.highlight(ran, i)}
                            onMouseLeave={_=>this.highlight(null)}
                            style={{
                                color:"white",
                                boxSizing:"border-box",
                                padding:"5px",
                                position:"relative",
                                border:"solid 2px "+color,
                                minWidth:"10vw",
                                maxWidth:"20vw",
                                background: color + "CC",
                                borderRadius:"5px",
                                height:((ran.max - ran.min + 1)*100/6) + "%",
                                marginLeft:"5px",
                                display:"flex",
                                marginRight:"5px",
                                flex:"1 1 10vw",
                                justifyContent:"center",
                                alignContent:"center",
                                textAlign:"center",
                                flexDirection:"column",
                            }}
                        >
                            <div style={{display:i === this.state.hoveredIndex ? "block":"none",
                                fontSize:"0.6em",
                                background:"gray",
                                padding:"10px",
                                zIndex:"300",
                                width:"300px",
                                top:"50%",

                                position:"absolute"}}>
                                {this.props.enablingStrategies.filter(es => {
                                    return es.practice_change_id === pc_id
                                }).map(es => {
                                    return <div>{es.text}</div>
                                })}
                            </div>
                            {project.name}<br/>
                            {pc.practice_change_name}
                        </div>
                    </div>
                })
            }
        </div>
    }

    renderPracChangesTable = () => {
        return <div>
            <Button fullWidth variant={"outlined"} color={"primary"} onClick={_=>this.setState({esOpen: !this.state.esOpen})}>{this.state.esOpen ? "Hide" :"Show"}</Button>
            {!this.state.esOpen ||  <div>
                    <div style={{display:"flex",borderBottom:"solid 2px lightgrey", fontSize:"1.1em"}}>
                        <div style={{flex:"1 0 0",padding:"2px", borderRight:"solid 2px lightgrey"}}>Practice Change Name</div>
                        <div style={{minWidth:"20px"}}/>
                        <div style={{flex:"3 0 0"}}>Enabling Strategy</div>
                </div>
                <div>
                    {this.props.selectedPracChanges.map((pc_id,i) => {
                        if(this.state.hoveredIndex !== i)return;
                        let pc = this.props.practiceChanges.find(pc => pc.id === pc_id);
                        return <div>
                            {this.props.enablingStrategies.filter(es => {
                                return es.practice_change_id === pc_id
                            }).map(es => {
                                return <div style={{display:"flex",borderBottom:"solid 2px lightgrey", minHeight:"50px"}}>
                                                <div style={{flex:"1 0 0", width:"25%", padding:"2px", borderRight:"solid 2px lightgrey"}}>{pc.practice_change_name}</div>
                                                <div style={{minWidth:"20px"}}/>
                                                <div style={{flex:"3 0 0"}}>{es.text}</div>
                                </div>
                            })}
                        </div>
                })}
                </div>
            </div>}
        </div>
    }

    render() {
        let current_project = this.props.projects.find(proj => proj.name === this.state.currentProject);
        let prac_changes = [];
        if(current_project){
            prac_changes = this.props.practiceChanges.filter(pc => pc.project_id === current_project.id);
        }
        return (
            <div style={{height:"100%",paddingTop:"10px", paddingBottom:"10px", display:"flex", flexDirection:"column"}}>
                <div style={{flex:"1 0 0", transition:"ease 0.3s all" , display:"flex",}}>
                    <Grid container>

                        <Grid  item xs={12}>
                            {this.renderPracChanges()}
                        </Grid>

                    </Grid>
                </div>
                <div style={{flex:(this.state.esOpen ? "1" : "0")  +" 0 36px", transition:"ease 0.3s all"}}>
                    <div style={{height:"100%", width:"100%", overflowY:"auto"}}>
                    {this.renderPracChangesTable()}
                    </div>
                </div>
            </div>
        )
    }
}

VisualSummaryViewer.propTypes = {

    onBackCallback: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {

    return {
        projects:state.projects,
        matrixData: state.matrixData,
        practiceChanges: state.practiceChanges,
        enablingStrategies: state.enablingStrategies,
    };
}

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(VisualSummaryViewer);