import React from 'react';
import {PropTypes} from "prop-types"
import {setCurrentScreen} from "../actions/projectActions";
import {connect} from "react-redux";
import Button from "@material-ui/core/Button/Button";


const styles = {
    container: (up) => ({
        top: up ? "-15vh": "0",
        position:"absolute",
        transition:" 0.3s all",
        width:"100%",
    }),
    innerWrapper: {
        width:"50%",
        height:"15vh",
        margin:"auto",
    },
    bar: {
        height:"25px",
        position:"relative",
        overflow:"hidden",
        marginTop:"12.5px",
        border:"solid 4px rgb(0,103,114)",
        borderRadius:"12.5px",
        background:"white"
    },
    barInner: (val) => ({
        height:"100%",
        overflow:"hidden",
        width:val + "%",
        transition:"0.3s all",
        background:"rgba(0,103,114,0.8)"
    })

};

class OverviewProgress extends React.Component {


    constructor(props) {
        super(props);
    }

    renderSteps = () => {
        return <div style={{width:"100%"}}>
            <div style={{width: "80%", margin:"auto"}}>
                {this.props.step_text.map((_,index) => {
                    let textcolor = index === this.props.current_step ? "#000" : "#FFF"
                    let color = index === this.props.current_step ? "#3294a3" : "#00616F"
                    let hlcolor = index === this.props.current_step ? "grey" : "#2091a3"
                    hlcolor = index === this.props.current_step ? "lightgreen" :hlcolor;
                    hlcolor = this.props.error ? "rgb(226, 189, 0)" : hlcolor;
                    let cursor = this.props.error ? "not-allowed" : "pointer"
                    return <div style={{boxSizing:"border-box",
                            cursor:cursor,

                            transition:" 0.3s all",
                            color:textcolor,
                            position:"relative",
                            border:"solid 3px "+ hlcolor,
                            display:"inline-block",
                            backgroundColor:color,
                            width:100/this.props.steps + "%",
                            height:"40px",
                            zIndex:this.props.steps-index
                        }}
                        onClick={_=>this.props.setCurrentScreen((index) * 6)}
                    >


                        <div style={{paddingLeft:(index === 0?"0px": "26px"), textAlign:"center", lineHeight:"37px"}}>
                            {_}
                        </div>
                    </div>

                })}
            </div>
        </div>
    }

    render() {
        let color = "rgba(0,103,114)"
        let colora = "rgba(0,103,114, 0.5)"
        if(this.props.error !== null){
            color = "rgb(226, 189, 0)"
            colora = "rgba(226, 189, 0, 0.5)"
        }else if(this.props.current_step === this.props.steps){
            color = "rgb(45, 160, 0)"
            colora = "rgba(45, 160, 0, 0.5)"
        }
        return (<div style={styles.container(this.props.up)}>
            <div style={styles.innerWrapper}>
                {this.renderSteps()}
            </div>
        </div>);
    }
}


const mapStateToProps = (state, ownProps) => {


    return {
        current_screen: state.project.current_screen,
    };
}

const mapDispatchToProps = dispatch => ({
    setCurrentScreen: (n) => {
        dispatch(setCurrentScreen(n))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(OverviewProgress);
