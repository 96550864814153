const defaultState = {
    heading_row: [
        {text: "Transition phase", extended: ""},
        {text: "Champions", extended: "Key networks of individuals and organisations"},
        {text: "Platforms for connecting", extended: "(Semi) Formalised organisations, structures, & processes for coordination and alignment"},
        {text: "Knowledge", extended: "Research, science & contextualised knowledge"},
        {text: "Projects and applications", extended: "Experiments, demonstrations, & focus projects"},
        {text: "Implemenation guidance technical", extended: "Tools and guidance for solution design and implementation"},
        {text: "Implemenation guidance administrative", extended: "Administrative instruments including policy, planning and regulation"}
    ],
    rows: [
        {
            label: "1. Issue emergence",
            elements: [
                {text: "Issue activists", extended: "Issue activists (from community or industry) champion the issue"},
                null,
                {text: "Issue highlighted", extended: "Science or local experience identifies there is an issue"},
                {text: "Issue examined", extended: "Studies (conducted locally or elsewhere) exist that have high profile in the local context"},
                null,
                null,
            ]
        },
        {
            label: "2. Issue definition",
            elements: [
                {text: "Individual champions", extended: "Individual champions (from science, industry or community) define the issue from their own perspective, providing greater clarity about why it’s important"},
                {text: "Sharing concerns and ideas", extended: "Early connections between champions emerge, through which the need for better coordination to profile the issue is identified\n"},
                {text: "Causes and impacts examined", extended: "Science explains the specific causes and impacts of the issue"},
                {text: "Solutions explored", extended: "Solution options are experimented with"},
                {text: "Data and evidence collected", extended: "Knowledge and data on the issue is captured in reports and databases to inform the development of potential solution ideas."},
                null,
            ]
        },
        {
            label: "3. Shared understanding and issue agreement",
            elements: [
                {text: 'Connected champions', extended: "Champions connect with each other to propose ideas for specific solutions and initiate solution development that address the issue"},
                {text: 'Developing a collective voice', extended: "Mechanisms exist that aim to develop a collective voice that speaks with clarity about the issue and advocates potential solutions"},
                {text: 'Solutions developed', extended: "Collaboration across science and industry develops basic solutions"},
                {text: 'Solutions experimented with', extended: "Solutions are tested in the field and evaluated for performance, feasibility and community acceptance"},
                {text: 'Preliminary practical guidance', extended: "Preliminary practical guidance on the solutions exist in the form of generic templates and guiding principles for implementation"},
                {text: 'Administrative instruments explored', extended: "Administrative instruments are explored by a champion group and are a) informal or b) being tested by individual jurisdictions if at a city scale (e.g. planning frameworks and amendments)."},
            ]
        },
        {
            label: "4. Knowledge dissemination",
            elements: [
                {text: 'Influential champions', extended: "Champions have a common position and are engaging with policy actors to advance and make way for solutions to be reflected in policy and practice"},
                {text: 'Building broad support:', extended: "Mechanisms exist that aim to broadcast consistent messages to build broad support and develop pathways for implementing solutions"},
                {text: 'Solutions advanced', extended: "Collaboration across science and industry advances the solutions through validation, refinement and generalisation"},
                {text: 'Solutions demonstrated at scale', extended: "Significant solution demonstrations exist that include a learning agenda"},
                {text: 'Refined practical guidance and design tools', extended: "Refined practical guidance exists that is aimed at practitioners (e.g. engineering guidelines) and translated from research. Basic modelling and simulation tools exist."},
                {text: 'Early policy and performance standards', extended: "Administrative instruments are beginning to be implemented and explored by other organisations/ sectors. The practice change is recognised in policy, early regulation and preliminary performance standards.."},
            ]
        },
        {
            label: "5. Policy and practice diffusion",
            elements: [
                {text: 'Organisational champions', extended: "Organisational champions lead multi-stakeholder networks in becoming aligned and starting to embed the solutions in policy and practice "},
                {text: 'Expanding the community of practice', extended: "Mechanisms exist that aim to expand the community of practice focused on implementing solutions to address the issue and building stakeholder support for the solutions"},
                {text: 'Capacity building', extended: "Solutions are translated into simplified packages for broad application with accompanying capacity building activities"},
                {text: 'Widespread implementation and learning', extended: "Widespread industry-led adoption of new practices leads to organisational learning and adaptation"},
                {text: 'Implementation guidance and guidance for other sectors', extended: "Refined practical guidance, informed by case study learnings, including guidance on how to support the implementation of solutions (e.g. maintenance, financing). Practical guidance is translated to other sectors."},
                {text: 'Refined policy and standards, early regulation', extended: "Administrative instruments are refined and formalised as the practice undergoes further refinement."},
            ]
        },
        {
            label: "6. Embedding new practice",
            elements: [
                {text: 'Multi-stakeholder networks', extended: "Stakeholders are implementing practices that are aligned across sectors to deliver the solutions"},
                {text: 'Guiding consistent application', extended: "Mechanisms exist that aim to develop and embed the systems and processes that enable ongoing and consistent implementation of the practice across the sector"},
                {text: 'Monitoring and evaluation', extended: "Science focuses on reflection, monitoring, evaluation; new questions and directions emerge"},
                {text: 'Standardisation and refinement', extended: "Implementation is being standardised and refined"},
                {text: 'Comprehensive / standardised design and implementation guidance', extended: "Comprehensive design and implementation guidance for conventional solution application (including standardised templates) is informed by case studies. Guidance exists for other sectors and for other innovative applications that lead to new research questions."},
                {text: 'Comprehensive policy and regulation', extended: "A comprehensive policy and regulatory framework (e.g. legislation, regulatory standards, oversight, measurement, reporting, accountability) begins to achieve consistent sector-wide governance and application of the practice."},
            ]
        }
    ]
}

export const matrixData = function (state = defaultState, action) {

    return state;
}