import React from 'react';
import {PropTypes} from "prop-types"
import {setCurrentScreen} from "../actions/projectActions";
import {connect} from "react-redux";
import Button from "@material-ui/core/Button/Button";
import ProjectProgression from "./ProjectProgression";
import HelpPopper from "./HelpPopper";
import Dialog from "@material-ui/core/Dialog";
import OverviewProgress from "./OverviewProgress";

function verifyMatrix(matrix_data){
    let prev = null;

    for(let col = 0; col < matrix_data.columnJustifications.length; col++){
        if(matrix_data.columnJustifications[col] === ""){
            return false;
        }
    }
    for(let col = 0; col < 5; col++) {
        prev = null;
        for (let row = 0; row < 6; row++) {

            if((col === 1 && row === 0) || (col === 4 && row < 2)){
                continue;
            }

            let curr = matrix_data.entries[row][col];
            if(prev === null){
                prev = curr;
                continue;
            }else if(prev.priority <= curr.priority || curr.priority === null){
                prev = curr;
                continue;
            }else if(prev.priority === null && curr.priority !== null){
                return false;
            }else if(prev.priority > curr.priority){
                return false;
            }
        }
    }
    return true;
}

const styles = {
    container: {
        width:"100vw",
        height:"100vh",
        overflowX:"hidden",
        overflowY:"hidden",
        position:"absolute",
    },
    controls:{
        position:"absolute",
        width:"100%",
        top:0,
    },
};

class ScreenManager extends React.Component {


    constructor(props) {
        super(props);
        this.nextCallback = this.nextCallback.bind(this);
        this.prevCallback = this.prevCallback.bind(this);
    }


    nextCallback(){
        this.props.nextCallback();
    }

    jumpCallback = (index) => {
        this.props.jumpCallback(Math.max(0, index))
    }

    prevCallback(number){
        this.props.prevCallback();
    }

    renderHelpPopper = () => {
        return <Dialog>
            The CRCWSC has developed the Transition Dynamics Framework, which identifies six distinct
            phases of change in any transition to new practice:
            <ol>
                <li> An issue with old practice emerges </li>
                <li> The issue becomes more defined </li>
                <li> People develop shared understanding and agreement about the issue </li>
                <li> Knowledge about solutions is disseminated </li>
                <li> New solutions are diffused through policy and practice </li>
                <li> Solutions are embedded as new mainstream practice </li>
            </ol>
            As a city moves through each phase sequentially, enabling conditions are established to support its
            trajectory towards its practice change and avoid the risk of change pathways that reflect lock-in,
            backlash or system failure patterns.<br/>
            Actions to orient and drive change towards a desired practice change need to progressively establish
            these enabling conditions. Actions with the most impact during the early phases of transition will be
            different from those during the later phases. It is critical to identify a city’s current phase of
            change to ensure that actions are prioritised according to the effectiveness they will have in
            accelerating the transition.<br/>
            The CRCWSC’s Transition Dynamics Framework sets out five types of enabling factors that need to
            be present throughout a transition: champions, platforms for connecting, knowledge, projects and
            applications, and practical and administrative tools and instruments. Together, these five factors
            create an enabling environment for a transition and, mapped against the six transition phases,
            they create a matrix (Figure 1) for a deeper understanding of the current transition phase.

        </Dialog>
    }

    render() {

        let nsteps = 3;
        let current_step = 0;
        let current_task = null;
        let prog_up = true;
        let overview_up = false;
        let matrix_error = null;
        let is_complete = false;
        let current_step_index = 0;
        let current_practice_change = this.props.current_practice_change;
        if((current_practice_change !== null || this.props.newPracticeChange)  && this.props.current_screen < 6 && this.props.current_screen >= 1){
            prog_up = false
            overview_up = true;
        }
        if(current_practice_change !== null || this.props.newPracticeChange){
           if(this.props.matrix_valid){
                current_task = "Define practice change"
                current_step = 1
                current_step_index = 1;

                if(this.props.current_screen === 2) {
                    current_task = "Define enabling factors"
                    current_step = 2
                    current_step_index = 2;

                }else if(this.props.current_screen === 3){
                    current_task = "Define transition phase"
                    current_step = 2
                    current_step_index = 2;

                }else if(this.props.current_screen === 4){

                    current_step = 3
                    current_step_index = 3;

                    current_task = "Define enabling strategies"
                }else if(this.props.current_screen === 5){
                    current_step = 3
                    current_step_index = 3;

                    is_complete = true;
                    current_task = "complete"
                }
            }else{
                current_step = 2;
                current_task = "Fix matrix entries"
                matrix_error = "TDF is not completed, fill out justifications and enabling factor presence to proceed"

            }
        }
        if(this.props.newPracticeChange){
            current_step = 1;
            current_task = "Define practice change"
            matrix_error = "Define your practice change before continuing"
        }



        return (<div style={styles.container}>
            <div>
            {
                React.Children.map(this.props.children, (child, i) => {
                    return React.cloneElement(child, {
                        style:{transform:"translate("+(i-this.props.current_screen)*100+"%)"},
                        active:this.props.current_screen === i,
                        nextCallback:this.nextCallback,
                        prevCallback:this.prevCallback,
                        jumpCallback:this.jumpCallback,
                        last_screen: i === React.Children.count(this.props.children) - 1,
                        first_screen: i === 0,
                        index: i,
                        currentScreen: this.props.current_screen

                    })
                })
            }
                {this.props.user_state.user_authenticated &&
                <div style={styles.controls}>
                    <ProjectProgression is_complete={is_complete} current_step_index={current_step_index}
                                        current_task={current_task} error={matrix_error} up={prog_up}
                                        current_step={current_step} steps={nsteps} step_text={[
                        "Practice Change",
                        "Phase",
                        "Action",
                    ]} step_screen={[
                        1, 2, 4
                    ]}/>
                    {/*<OverviewProgress up={overview_up} current_step={this.props.current_screen === 0 ? 0 : 1} steps={2}
                                      step_text={[
                                          "Projects",
                                          "Reporting"
                                      ]}/>*/}
                </div>
                }
            </div>
        </div>);
    }
}


const mapStateToProps = (state, ownProps) => {


    let prac_change = state.project.current_practice_change === null ? null : state.practiceChanges.filter(v=>v.id === state.project.current_practice_change)[0];
    let es = prac_change === null ? null : state.enablingStrategies.filter(es => es.practice_change_id === prac_change.id)
    return {
        user_state: state.user,
        project: state.project,
        current_screen: state.project.current_screen,
        current_practice_change: prac_change,
        current_enabling_strategies: es,
        matrix_valid: prac_change === null ? null : verifyMatrix(prac_change.matrixData)
    };
}

const mapDispatchToProps = dispatch => ({
    setCurrentScreen: (n) => {
        dispatch(setCurrentScreen(n))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(ScreenManager);
