import React from 'react';
import {PropTypes} from "prop-types";
import {connect} from 'react-redux';
import {hideMessage, setCurrentProject, setCurrentScreen} from "../actions/projectActions";
import {deleteEnablingStrategy, submitPracticeChange} from "../actions/serverActions";
import Typography from "@material-ui/core/Typography/Typography";
import Drawer from "@material-ui/core/Drawer/Drawer";
import Hidden from "@material-ui/core/Hidden/Hidden";
import Button from "@material-ui/core/Button/Button";
import ListItem from "@material-ui/core/ListItem/ListItem";
import Card from "@material-ui/core/Card/Card";
import CardHeader from "@material-ui/core/CardHeader/CardHeader";
import CardContent from "@material-ui/core/CardContent/CardContent";
import TextField from "@material-ui/core/TextField/TextField";
import Divider from "@material-ui/core/Divider/Divider";
import Fab from "@material-ui/core/Fab/Fab";
import AddIcon from '@material-ui/icons/Add';
import Collapse from "@material-ui/core/Collapse/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import EditIcon from '@material-ui/icons/Edit';
import Popper from "@material-ui/core/Popper/Popper";
import Fade from "@material-ui/core/Fade/Fade";
import Paper from "@material-ui/core/Paper/Paper";
import Popover from "@material-ui/core/Popover/Popover";
import Grow from "@material-ui/core/Grow/Grow";
import EditMatrix from "../components/editMatrix/EditMatrix";
import {setPracticeChange, setPracticeChanges} from "../actions/practiceChangeActions";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import EnablingStrategiesScreen from "../components/enablingStrategies/EnablingStrategiesScreen";
import CreatePracticeChange from "../components/createPracticeChange/CreatePracticeChange";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import FilledInput from "@material-ui/core/FilledInput";
import CardActions from "@material-ui/core/CardActions";
import PracticeChangePanel from "../components/createPracticeChange/PracticeChangePanel";
import {buildMatrix} from "../reducers/practiceChanges";
import Screen from "../components/Screen";
import {withRouter} from "react-router-dom";
import {checkAuth} from "../actions/authenticationActions";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import List from "@material-ui/core/List/List";
import CloseIcon from '@material-ui/icons/Close';
import {updateProjectData} from "../actions/projectsActions";

const styles = {
    drawer:{
        width: 300,
    },

}

class ProjectContentScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            selectedTab: 0,
            openIssues: true,
            selectedIssues: null,
            proj: this.props.projects.find(proj=>proj.id===this.props.project_id),
            viewDetails: false,
            anchorEI: null,
            selectedPC: "",
            activeStep: 1,
            screenWidth: window.innerWidth,
            openDrawer: false,
            editIssue: false,
            openNewIssueDialog: false,
            openNewPCDialog: false,
            template_index: -1,
            title:"Issues",
            header:"",
        };
        this.updateScreenWidth = this.updateScreenWidth.bind(this);
    }

    componentDidMount() {
        let proj_id = this.props.match.params.projectID;
        this.setState({
            loading:true,
        })
        this.props.checkAuth().then(project_list=>{
            this.props.setCurrentProjectCallback(parseInt(proj_id));
            let index = project_list[0].findIndex(proj=>proj.id===parseInt(proj_id));
            this.setState({
                loading: false,
                proj: project_list[0][index],
            });
            this.props.setCurrentProject(index);
            this.updateScreenWidth();
            window.addEventListener('resize', this.updateScreenWidth);

        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateScreenWidth);
    }

    updateScreenWidth() {
        this.setState({ screenWidth: window.innerWidth, });
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.practiceChanges.length !== this.props.practiceChanges.length){
            if(this.state.selectedIssues === null){
                return;
            }
            this.setState({
                selectedIssues: this.props.practiceChanges.filter(f => f.issue_name === this.state.selectedIssues[0].issue_name)
            })
        }

        /*if(prevProps.practiceChanges!==this.props.practiceChanges){
            if(this.state.selectedIssues === null){
                return;
            }
            this.props.setPracticeChanges(this.props.practiceChanges)
            this.setState({selectedIssues: this.props.practiceChanges.filter(f => f.issue_name === this.state.selectedIssues[0].issue_name)})
        }*/
    }
    
    handlePracChangeCreated = () => {
        this.setState({
            openNewPCDialog: false,
            openNewIssueDialog: false,
        })
    };

    handleOnCreate = (name, desc, indicators) => {
        this.handlePracChangeCreated();

        if(this.state.openNewPCDialog){
            this.props.createPracticeChange(
                this.props.project.id,
                this.state.selectedIssues[0].issue_name,
                this.state.selectedIssues[0].issue_description,
                name,
                desc,
                indicators);
        }
        this.setState({
            openNewIssueDialog:false,
            openNewPCDialog:false})

    };

    renderNewIssueDialog = () => {
        return(<Dialog open={this.state.openNewIssueDialog} fullWidth
                       keepMounted={false}
                       maxWidth={"md"} >
            <DialogTitle>Create New Issue
                <IconButton style={{float:"right", padding:0}}
                            size={"small"}
                            onClick={_=>this.setState({openNewIssueDialog:false})}>
                <CloseIcon/></IconButton>
            </DialogTitle>
            <Divider/>
            <DialogContent style={{padding:"0px"}}>
                <CreatePracticeChange onCreateCallback={this.handlePracChangeCreated}
                                      editing={this.state.openNewIssueDialog}/>
            </DialogContent>
        </Dialog>)
    };

    renderNewPracChangeDialog = () => {
        return(<Dialog open={this.state.openNewPCDialog} fullWidth
                       maxWidth={"lg"} onClose={_=>this.setState({openNewPCDialog:false})}>
            <DialogTitle>Create New Practice Change</DialogTitle><Divider/>
            <DialogContent style={{padding:"15px 5px"}}>
                <PracticeChangePanel editing={this.state.openNewPCDialog}
                                     currentPracChange={this.props.current_practice_change}
                                     onCreateCallback={this.handleOnCreate}
                                     templateIndex={this.state.template_index}/>
            </DialogContent>
        </Dialog>)
    }

    renderDrawer() {
        let all_issues = new Set(this.props.practiceChanges.map(p => p.issue_name));
        let issues = [...all_issues];
        return (
            <Hidden smDown><Drawer variant={"permanent"} open>
                <div style={styles.drawer}>
                    <div style={{height:"65px", }}>
                    </div>
                    <ListItem style={{justifyContent:"space-between"}}
                              button key={2}
                              onClick={_=>this.setState({
                                  openIssues:this.state.selectedTab===0 ?(!this.state.openIssues):this.state.openIssues,
                                  selectedTab:0,
                                  title:"Issues",
                                  header:""})}
                              selected={this.state.selectedTab===0}>
                        Issues
                        {this.state.openIssues ?<ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={this.state.openIssues} timeout="auto" unmountOnExit style={{marginLeft:"20px"}}>
                        {issues.map(i =>
                            {return <ListItem button onClick={_=>{this.setState({
                                selectedIssues:this.props.practiceChanges.filter(iss=> iss.issue_name===i),
                                selectedTab:1,
                                title:this.props.practiceChanges.filter(iss=> iss.issue_name===i)[0].issue_name,
                                header:"Issue:",})}}>
                                {i}
                            </ListItem>}
                        )}

                        <Fab style={{float:"right", marginRight:"20px", marginBottom:"10px"}}
                             size={"small"} onClick={_=>this.setState({openNewIssueDialog:true})}>
                            <AddIcon/>
                        </Fab>
                    </Collapse>
                    <Divider/>
                </div>
            </Drawer></Hidden>
        )
    }

    openDrawer() {
        let all_issues = new Set(this.props.practiceChanges.map(p => p.issue_name));
        let issues = [...all_issues];
        return (<Drawer variant={"temporary"} open={this.state.openDrawer} onClose={_=>this.setState({openDrawer:false})}>
            <div style={styles.drawer}>
                <div style={{height:"65px", }}>
                </div>
                <ListItem style={{justifyContent:"space-between"}}
                          button key={2}
                          onClick={_=>this.setState({openIssues:this.state.selectedTab===0 ?(!this.state.openIssues):this.state.openIssues,selectedTab:0})}
                          selected={this.state.selectedTab===0}>
                    Issues
                    {this.state.openIssues ?<ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.openIssues} timeout="auto" unmountOnExit style={{marginLeft:"20px"}}>
                    {issues.map(i =>
                        {return <ListItem button onClick={_=>{this.setState({
                            selectedIssues:this.props.practiceChanges.filter(iss=> iss.issue_name===i),
                            selectedTab:1})}}>{i}</ListItem>}
                    )}

                    <Fab style={{float:"right", marginRight:"20px", marginBottom:"10px"}}
                         size={"small"} onClick={_=>this.setState({openNewIssueDialog:true, })}>
                        <AddIcon/>
                    </Fab>
                </Collapse>
                <Divider/>
            </div>
        </Drawer>)
    }

    renderField = (name, key) => {
        return (
            <div style={{marginBottom:"10px"}}>
                <Typography>
                    {name}:
                </Typography>
                <TextField value={this.props.project[key]} onChange={e => this.props.setProjectData(this.props.project, key, e.target.value)}/>
            </div>)
    };

    calculateProgress = (prac_change) => {

        let mat_data = prac_change.matrixData;
        let matrix_valid = true;
        for (let col = 0; col < mat_data.columnJustifications.length; col++) {
            if (mat_data.columnJustifications[col] === "") {
                matrix_valid = false;
                break;
            }
        }
        for (let col = 0; col < mat_data.entries[0].length && matrix_valid; col++) {
            let last_value = null;
            let value_found = false;
            for (let row = 0; row < mat_data.entries.length && matrix_valid; row++) {
                let entry = mat_data.entries[row][col];
                if (row === 0 && (col === 1 || col === 4)) {
                    continue;
                } else if (row === 1 && col === 4) {
                    continue;
                }
                if (entry.priority !== null) {
                    value_found = true;
                }
                if (last_value === null) {
                    last_value = entry.priority;
                }


                if (last_value === null && entry.priority !== null) {
                    matrix_valid = false;
                    break;
                }
                if (last_value !== null && entry.priority === null) {
                    continue;
                } else if (last_value !== null && entry.priority < last_value) {
                    matrix_valid = false
                    break;
                }
            }
            if (!value_found) {
                matrix_valid = false;
            }
        }

        if (!matrix_valid) {
            return 2;
        }


        let es = this.props.enablingStrategies.filter(es => es.practice_change_id === prac_change.id);
        if (es.length === 0) {
            return 3;
        }

        return 4;

    };

    renderProgress = (pc) => {

        const prog_text = ["Create practice change", "Define transition phase", "Develop enabling strategies"]
        let progress = this.calculateProgress(pc);

        return <div style={{
            display: "flex",
            flexDirection: "row",
            maxHeight: "40px",
            padding: "5px",
            borderBottom: "solid 2px lightgrey"
        }}>
            <div></div>
            <span style={{
                width: "calc(50%)",
                overflow: "hidden",
                whitespace: "nowrap",
                fontSize: "1.0em",
                textOverflow: "ellipsis"
            }}>
                            {pc.practice_change_name}
                        </span>
            <div style={{flex: "1 1 auto"}}/>
            <div style={{
                marginLeft: "20px",
                overflow: "hidden",
                marginTop: "5px",
                marginBottom: "10px",
                borderRadius: "10px",
                border: "solid 2px grey",
                marginRight: "20px",
                background: "white",
                position: "relative",
                width: "400px"
            }}>
                {progress === 4 ? <div style={{height:"100%"}}>
                        <div style={{height: "100%", width:"100%", backgroundColor: "#77dd77"}}/>
                        <div style={{
                            width: "100%",
                            lineHeight: "20px",
                            height: "100%",
                            position: "absolute",
                            left: 0,
                            top: 0,
                            textAlign: "center"
                        }}>
                            Complete
                        </div>
                    </div> :
                    <div style={{height:"100%"}}>
                        <div style={{height: "100%", width: (100 * progress / 3) + "%", backgroundColor: "#008fa5"}}/>
                        <div style={{
                            width: "100%",
                            lineHeight: "20px",
                            height: "100%",
                            position: "absolute",
                            left: 0,
                            top: 0,
                            textAlign: "center"
                        }}>
                            {progress}/3 - {prog_text[progress - 1]}
                        </div>
                    </div>
                }


            </div>
            <div>
                <Button variant={"contained"} color={"primary"}
                        onClick={_ => {
                            this.props.selectPracChange(this.props.project_index, pc.id, progress === 1 ? 1 : 2);
                            this.setState({
                                selectedTab:null,
                                selectedPC:pc,
                                title:pc.practice_change_name,
                                header:"Practice Change:"})}}>
                    SELECT
                </Button>
            </div>
        </div>
    };

    renderPC = (step) => {
        if (step===0){
            let pc = this.props.practiceChanges.filter(pc=>pc.practice_change_name===this.state.selectedPC.practice_change_name)[0];
            return (<div style={{position:"relative",top:40, width:"100%", display:"flex",flexDirection:"column"}}>
                <div style={{position:"relative", width:"100%", display:"flex",flexDirection:"row"}}>
                        <div style={{width:"50%"}}>
                            <Card style={{margin:"10px 5px"}} raised>

                                <CardHeader style={{backgroundColor:"#FCE588", fontVariant:"small-caps"}}
                                            title={"Issue"} titleTypographyProps={{style:{color:"#8D2B0B"}}}/>
                                <Divider/>
                                <CardContent>
                                    <TextField style={{margin:"10px 0px"}} variant={"outlined"} label={"Issue Name"} fullWidth
                                               value={pc.issue_name}/>
                                    <TextField style={{margin:"10px 0px"}} variant={"outlined"} label={"Issue Description"}
                                               fullWidth multiline rows={4} value={pc.issue_description}/>
                                </CardContent>
                            </Card>
                        </div>
                        <div style={{width:"50%"}}>
                            <Card style={{margin:"10px 5px"}} raised>

                                <CardHeader style={{backgroundColor:"#FCE588", fontVariant:"small-caps"}}
                                            title={"Practice Change"} titleTypographyProps={{style:{color:"#8D2B0B"}}}/>
                                <Divider/>
                                <CardContent>
                                    <TextField style={{margin:"10px 0px"}} variant={"outlined"} label={"Practice Change Name"}
                                               fullWidth value={pc.practice_change_name}/>
                                    <TextField style={{margin:"10px 0px"}} variant={"outlined"}
                                               label={"Practice Change Description"}  fullWidth multiline
                                               rows={4} value={pc.practice_change_description}/>
                                </CardContent>
                            </Card>
                        </div>
                </div>
                    <div style={{position:"relative", width:"100%", display:"flex",flexDirection:"row"}}>
                        <div style={{width:"100%"}}>
                            <Card style={{margin:"10px 5px"}} raised>

                                <CardHeader style={{backgroundColor:"#FCE588", fontVariant:"small-caps"}} title={"Indicators"} titleTypographyProps={{style:{color:"#8D2B0B"}}}/>
                                <Divider/>
                                <CardContent>
                                    {this.renderIndicators(pc.indicators)}
                                </CardContent>
                            </Card>
                        </div>
                        </div>

            </div>)
        }
        else if (step===1){return <div style={{position:"relative",top:40}}><EditMatrix/></div>}
        else if (step===2){return <div style={{position:"relative",top:40}}><EnablingStrategiesScreen onDeleteCallback={strategy_id => this.props.deleteEnablingStrategy(strategy_id)}/></div>}
    };

    renderIndicators = (indicator_array) => {
        let categories = this.props.indicators[0].categories
        let all_data = [];
        let cat_list = [];
        for (let i=0; i<indicator_array.length;i++){
            let indicator_id = indicator_array[i];
            let results = getIndicatorByID(indicator_id, categories)
            all_data.push(results)
            cat_list.push(results.cat_index)
        }

        let all_catIndex = [...new Set(cat_list)];

        return all_catIndex.map(ct_index => {
            return (<div>

                <Typography variant={"h6"}>{categories[ct_index].text}</Typography>
                {all_data.filter(ind => ind.cat_index === ct_index).map(data => {
                    return (
                        <Typography variant={"subtitle1"}>{categories[ct_index].indicators[data.ind_index].text}</Typography>
                    )
                })}
            </div>)
        })

    };

    editIssue = (key, value) => {
        let all_PC =  [...this.state.selectedIssues];
        for (let i = 0; i < this.state.selectedIssues.length; i++){
            all_PC[i][key] = value;
        }
        this.setState({selectedIssues: all_PC})
    };

    saveEditedIssue = () => {
        //console.log(this.props.practiceChanges);
        //this.props.practiceChanges.filter(pc => pc.issue_name === this.props.project_id)
        //let these_PC =  [...this.state.selectedIssues];
        //let all_PC = [...this.props.practiceChanges];
        //for (let i = 0; i < this.state.selectedIssues.length; i++){
            //let pcid = these_PC[i]["id"]
            //console.log(pcid)
            //console.log(all_PC[all_PC.findIndex(pc => pc.id === pcid)])
            //all_PC[all_PC.findIndex(pc => pc.id === pcid)] = these_PC[i]
        //}

        //console.log(all_PC)
        //this.props.setPracticeChanges(all_PC);
        this.setState({editIssue:false});
        //console.log(this.state.selectedIssues);
    };

    renderScreenContent() {
        //console.log(this.props.practiceChanges)
        let all_issues = new Set(this.props.practiceChanges.map(p => p.issue_name));
        let issues = [...all_issues];
        let steps = ["Define","Phase","Action"];
        if (this.state.selectedTab===0){
            return <div>
                <Card style={{margin:"10px 0px", width:"100%"}} raised>
                    <CardHeader title={"Completion Progress"}/>
                    <Divider/>
                    <CardContent style={{height:"calc(100%-200px)",overflowY:"auto"}}>
                        {issues.map(i =>
                        {return <div>

                            <Typography style={{backgroundColor:"#D9E2EC"}} variant={"h6"}>{i}</Typography>

                            {this.props.practiceChanges.filter(p=>p.issue_name===i).map(pc => {
                                return this.renderProgress(pc)
                            })}</div>
                        })}
                    </CardContent>
                </Card>
            </div>;
        }
        else if (this.state.selectedTab===1){
            return (<div>
                <Card style={{margin:"15px 5px"}} raised>
                    <CardHeader title={"Issue"} action={
                        <IconButton disabled={this.state.editIssue} onClick={_=>this.setState({editIssue:true})}>
                            <EditIcon />
                        </IconButton>
                    }/>
                    <Divider/>
                    <CardContent>
                        <Typography>Issue Name:</Typography>
                        <TextField autoFocus disabled={!this.state.editIssue} fullWidth
                                   onChange={e => this.editIssue("issue_name", e.target.value)}
                                   value={this.state.selectedIssues[0].issue_name}/>
                        <div style={{height:"10px"}}> </div>
                        <Typography>Issue Description:</Typography>
                        <TextField multiline fullWidth
                                   disabled={!this.state.editIssue}
                                   onChange={e => this.editIssue("issue_description", e.target.value)}
                                   value={this.state.selectedIssues[0].issue_description}/>
                    </CardContent>
                    {this.state.editIssue
                        ?<CardActions disableSpacing>
                            <Button variant={"outlined"} style={{marginLeft:"auto",marginTop:"auto",}} onClick={this.saveEditedIssue}>
                                Save
                            </Button>
                        </CardActions>:null}
                </Card>
                <Card style={{margin:"15px 5px"}} raised>
                    <CardHeader title={"Practice Change"} action={
                        <IconButton onClick={_=>this.setState({openNewPCDialog:true, })}>
                            <AddIcon/>
                        </IconButton>
                    }/>
                    <Divider/>
                    <CardContent>
                        {this.state.selectedIssues.map(pc => {return(
                            <Card style={{margin:"10px 5px"}} raised>
                                <CardHeader disableTypography title={this.renderProgress(pc)}/>
                                <CardContent style={{paddingTop:0,marginTop:-5}}>
                                    <Typography>Practice Change Description:</Typography>
                                    <Typography>{pc.practice_change_description}</Typography>
                                    <div style={{height:10}}></div>
                                    <Typography>Indicators:</Typography>
                                        {this.renderIndicators(pc.indicators)}
                                </CardContent>
                            </Card>)})}
                    </CardContent>
                </Card>
            </div>)
        }
        else {
            return (<div style={{height:"100%", width:"100%"}}>
                <div style={{position:"relative",top:20, }}>
                    <Stepper nonLinear activeStep={this.state.activeStep}>
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepButton style={{fontVariant:"small-caps"}} onClick={_=>this.setState({activeStep:index})}>
                                    {label}
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                </div>
                {this.renderPC(this.state.activeStep)}




            </div>);
        }
    }

    render(){
        //console.log(this.props.practiceChanges)
        //console.log(this.state.selectedIssues)
        if(this.state.loading){
            return <Dialog open>
                <DialogTitle disableTypography>
                    <Typography variant={"h5"} align={"center"}>
                        Please wait
                    </Typography>
                </DialogTitle>
                <DialogContent style={{overflow:"hidden", margin:"auto"}}>
                    <div style={{width:"35px", height:"55px",}}>
                        <CircularProgress style={{width:"50px", height:"50px", margin:"auto"}} size={50}/>
                    </div>
                </DialogContent>
            </Dialog>
        }
        return <Screen key={1} active={true} appBar={true} nav={true} history={this.props.history}
                       current_project={this.state.proj}
                       title={this.state.title} header={this.state.header} project_id={this.props.project_id}>
            <div style={{height:"calc(100% - 70px)", width:"100%"}}>

            {this.renderDrawer()}
            {this.openDrawer()}
            {this.renderNewPracChangeDialog()}
            {this.renderNewIssueDialog()}

            <div style={{position:"fixed", zIndex:1201, top:10, left:150}}>
                <IconButton size={"small"} style={{color:"white"}} onClick={e=>this.setState({viewDetails:!this.state.viewDetails, anchorEI:e.currentTarget})}>
                    <EditIcon/>
                </IconButton>
            </div>
            {this.state.screenWidth>=960 ?null:
            <div style={{position:"fixed",zIndex:1201, top:10, left:5}}>
                <IconButton size={"small"} style={{color:"white"}} onClick={_=>this.setState({openDrawer:!this.state.openDrawer})}>
                    <MenuIcon/>
                </IconButton>
            </div>}
            <Popover open={this.state.viewDetails}
                     anchorEl={this.state.anchorEI} anchorOrigin={{vertical:"top",horizontal:"right"}}
                     onClose={_=>this.setState({viewDetails:false})}>
                <Card raised>
                    <CardHeader title={"Details"}/>
                    <Divider/>
                    <CardContent>
                        <div style={{display:"flex",flexDirection:"row"}}>
                            <div style={{flexBasis:"50%", margin:"5px 5px"}}>
                                {this.renderField("Time Period", "time_period")}
                                {this.renderField("City", "city")}
                            </div>
                            <div style={{flexBasis:"50%", margin:"5px 5px"}}>
                                    {this.renderField("Whos Involved", "whos_involved")}
                                    {this.renderField("Lead Facilitator/Analyst", "lead_fac_analyst")}
                            </div>
                        </div>
                    </CardContent>
                    <CardActions style={{float:"right"}} ><Button variant={"contained"} color={"primary"} onClick={_=>this.setState({viewDetails:false})}>Confirm</Button></CardActions>
                </Card>
            </Popover>

            <div style={{
                position:"relative",
                left:this.state.screenWidth>=960 ?styles.drawer.width+30:20 ,
                top:"70px",
                height:"calc(100% - 70px)",width:this.state.screenWidth>=960 ?`calc(100% - ${styles.drawer.width+50}px)`:"95%"}}>
                {this.renderScreenContent()}
            </div>
            </div>
        </Screen>;
    }
};

ProjectContentScreen.propTypes = {};

const mapStateToProps = (state, ownProps) => {
    const prac_changes = state.practiceChanges.filter(pc => pc.project_id === ownProps.project_id);
    const enabling_strategies = state.enablingStrategies.filter(es => prac_changes.some(pc => pc.id === es.practice_change_id));

    return {
        user_state: state.user,
        project_index: state.projects.findIndex(i => i.id === ownProps.project_id),
        project: state.projects.find(p => p.id === ownProps.project_id),
        projects: state.projects,
        open: state.project.show_message,
        current_screen: state.project.current_screen,
        current_practice_change: prac_changes.find(pc => pc.id === state.project.current_practice_change) || null,
        progress_block: state.project.progress_block,
        practiceChanges: prac_changes,
        enablingStrategies: enabling_strategies,
        indicators:state.indicators,
    };

}

//TODO: optimize here?
const getIndicatorByID = (id, indicator_categories) => {
    for(let j = 0; j < indicator_categories.length; j++) {

        let indicators = indicator_categories[j].indicators;
        for (let i = 0; i < indicators.length; i++) {
            if (indicators[i].id === id) {
                return {cat_index: j, ind_index: i};
            }
        }
    }

}

const mapDispatchToProps = dispatch => ({

    hideMessage: () => {
        dispatch(hideMessage());
    },
    deleteEnablingStrategy: (id) => {
        dispatch(deleteEnablingStrategy(id))
    },
    setCurrentScreen: (n) => {
        dispatch(setCurrentScreen(n))
    },
    checkAuth: () => {
        return dispatch(checkAuth(localStorage.getItem("email"), localStorage.getItem("token"))).then(data=>{
            let all_data = data[0];

            return all_data
        })
    },

    setPracticeChanges: (practice_changes) => {
        dispatch(setPracticeChanges(practice_changes))
    },

    setCurrentProject: (index) => {
        dispatch(setCurrentProject(index))
    },
    selectPracChange: (index, pcid, n) => {
        dispatch(setCurrentProject(index))
        dispatch(setPracticeChange(pcid));
        dispatch(setCurrentScreen(n))
    },
    createPracticeChange: (pid, iname, idesc, pcname, pcdesc, indics) => {
        dispatch(submitPracticeChange(pid, {
            project_id:pid,
            issue_name: iname,
            issue_description:idesc,
            practice_change_name: pcname,
            practice_change_description: pcdesc,
            indicators:indics,
            practice_change_data: buildMatrix()
        }));
    },
    setProjectData: (proj,key, value) => {
        dispatch(updateProjectData(proj.id, {...proj, [key]:value}))
    }

})

ProjectContentScreen = withRouter(ProjectContentScreen);

export default connect(mapStateToProps, mapDispatchToProps)(ProjectContentScreen);