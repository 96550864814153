import fetch from "cross-fetch"
import {
    deAuthUser,
    setUserAuthError,
    setUserAuthErrorString,
    setUserData,
    setUserGenericError,
    setUserGenericErrorString
} from "./userActions";
import {getEnablingStrategyTemplates, getIndicatorSets, getProjects, getReports, saveProject} from "./serverActions";

export const dance_url ="https://wscu-dev.dance4water.org/api/v1"


export const checkAuth = (username) => {
    const auth = localStorage.getItem("auth");
    return (dispatch, getState) => {
        const email = localStorage.getItem("email");
        const token = localStorage.getItem("token");
        const inou = v => v===undefined||v===null;
        if(!inou(email) || !inou(token)){
            return fetch(dance_url + "/auth/user", {
                method: "get",
                headers: {
                    "Authorization": localStorage.getItem("token")
                }
            }).then(res => {
                if(res.status !== 200){
                    let error = new Error(res.statusText);
                    error.code = res.status;
                    throw error;
                }else{
                    return res.json();
                }
            }).then(json => {
                dispatch(setUserData(email, token));
                dispatch(toolAccess(email))
                return Promise.all([
                    dispatch(getProjects()),
                    dispatch(getIndicatorSets()),
                    dispatch(getEnablingStrategyTemplates()),
                    dispatch(getReports())])

            }).catch(p => {
                if(p.code === 401){
                    dispatch(deAuthUser());
                    dispatch(setUserAuthError());
                    dispatch(setUserAuthErrorString("Authentication Error... Please try again by refreshing the page and enter login information"));
                }else{
                    dispatch(setUserGenericError());
                    dispatch(setUserGenericErrorString("Authentication Error... Please try again by refreshing the page and enter login information"));
                }
            })
        }

    }

}

export const toolAccess =(user_id) => {
    return (dispatch, getState) => {
        return fetch(dance_url + "/projects/user_roles?tool_id=3", {
            method: "get",
            headers: {
                "Authorization": localStorage.getItem("token")
            }
        }).then(res => {
            if(res.status !== 200){
                let error = new Error(res.statusText);
                error.code = res.status;
                throw error;
            }else{
                return res.json();
            }
        }).then(json => {
            
            let access = false
            for (let i = 0; i < json.items.length; i++) {
                // Check user groups
                for (let j = 0; j < json.items[i].user_group.users.length; j++) {
                    console.log(json.items[i].user_group.users[j].id, user_id)
                    if (json.items[i].user_group.users[j].email === user_id) {
                        console.log("User has access to tool")
                        return true
                    }
                }
            }

            if (access === false) {
                dispatch(deAuthUser());
                dispatch(setUserAuthError());
                dispatch(setUserAuthErrorString("Tool Error...You don't have access to the tool. Please contact your administrator"));
            }

            return json;
        }
        ).catch(p => {
            if(p.code === 401){
                dispatch(deAuthUser());
                dispatch(setUserAuthError());
                dispatch(setUserAuthErrorString("Tool Error... Please try again by refreshing the page and enter login information"));
            }
            // else{
            //     dispatch(setUserGenericError());
            //     dispatch(setUserGenericErrorString("Tool Error... Please try again by refreshing the page and enter login information"));
            // }
        })
    }
}

export const authUser = (username, password) => {
    username = username.toLowerCase();
    const auth ="" /// "Basic " + btoa(username + ":" + password);
    return (dispatch, getState) => {
        return fetch(dance_url + "/auth/login", {
            method: "POST",
            headers: {
                "Authorization": auth,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },

            mode:"cors",
            body: JSON.stringify({'email': username, 'password': password })

        }).then(res => {
            if(res.status !== 200){
                let error = new Error(res.statusText);
                error.code = res.status;
                throw error;
            }else{
                return res.json();
            }
        }).then(json => {
            localStorage.setItem("email", username);
            localStorage.setItem("token", json["access_token"]);
            localStorage.setItem("auth", auth);
            dispatch(setUserData(username, json["access_token"]));
            dispatch(getProjects());
            dispatch(getIndicatorSets());
            dispatch(getEnablingStrategyTemplates());
            dispatch(toolAccess( username));
        }).catch(p => {
            if(p.code === 401){
                dispatch(deAuthUser());
                dispatch(setUserAuthError());
                dispatch(setUserAuthErrorString('Authentication Error... Username or password are incorrect'));
            }else{
                dispatch(setUserGenericError());
                dispatch(setUserGenericErrorString('Authentication Error... Please try again by refreshing the page and enter login information'));
            }
        })
    }
}
