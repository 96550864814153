import {saveMixin} from "./serverActions";


export const setProjects = (projects) => ({
    type: "PROJECTS_SET_PROJECTS",
    projects,
});


export const updateProjectData = saveMixin((project_id, data) => ({
    type: "PROJECTS_SET_PROJECT_DATA",
    project_id,
    ...data,
}));

export const createNewProject = (id, name, indicator_set_id) => ({
    type: "PROJECTS_CREATE_NEW_PROJECT",
    id,
    name,
    indicator_set_id,
})

export const removeProject = (id) => ({
    type: "PROJECTS_REMOVE",
    id
})
