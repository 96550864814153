import React, {Component} from 'react';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';

import {Provider} from "react-redux"
import {createStore, compose, applyMiddleware} from "redux"
import thunk from "redux-thunk"
import './App.css';
import {rootReducer} from "./reducers/index"
import {connect} from "react-redux";
import Wrapper from "./Wrapper";
import {authUser, checkAuth, dance_url} from "./actions/authenticationActions";
import {cueAutoSave} from "./actions/projectActions";
import {getIndicatorSets, getProjects, saveProject, saveReports} from "./actions/serverActions";
import {setUserData} from "./actions/userActions";


const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#00616F',
        },
        secondary: {
            main: '#EF4844',
        },
    },
    typography: {
        fontFamily: [
            'New Rail Alphabet',
            'Arial'
        ].join(','),
        useNextVariants:true,
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: "1em",
            }
        }
    }
});

const timestamp = _ => {
    return Math.round((new Date()).getTime() / 1000);
}
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer,   composeEnhancer(applyMiddleware(thunk)));

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {index: 0};
    }

    componentDidMount(){
        setInterval(_=>{
           let do_update = store.getState().project.cue_auto_save;
           if(do_update){
               let p = store.getState().project.current_project;
               if(p !== null){
                   Promise.all([
                       store.dispatch(saveProject(store.getState().projects[p].id)),
                   ]).then(_=>{
                       store.dispatch(cueAutoSave(false));
                   })
               }else{
                   Promise.all([
                       store.dispatch(saveReports(store.getState().reports)),
                       ...store.getState().projects.filter(proj => proj.dirty).map(proj => {
                           return store.dispatch(saveProject(proj.id)).then(_=>
                               store.dispatch({type:'PROJECTS_SET_DIRTY', project_id: proj.id}))
                       })
                   ]).then(_=>{
                       store.dispatch(cueAutoSave(false));
                   })
               }
           }
        }, 5000);
        // store.dispatch(checkAuth(localStorage.getItem("email"), localStorage.getItem("token")))
        store.dispatch(checkAuth(localStorage.getItem("email"), localStorage.getItem("token")))


        // const email = localStorage.getItem("email");
        // const token = localStorage.getItem("token");
        // fetch(dance_url + "/check_auth/",{
        //     method:"get",
        //     headers: {
        //         "authorization": localStorage.getItem("token")
        //     },
        //     mode:"cors",
        // }).then(f=>f.json()).then(d => {
        //     const inou = v => v===undefined||v===null;
        //     if(!inou(email) || !inou(token)){
        //         store.dispatch(setUserData(email, token))
        //         store.dispatch(getProjects());
        //         store.dispatch(getIndicatorSets());
        //     }
        // });



    }

    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <Provider store={store}>
                        <Wrapper/>
               </Provider>
            </MuiThemeProvider>
        );
    }
}


export default App;
