import React from 'react';
import {PropTypes} from "prop-types";
import ListItem from "@material-ui/core/ListItem/ListItem";
import {connect} from 'react-redux';
import {setCurrentScreen} from "../../actions/projectActions";
import {setPracticeChange} from "../../actions/practiceChangeActions";

class DashboardPracticeChangeEntry extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (<ListItem button onClick={_=>this.props.setCurrentScreen(3, this.props.practice_change.id)}>
            {this.props.practice_change.practice_change_name}
        </ListItem>);
    }
}

DashboardPracticeChangeEntry.propTypes = {
    practiceChangeId:PropTypes.number.isRequired,
    practice_change:PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {

    return {
        practice_change: state.practiceChanges.filter(v=>v.id === ownProps.practiceChangeId)[0]
    };
}

const mapDispatchToProps = dispatch => ({
    setCurrentScreen: (n, id) => {
        dispatch(setPracticeChange(id));
        dispatch(setCurrentScreen(n));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPracticeChangeEntry);