

import {saveMixin} from "./serverActions";

export const addEnablingStrategy = saveMixin((practice_change_id, enabling_strategy_id)=>({
    type:"ES_ADD_STRATEGY",
    practice_change_id,
    enabling_strategy_id
}));


export const setEnablingStrategies = (strategies)=>({
    type:"ES_SET_STRATEGIES",
    strategies: strategies,
});




export const setStrategyText = saveMixin((id, text)=>({
    type:"ES_STRAT_TEXT",
    id,
    text,
}));


export const setStratTemplate = saveMixin((index, s_id)=>({
    type:"ES_SET_TEMPLATE",
    index,
    s_id,
}));


export const toggleStrategyCell = saveMixin((id, col, row)=>({
    type:"ES_TOGGLE_CELL",
    id,
    row,
    col,
}));


export const addCellToStrategy = saveMixin((id, col, row)=>({
    type:"ES_ADD_CELL",
    id,
    row,
    col,
}));



export const removeCellFromStrategy =(id, col, row)=>({
    type:"ES_REMOVE_CELL",
    id,
    row,
    col,
});

export const removeEnablingStrategy = (id) => ({
    type: "ES_REMOVE",
    id
})

