import React from 'react';
import {PropTypes} from "prop-types"
import Paper from "@material-ui/core/Paper"
import {styles} from "./MatrixCell"
import JustificationPopover from "./JustificationPopover";
import {setColumnJustification} from "../../actions/matrixInstanceActions"
import {connect} from "react-redux"

const componentStyles = {
    textField:{
        position:"relative",
        height:"100%",
        overflow:"hidden",
    },

    hider:{
        position:"absolute",
        top:0,
        left:0,
        right:0,
        bottom:0,
        backgroundImage:"linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 80%)"
    },
    textFieldInner:{
        position:"absolute",
        padding:"5%",
        top:0,
        left:0,
        right:0,
        wordWrap:"break-word",
        textAlign:"center",
    }
}
class MatrixCellJustification extends React.Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handlePopoverClose = this.handlePopoverClose.bind(this);
        this.state = {popoverOpen: false};
    }

    handlePopoverClose(just){
        if(just !== null){
            this.props.setColJustification(this.props.matrixId, this.props.col, just);
        }
        this.setState({
            popoverOpen:false,
        })
    }

    handleClick(){
        if(this.state.popoverOpen){
            return;
        }
        this.setState({
            popoverOpen:true,
        })
    }

    render() {
        return (<Paper style={styles.innerWrappers} onClick={this.handleClick}>
            <div style={componentStyles.textField}>
                <div style={componentStyles.textFieldInner}>
                    {this.props.justification === ""?
                        (this.props.readOnly ? "": <h2>click to set</h2>)
                        : this.props.justification}
                </div>

                <div style={componentStyles.hider}/>
            </div>

            <JustificationPopover
                readOnly={this.props.readOnly}
                open={this.state.popoverOpen}
                justification={this.props.justification}
                colTitle={this.props.colTitle}
                columnJustifications={this.props.columnJustifications}
                closeCallback={this.handlePopoverClose}/>
        </Paper>);
    }
}

MatrixCellJustification.propTypes = {
    row:PropTypes.number.isRequired,
    col:PropTypes.number.isRequired,
    justification:PropTypes.string.isRequired,
    colTitle: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    matrixId: PropTypes.number.isRequired,
    setColJustification: PropTypes.func.isRequired,
}


const mapStateToProps = (state, ownProps) => {
    const matrix = state.practiceChanges.find(pc => pc.id === ownProps.matrixId).matrixData;
    const entry = matrix.columnJustifications[ownProps.col];


    console.log(matrix.entries, ownProps)
    const cols = matrix.entries.map(row => row[ownProps.col].justification)
    return {
        justification: entry,
        colTitle: state.matrixData.heading_row[ownProps.col+1],
        columnJustifications: cols,
    }
};

const mapDispatchToProps = dispatch => ({
    setColJustification: (id, col, txt)=>{
        dispatch(setColumnJustification(id, col, txt));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(MatrixCellJustification);