import React from 'react';
import {PropTypes} from "prop-types";
import {connect} from 'react-redux';
import Paper from "@material-ui/core/Paper/Paper";
import Grid from "@material-ui/core/Grid/Grid";
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import VisualSummaryViewer from "./VisualSummaryViewer";
import Button from "@material-ui/core/Button";
import {setReportData} from "../../actions/reportsActions";
import {createReport} from "../../actions/serverActions";
import {ListItemText} from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";

const styles = {
    scorePanelContainer: {
        background: "white",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: "1%",
        boxSizing: "border-box",
        overflow: "hidden"
    },
    divider: n => ({
        height: n + "%"
    }),
}


class VisualSummary extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            projectFilter: [],
            selectCell: [],
            currentProject: "",
            selectedPracChanges:[],
        }
    }




    handleProjChange = (event,child) => {
        this.setState({
            currentProject: event.target.value
        })
    }

    selectPracticeChange = id => _ => {
        let temp = [...this.props.selectedReport.data.selectedPracChanges];
        let index = temp.indexOf(id);
        if(index < 0){
            temp.push(id);
        }else{
            temp.splice(index, 1);
        }

        this.props.setReportData(this.props.selectedReport.id, {
                ...this.props.selectedReport.data,
            selectedPracChanges: temp,
        })

    }

    handleNameChange = (event) => {
        this.props.setReportData(this.props.selectedReport.id, {
            ...this.props.selectedReport.data,
            report_name:event.target.value
        })
    }

    render() {
        let current_project = this.props.projects.find(proj => proj.name === this.state.currentProject);
        let prac_changes = [];
        if(current_project){
            prac_changes = this.props.practiceChanges.filter(pc => pc.project_id === current_project.id);
        }
        return (
            <Grid container spacing={32} style={{height:"90%"}}>
                <Grid style={{height: "100%"}} item xl={3} lg={3} md={3} sm={3} xs={3}>

                    <Paper style={styles.scorePanelContainer}>
                        <div>
                            <Button variant={"contained"} color={"primary"} onClick={this.props.onBackCallback}>
                                back
                            </Button>
                        </div>
                        <div>
                            Report name:
                            <TextField value={this.props.selectedReport.data.report_name} fullWidth onChange={this.handleNameChange}/>
                        </div>
                        <div style={{textAlign:"center"}}>
                            <h3>Visual Summary</h3>
                            <p>
                                Select the city and practice
                                change(s) to be added to the
                                visual report
                            </p>
                            <Select style={{textAlign:"left", width:"90%"}} value={this.state.currentProject} onChange={this.handleProjChange}>
                                <MenuItem value={""}>
                                    <em>None</em>
                                </MenuItem>
                                {
                                    this.props.projects.map((proj,i) => {
                                        return <MenuItem key={i} value={proj.name}>{proj.name}</MenuItem>
                                    })
                                }
                            </Select>
                        </div>
                        <div style={{flex:1, overflowY:"auto"}}>
                            <List dense>
                                {
                                    prac_changes.map(pc => {
                                        return <ListItem dense onClick={this.selectPracticeChange(pc.id)} selected={this.props.selectedReport.data.selectedPracChanges.indexOf(pc.id) >= 0}>
                                            {pc.practice_change_name}
                                            </ListItem>
                                    })

                                }
                            </List>
                        </div>
                    </Paper>
                </Grid>
                <Grid style={{height: "100%"}} item xl={9} lg={9} md={9} sm={9} xs={9}>

                    <Paper style={styles.scorePanelContainer}>
                        <VisualSummaryViewer selectedPracChanges={this.props.selectedReport.data.selectedPracChanges || []}/>
                    </Paper>
                </Grid>
            </Grid>
        )
    }
}

VisualSummary.propTypes = {
    selectedReport: PropTypes.object.isRequired,
    onBackCallback: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {

    return {
        projects:state.projects,
        matrixData: state.matrixData,
        practiceChanges: state.practiceChanges,
    };
}

const mapDispatchToProps = dispatch => ({
    setReportData: (id, data) => {
        dispatch(setReportData(id, data))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(VisualSummary);