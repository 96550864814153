


const template = function(state={}, action){
    switch(action.type){
        case "IT_INIT_DATA":
            let new_state = {...state};
            return new_state;
        default:
            return state;
    }
}


export const issueTemplates = function(state=[], action){
    switch(action.type){
        case "IT_INIT_DATA":
            let new_state = action.templates.map(o=>
                template(o, action)
            )
            return new_state;
        default:
            return state;
    }

}