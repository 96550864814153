

const defaultState = [];

export const report = (state = {}, action) => {
    switch(action.type){
        case "REPORTS_SET_REPORT":{
            let new_state = {};
            new_state.id = action.id;
            new_state.data = action.data;
            return new_state;
        }
        case "REPORTS_NEW_REPORT":{
            let new_state = {};
            new_state.id = action.data.id;
            new_state.data = action.data.data;
            return new_state;
        }
        case "REPORTS_SET_DATA":{
            let new_state = {...state};
            new_state.data = action.data;
            return new_state;
        }
        default:
            return state;
    }
}

export const reports = (state = defaultState, action) => {
    switch (action.type){
        case "REPORTS_SET_REPORTS":{
            return action.reports.map(p => report(p, p));
        }
        case "REPORTS_NEW_REPORT":{
            return [...state, report({}, action)];
        }
        case "REPORTS_SET_DATA":{
            return state.map(rp => rp.id === action.id ? report(rp, action): rp)
        }
        default:
            return state;

    }
};