

import {saveMixin} from "./serverActions";

export const setReports = (reports) => ({
    type: "REPORTS_SET_REPORTS",
    reports,
});

export const newReport = (data) => ({
    type: "REPORTS_NEW_REPORT",
    data
})

export const setReportData = saveMixin((id, data) => ({
    type: "REPORTS_SET_DATA",
    id,
    data
}))

