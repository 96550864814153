import React from 'react';
import {PropTypes} from "prop-types"
import Popover from "@material-ui/core/Popover"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import FiberManualRecord from "@material-ui/icons/FiberManualRecord"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Divider from "@material-ui/core/Divider";

const styles = {
    popoverStyle:{
    },
    listStyle:{
        paddingLeft:"10px",
        paddingRight:"10px",
    },
    buttonListItem:{
      width:"100%"
    },
    button:{

    }
}
class PriorityPopover extends React.Component {

    constructor(props) {
        super(props);
        this.state = {picked_priority:props.value, justification:props.justification};
        this.listItemClick = this.listItemClick.bind(this);
    }

    listItemClick(priority){
        this.setState({
            picked_priority:priority
        })
    }


    renderPriorityListItem(text, color, priority){
        return(
                <ListItem divider button
                          style={{border:"1px solid #CFCFCF", borderRadius:"10px", marginBottom:"5px"}}
                          selected={priority===this.state.picked_priority}
                          onClick={_=>this.listItemClick(priority)}>
                    <ListItemIcon >
                        <FiberManualRecord style={{ fontSize:"6vmin",color:color}}/>
                    </ListItemIcon>
                    <ListItemText primary={text} primaryTypographyProps={{variant:"h4", align:"right"}}>

                    </ListItemText>
                </ListItem>
            )
    }
    render() {

        return(
            <Popover style={styles.popoverStyle} onClose={_=>this.props.closeCallback(null)}
                     anchorEl={this.props.target} PaperProps={{style:{maxWidth:"500px"}}} open={this.props.open}>
                <List>
                    <ListItem divider>
                        <Typography variant={"h5"}>
                            {this.props.heading}
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant={"caption"}>
                            {this.props.extended}
                        </Typography>
                    </ListItem>
                    <ListItem dense divider style={{paddingBottom:"0px"}}>
                        <Typography variant={"h6"}>
                            Select Color Indicator
                        </Typography>
                    </ListItem>
                </List>
                <div style={styles.listStyle}>
                    <List>
                        {this.renderPriorityListItem("Present", "lightgreen", 0)}
                        {this.renderPriorityListItem("Not Fully Present", "yellow", 1)}
                        {this.renderPriorityListItem("Not Present", "red", 2)}
                    </List>
                </div>
                <div>
                    <List style={{width:"100%"}}>
                        <ListItem style={{width:"100%"}}>
                            <TextField
                                style={{width:"100%"}}
                                multiline
                                defaultValue={this.state.justification}
                                onChange={e=>this.setState({justification:e.target.value})}
                                variant={"outlined"}
                                rows={4}
                                label={"Justification"} />
                        </ListItem>
                    </List>
                </div>
                <div>
                    <List>
                        <ListItem>
                            <Grid container spacing={8} justify={"flex-end"} direction={"row"} alignItems={"center"}>
                                <Grid item>
                                    <Button onClick={_=>this.props.closeCallback(null)} color={"secondary"} variant={"outlined"} style={styles.button}>cancel</Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={_=>this.props.closeCallback(this.state.picked_priority, this.state.justification)} color={"primary"} style={styles.button} variant={"contained"}>save</Button>
                                </Grid>
                            </Grid>
                        </ListItem>
                    </List>
                </div>
            </Popover>
        )
    }
}

PriorityPopover.propTypes = {
    value: PropTypes.number,
    justification: PropTypes.string,
    open: PropTypes.bool.isRequired,
    target: PropTypes.object,
    heading: PropTypes.string,
    extended: PropTypes.string,
    closeCallback: PropTypes.func.isRequired,
}


export default PriorityPopover;