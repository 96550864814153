import React from 'react';
import {PropTypes} from "prop-types";
import {connect} from 'react-redux';
import Matrix from "../matrix/Matrix";
import {setCurrentScreen} from "../../actions/projectActions";

const styles = {
    scorePanelContainer: {
        background: "white",
        display:"flex",
        flex:"0 1 100%",
        flexDirection:"column",
        height: "100%",
        overflowY:"auto",
        padding: "1%",
        boxSizing: "border-box",
    },
    divider: n => ({
        height: n + "%"
    }),
}


function verifyMatrix(matrix_data){
    let prev = null;
    for(let col = 0; col < 5; col++) {
        prev = null;
        for (let row = 0; row < 6; row++) {

            if((col === 1 && row === 0) || (col === 4 && row < 2)){
                continue;
            }

            let curr = matrix_data.entries[row][col];
            if(prev === null){
                prev = curr;
                continue;
            }else if(prev.priority <= curr.priority || curr.priority === null){
                prev = curr;
                continue;
            }else if(prev.priority === null && curr.priority !== null){
                return false;
            }else if(prev.priority > curr.priority){
                return false;
            }
        }
    }
    return true;
}


class EditMatrix extends React.Component {


    constructor(props) {
        super(props);
        this.state = {currentStrategy: -1, editStrategy: null, selectedTemplate:null};
    }


    render() {

        return <div style={{display:"flex", flexDirection:"column", width:"100%"}}>
                <Matrix matrixId={this.props.project.current_practice_change}/>
        </div>}
}


EditMatrix.propTypes = {
    onDeleteCallback: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {


    let current_practice_change = state.practiceChanges.filter(v=>v.id === state.project.current_practice_change)[0];
    return {
        project: state.project,
        current_practice_change,
    };
}

const mapDispatchToProps = dispatch => ({
    continueClick:() => {
        dispatch(setCurrentScreen(3))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(EditMatrix);