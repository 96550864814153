import React from 'react';
import {PropTypes} from "prop-types";
import {connect} from 'react-redux';
import Paper from "@material-ui/core/Paper/Paper";
import Typography from "@material-ui/core/Typography/Typography";
import Divider from "@material-ui/core/Divider/Divider";
import TextField from "@material-ui/core/TextField/TextField";
import PracticeChangePopover from "./PracticeChangePopover";
import Button from "@material-ui/core/Button/Button";
import SelectIndicatorPopover from "./SelectIndicatorPopover";
import ListItem from "@material-ui/core/ListItem/ListItem";
import List from "@material-ui/core/List/List";
import PracticeChangeTemplatePopover from "./PracticeChangeTemplatePopover";
import HelpPopper from "../HelpPopper";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DialogActions from "@material-ui/core/DialogActions";

const styles = {
    container: {
        height: "100%",
        width: "100%",
        background: "white",
        overflow:"hidden",
        margin:"0px 24px",
    },
    centerDiv: {
        display: "table",
        margin: "auto"
    },
    newIssueContainer: {
        boxSizing: "border-box",
        margin: "3%",
    },
    fill: {
        maxHeight: "100%",
        margin: "1%"
    },
    templateContainer: {
        height: "80%",
        margin: "3%",
        boxSizing: "border-box"
    },
    noShrink:{
        flexShrink:0,
    }


}

const ids_to_nested_arrays = (indicators, ids) => {
    let output = [];
    indicators.categories.map(i => {
        let next = [];
        output.push(next)
        i.indicators.map(ind => {
            if(ids.includes(ind.id)){
                next.push(ind.index)
            }
        })
    })

    return output;
}

class PracticeChangePanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pickedIndicators: false,
            setDesc: false,
            name:"",
            description:"",
            descPopoverOpen: false,
            descPopoverTarget: null,
            indicatorPopoverOpen: false,
            indicatorPopoverTarget: null,
            indicator_list: [],
        }
        if(!props.editing && props.currentPracChange){

            this.state.indicator_list = ids_to_nested_arrays(props.indicators, props.currentPracChange.indicators);
            this.state.name = props.currentPracChange.practice_change_name;
            this.state.description = props.currentPracChange.practice_change_description;
            this.state.setDesc = true;
        }
        this.renderPickedIndicators = this.renderPickedIndicators.bind(this);
        this.handleTextFieldClick = this.handleTextFieldClick.bind(this);
        this.handleIndicatorSelectClicked = this.handleIndicatorSelectClicked.bind(this);
        this.handleDescPopoverClose = this.handleDescPopoverClose.bind(this);
        this.handleIndicatorPopoverClose = this.handleIndicatorPopoverClose.bind(this);
        this.handleOnCreateClick = this.handleOnCreateClick.bind(this);
    }
    componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.currentPracChange === null){
            return;
        }
        if((!this.props.editing && prevProps.editing) || (!this.props.editing &&  prevProps.currentPracChange !== this.props.currentPracChange)){

            this.setState({
                indicator_list:ids_to_nested_arrays(this.props.indicators, this.props.currentPracChange.indicators),
                name: this.props.currentPracChange.practice_change_name,
                description: this.props.currentPracChange.practice_change_description,
                setDesc: true
            })
        }
    }
    handleDescPopoverClose(name, desc) {
        if(name === null || desc === null){
           name = this.state.name;
           desc = this.state.description;
        }
        this.setState({
            setDesc: true,
            name: name,
            description: desc,
            descPopoverOpen: false,
        })
    }


    handleIndicatorPopoverClose(picked) {
        this.setState({
            pickedIndicators:true,
            indicator_list: picked,
            indicatorPopoverOpen: false,
        })
    }

    handleTextFieldClick(event) {
        this.setState({
            descPopoverOpen: true,
            descPopoverTarget: event.target,
        })
    }

    handleIndicatorSelectClicked(event) {
        this.setState({
            indicatorPopoverOpen: true,
            indicatorPopoverTarget: event.target.parentNode.parentNode.parentNode.parentNode,
        })
    }

    renderPickedIndicators() {

        let indicator_list = this.props.default_checked;
        let clicked_list = this.state.indicator_list;
        const {indicators} = this.props;

        let render_list = [];
        let list = [];
        for (let i = 0; i < indicator_list.length; i++) {
            if (indicator_list[i].length === 0 && clicked_list.length===0) {
                continue;
            }
            else {
                if (clicked_list.length!==0){
                    list = clicked_list[i];
                }
                else {
                    list = indicator_list[i];
                }


                let index = [...new Set(list)];

                if (index.length!==0){
                render_list.push(
                    (<List subheader={
                        indicators.categories[i].text
                    }>
                        {index.map(j => {
                            return (<ListItem>
                                {
                                    indicators.categories[i].indicators[j] ? indicators.categories[i].indicators[j].text : ""
                                }
                            </ListItem>)
                        })}
                    </List>)
                );}
            }
        }

        if(render_list.length == 0){
            return (<Typography style={{color:"lightgrey"}} variant={"subtitle"} align={"center"}>
                No Indicators Selected
            </Typography>)
        }


        return render_list
    }

    handleChange(key, event){
        //let new_state = {...this.props};
        //console.log(new_state)
        //new_state[key] = event.target.value;
        //this.props.issueCallback(new_state.issueName  , new_state.issueDescription );
        if (key==="name"){this.setState({name:event.target.value});}
        else if (key==="description"){this.setState({description:event.target.value});}
        //this.props.issueCallback(this.state.issueName,this.state.issueDescription)
    }

    handleOnCreateClick(){
        let {indicator_list} = this.state;
        if(!this.state.pickedIndicators){
            indicator_list = this.props.default_checked;
        }
        let ind_ids = [];
        const {indicators} = this.props;
        for (let i = 0; i < indicator_list.length; i++) {
            if (indicator_list[i].length === 0) {
                continue;
            }
            else {
                for(let j = 0; j < indicator_list[i].length; j++) {
                    ind_ids.push(indicators.categories[i].indicators[j].id);
                }
            }
        }

        let name = this.state.name || this.props.default_name;
        let desc = this.state.description || this.props.default_description;

        this.props.onCreateCallback(name, desc, ind_ids);
    }

    renderNewPC() {
        return (
            <div style={{display: "flex", flexDirection: "column", flexBasis:"50%", margin:"0px 5px"}}>

                <div style={{margin:"10px 5px"}}>
                    <Typography variant={"subtitle1"}>
                        Practice Change Name
                    </Typography>
                    <TextField defaultValue={this.state.name || this.props.templateName} variant={"outlined"}
                               placeholder={"Name your practice change"}
                               multiline style={{width: "100%"}} onChange={e => this.handleChange("name", e)}>

                    </TextField>
                </div>
                <div style={{margin:"10px 5px"}}>
                    <Typography variant={"subtitle1"}>
                        Description of new practice change
                    </Typography>
                    <TextField defaultValue={this.state.description || this.props.templateDescription} variant={"outlined"}
                               placeholder={"Describe..."}
                               multiline style={{width: "100%"}} onChange={e => this.handleChange("description", e)}>

                    </TextField>
                </div>
            </div>
        )
    }

    render() {
        if(this.props.indicators === null){
            return "";
        }
        let textfield_value = "";
        if(this.state.setDesc){
            textfield_value = this.state.name + "\n\n" + this.state.description;
        }else{
            textfield_value = this.props.default_name + "\n\n" + this.props.default_description;
        }
        return (
            <div style={styles.container}>
                <div  style={{padding:"15px", fontSize:"1.1em"}}>
                    Practice Change
                    <HelpPopper>
                        The practice change can be
                        pre-populated according to the
                        predefined template issue. If you create
                        a new issue, you must now define the
                        change in practice by entering the
                        ‘practice change name’ and a brief
                        description of how the practice has
                        changed in ‘practice change
                        description’.
                    </HelpPopper>

                </div>
                <div style={{boxSizing: "border-box", padding: "5px", display: "flex", flexDirection: "row"}}>
                    <div style={{display:"flex", flexDirection: "column", flexBasis:"50%", margin:"0px 5px"}}>
                        {this.renderNewPC()}
                        {/*<TextField onClick={this.handleTextFieldClick} style={{"width": "100%"}} multiline
                           label={"Practice Change"}
                           disabled={true}
                           value={textfield_value}
                           rows={5}>
                </TextField>*/}
                    </div>
                    <div style={{display:"flex", flexDirection: "column", flexBasis:"50%", margin:"0px 5px"}}>
                        <div>
                            Select Indicators
                            <HelpPopper>
                                If your city has undertaken or will undertake the WSC
                                Index benchmarking process, you can link the

                                practice change to the relevant WSC Index indicators.
                                This will be useful for reporting on your Transition

                                Dynamics Framework analysis and the subsequent
                                enabling strategies impact on your WSC Index

                                scores.
                                <br/>
                                <br/>

                                For each practice change, select which WSC Index
                                indicators are related to the practice

                                change from the list of 34 indicators. If you do not wish
                                to link your transitions analysis to the WSC

                                Index, you can leave this blank.
                            </HelpPopper>
                        </div>

                        <div style={styles.noShrink}>
                            <div style={styles.centerDiv}>
                                <Button onClick={this.handleIndicatorSelectClicked}>
                                    select
                                </Button>

                            </div>
                        </div>
                        <Divider/>
                        <div>
                            {this.renderPickedIndicators()}
                        </div>
                    </div>

                    <Divider/>
                    <PracticeChangeTemplatePopover
                        templateIndex={this.props.templateIndex}
                        closeCallback={this.handleDescPopoverClose}
                        name={this.state.name}
                        description={this.state.description}
                        open={this.state.descPopoverOpen}
                        target={this.state.descPopoverTarget}/>

                    <SelectIndicatorPopover
                        default_checked={this.state.pickedIndicators ? this.state.indicator_list : this.props.default_checked}
                        closeCallback={this.handleIndicatorPopoverClose}
                        open={this.state.indicatorPopoverOpen}
                        target={this.state.indicatorPopoverTarget}/>
                </div>
                <div style={{float:"right", marginTop:"10px"}}>
                    <Button style={{marginRight:"10px"}}  onClick={this.props.handleBackButtonClicked} variant={"contained"} color={"primary"}>
                        <ArrowBackIosIcon fontSize={"small"}/>
                        Back
                    </Button>
                    { this.props.editing &&
                    <Button  onClick={_ => this.handleOnCreateClick()} variant={"contained"} color={"primary"}>
                        create
                    </Button>
                    }
                </div>
            </div>)
    }
}

PracticeChangePanel.propTypes = {
    templateIndex: PropTypes.number,
    indicators: PropTypes.object,
    currentPracChange: PropTypes.object,
    onCreateCallback: PropTypes.func.isRequired,
};
//TODO: optimize here?
const getIndicatorByID = (id, indicator_categories) => {
    for(let j = 0; j < indicator_categories.length; j++) {

        let indicators = indicator_categories[j].indicators;
        for (let i = 0; i < indicators.length; i++) {
            if (indicators[i].id === id) {
                return {cat_index: j, ind_index: i};
            }
        }
    }

}

const mapStateToProps = (state, ownProps) => {
    if(state.project.current_project === null){
        return {
            default_name: "",
            default_description: "",
            default_checked: null,
            indicators: null
        }
    }
    let current_project = state.projects[state.project.current_project];
    let indicators = state.indicators.filter(is => is.id === current_project.indicator_set_id)[0]

    let n = indicators.categories.length;
    let default_checked = [];
    for(let i = 0; i < n; i++){
        default_checked.push([]);
    }
    let default_name;
    let default_description;

    if(ownProps.templateIndex !== -1){
        let template = state.issueTemplates[ownProps.templateIndex];
        default_name = template.practice_change_name;
        default_description = template.practice_change_description;

        for(let i = 0; i < template.indicators.length; i++){
            let indic = template.indicators[i];
            indic = getIndicatorByID(indic, indicators.categories);
            default_checked[indic.cat_index].push(indic.ind_index);
        }

        return {
            templateName: template.practice_change_name,
            templateDescription: template.practice_change_description,
            default_name,
            default_description,
            default_checked,
            indicators:indicators,
        }

    }
    return {
        default_name,
        default_description,
        default_checked,
        indicators:indicators,
    };
}

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(PracticeChangePanel);