

const defaultState = {
    project_id:-1,
    name:"",

    /**
     * app state variables
     */
    progress_block: 0,
    show_message: false,
    current_screen: 0,
    cue_auto_save: false,
    auto_save_time_stamp: 0,
    current_practice_change:null,
    current_project:null,
}

export const project = function(state=defaultState, action){
    switch(action.type){
        case "PROJ_INIT_PROJECT_DATA": {
            let new_state = Object.assign({}, state);
            new_state.name = action.project_data.name;
            new_state.project_id = action.project_data.id;
            return new_state;
        }
        case "PROJ_SET_TIMESTAMP": {
            let new_state = Object.assign({}. state);
            new_state.auto_save_time_stamp = action.ts;
            return new_state;
        }
        case "PROJ_SET_CURRENT_SCREEN": {
            let new_state = Object.assign({}, state);
            new_state.current_screen = action.index;
            return new_state;
        }
        case "PROJ_SET_CURRENT_PRACTICE_CHANGE": {
            let new_state = Object.assign({}, state);
            new_state.current_practice_change = action.id;
            return new_state;
        }
        case "PROJ_SET_CURRENT_PROJECT": {
            let new_state = Object.assign({}, state);
            new_state.current_project = action.index;
            return new_state;
        }
        case "PROJ_CUE_AUTO_SAVE": {
            let new_state = Object.assign({}, state);
            new_state.cue_auto_save = action.state;
            return new_state;
        }
        case "PROJ_HIDE_MESSAGE": {
            let new_state = Object.assign({}, state);
            new_state.show_message = false;
            return new_state;
        }
        case "PROJ_SHOW_MESSAGE": {
            let new_state = Object.assign({}, state);
            new_state.show_message = true;
            return new_state;
        }
        case "PROJ_PROGRESS_BLOCK_INC": {
            let new_state = Object.assign({}, state);
            new_state.progress_block += 1;
            return new_state;
        }
        case "PROJ_PROGRESS_BLOCK_DECR": {
            let new_state = Object.assign({}, state);
            new_state.progress_block -= 1;
            return new_state;
        }
        default:
            return state;
    }
}