

const defaultState = {
    user_email: "",
    user_token: "",
    user_authenticated: false,
    user_authentication_error: false,
    user_authentication_error_string: "",
    user_generic_error: false,
    user_generic_error_string: "",
}

export const user = (state = defaultState, action) => {
    switch (action.type){
        case "USER_SET_USERDATA":{
            const new_state = Object.assign({}, state);
            new_state.user_token = action.user_token;
            new_state.user_email = action.user_email;
            new_state.user_authenticated = true;
            new_state.user_authentication_error = false;
            new_state.project_list = [];
            return new_state;
        }
        case "USER_DE_AUTH": {
            const new_state = Object.assign({}, state);
            new_state.user_authenticated = false;
            new_state.user_token = "";
            new_state.user_email = "";
            new_state.user_authentication_error = false;
            new_state.project_list = [];
            return new_state;
        }
        case "USER_AUTHENTICATION_ERROR": {
            const new_state = Object.assign({}, state);
            new_state.user_authentication_error = true;
            return new_state;
        }
        case "USER_AUTHENTICATION_ERROR_STRING": {
            const new_state = Object.assign({}, state);
            new_state.user_authentication_error_string = action.string;
            return new_state;
        }
        case "USER_SET_GENERIC_ERROR": {
            const new_state = Object.assign({}, state);
            new_state.user_generic_error = true;
            return new_state;
        }
        case "USER_SET_GENERIC_ERROR_STRING": {
            const new_state = Object.assign({}, state);
            new_state.user_generic_error_string = action.string;
            return new_state;
        }
        default:
            return state;

    }
};