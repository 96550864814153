import React from 'react';
import {PropTypes} from "prop-types"
import Paper from "@material-ui/core/Paper/Paper";
import List from "@material-ui/core/List/List";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography/Typography";
import Card from "@material-ui/core/Card/Card";
import DashboardPracticeChangeEntry from "./DashboardPracticeChangeEntry";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import {setCurrentScreen} from "../../actions/projectActions";

const styles = {
    container:{
        background:"white",
        height:"100%",
        display:"flex",
        flexDirection:"column",
        overflow:"hidden",
    }
}

class DashboardPracticeChanges extends React.Component {

    constructor(props) {
        super(props);
        this.handleNewPracticeClick = this.handleNewPracticeClick.bind(this);
    }

    handleNewPracticeClick(){
        this.props.goToNew();
    }


    render() {
        return (
            <Card elevation={10} style={styles.container}>
                <div>
                    <Typography align={"center"} variant={"h4"}>
                        Current Practice Changes
                    </Typography>
                </div>
                <div style={{flex:"1 0 0%", overflowY:"auto"}}>
                    <List style={{margin:"20px"}}>
                        {this.props.practice_changes.length === 0 ? "none" :
                            this.props.practice_changes.map((v, i)=>{
                                return <DashboardPracticeChangeEntry key={"dpce"+i} practiceChangeId={v.id}/>
                            })
                        }
                        <ListItem key={"new"} button onClick={_=>{
                            this.handleNewPracticeClick();
                        }}>
                            <ListItemText>
                                NEW PRACTICE CHANGE
                            </ListItemText>
                        </ListItem>
                    </List>
                </div>
        </Card>)
    }
}

DashboardPracticeChanges.propTypes = {
    practice_changes:PropTypes.array,
};

const mapStateToProps = (state, ownProps) => {
    if(state.project.current_project === null){
        return {
            practice_changes: []
        }
    }
    let current_project = state.projects[state.project.current_project];
    return {
        practice_changes: state.practiceChanges.filter(pc=>pc.project_id === current_project.id)
    };
}

const mapDispatchToProps = dispatch => ({
    goToNew:_=>{
        dispatch(setCurrentScreen(2))
    }
});


export default connect(mapStateToProps, mapDispatchToProps)(DashboardPracticeChanges);